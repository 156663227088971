import React from "react";
import {Popup} from '../../components/Popup';
import {useTranslation} from 'react-i18next';
import { Button as ButtonIcon } from '@yandex/ui/Button/Button';
import './IconsStyle.css'

function Edit({handleToggleModal} : {handleToggleModal: () => void}) {

  const [hoverIcon, setHoverIcon] = React.useState<string | null>(null);
  const {t} = useTranslation();

  return (
    <div 
        className="IconStyle-Btn-Icon"
        onMouseEnter={() => {setHoverIcon('edit')}}
        onMouseLeave={() => {setHoverIcon(null)}} >
        <Popup
            active={'edit' === hoverIcon}
            position={{x: -25, y: 0}}>
            {t('Редактировать')}
        </Popup>

        <ButtonIcon
            type="button"
            className="IconStyle-Btn"
            onClick={handleToggleModal}
            >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m20 11.5c0-.2761424.2238576-.5.5-.5s.5.2238576.5.5v7.0000057c0 1.3807118-1.1192881 2.5-2.5 2.5h-13.01387819c-1.38071187 0-2.5-1.1192882-2.5-2.5v-13.0000057c0-1.38071187 1.11928813-2.5 2.5-2.5h7.01387819c.2761424 0 .5.22385763.5.5s-.2238576.5-.5.5h-7.01387819c-.82842712 0-1.5.67157288-1.5 1.5v13.0000057c0 .8284271.67157288 1.5 1.5 1.5h13.01387819c.8284271 0 1.5-.6715729 1.5-1.5zm-1.1464466-8.35355339 2 2c.1952621.19526215.1952621.51184463 0 .70710678l-8 8.00000001c-.0937682.0937682-.2209452.1464466-.3535534.1464466h-2c-.2761424 0-.5-.2238576-.5-.5v-2c0-.1326082.0526784-.2597852.1464466-.3535534l8-7.99999999c.1952622-.19526215.5118446-.19526215.7071068 0zm-.3535534 1.06066017-7.5 7.50000002v1.2928932h1.2928932l7.5-7.5"
                fill="#6c9763"
                stroke="#6c9763"
                strokeWidth="1.5"
              />
            </svg>
        </ButtonIcon>
      </div>
  );
}

export default Edit;
