import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux/AppStore'
import EditMaterial from '../EditMaterial/EditMaterial'
import EditFacades from '../EditFacades/EditFacades'
import EditPlinths from '../EditPlinth/EditPlinths'
import EditFacadeMaterials from '../EditFacadeMaterial/EditFacadeMaterials'
import EditModule from '../EditModule/EditModule'
import * as materialServices from '../api/MaterialServices'
import { ISaveImage } from '../../../../../common-code/interfaces/materials/ISaveImage'
import { KitchenService } from '../../../../3d/services/KitchenService/KitchenService'
import { IClassNameProps } from '@bem-react/core'
import { IMaterialData } from 'common-code/lib/interfaces/materials/IMaterialData'
import { ICreateGroup } from 'common-code/lib/interfaces/createData/ICreateGroup'
import EditHandle from '../EditHandle/EditHandle'
import { useUrlSearchParam } from '../../../../hooks/useSearchParams'
import { TMaterialEditMode } from '../../../../../../../common/types/TMaterialEditMode'
import {
    EDIT_MODE_CORPUS_MATERIALS,
    EDIT_MODE_FACADE_MATERIALS,
    EDIT_MODE_FACADES,
    EDIT_MODE_HANDLES,
    EDIT_MODE_MATERIALS,
    EDIT_MODE_MODULE,
    EDIT_MODE_PLINTHS,
} from 'common-code/lib/constants'
export interface IWisardEditModeProps extends IClassNameProps {
    service: KitchenService
    saveMaterial: (
        facadeListNew: IMaterialData[],
        facadeMode: TMaterialEditMode
    ) => Promise<void>
    modules: ICreateGroup[] | undefined
    saveUnitList: (unitListNew: ICreateGroup[]) => void
}

const WisardEditEditMode = ({
    service,
    saveMaterial,
    modules,
    saveUnitList,
}: IWisardEditModeProps) => {
    const isEditMode = useSelector(
        (state: AppState) => state.wizard.editModule.isEditMode
    )
    const collectionId = useUrlSearchParam('collection')
    // сохраням превью материала
    const saveImagePreview = async (
        imageNew: File,
        facadeMaterialId: string,
        materialMode: TMaterialEditMode
    ): Promise<string | null> => {
        const saveImageData: ISaveImage = {
            id: `${facadeMaterialId}-${collectionId}`,
            folder: 'imagePreview',
            title: materialMode,
            file: imageNew,
        }
        const imageUrl = await materialServices.saveImage(saveImageData)
        if (!imageUrl) return null
        return imageUrl.url as string
    }

    switch (isEditMode) {
        case EDIT_MODE_MATERIALS:
        case EDIT_MODE_CORPUS_MATERIALS:
            return (
                <EditMaterial
                    service={service}
                    saveMaterial={saveMaterial}
                    saveImagePreview={saveImagePreview}
                />
            )
        case EDIT_MODE_FACADES:
            return (
                <EditFacades
                    saveFacade={saveMaterial}
                    saveImagePreview={saveImagePreview}
                />
            )
        case EDIT_MODE_PLINTHS:
            return (
                <EditPlinths
                    service={service}
                    savePlinth={saveMaterial}
                    saveImagePreview={saveImagePreview}
                />
            )
        case EDIT_MODE_FACADE_MATERIALS:
            return (
                <EditFacadeMaterials
                    service={service}
                    saveFacadeMaterial={saveMaterial}
                    saveImagePreview={saveImagePreview}
                />
            )
        case EDIT_MODE_MODULE:
            return (
                <EditModule
                    service={service}
                    modules={modules}
                    saveUnitList={saveUnitList}
                />
            )
        case EDIT_MODE_HANDLES:
            return (
                <EditHandle
                    saveHandle={saveMaterial}
                    saveImagePreview={saveImagePreview}
                />
            )
        default:
            return <></>
    }
}

export default WisardEditEditMode
