import {KitchenService} from '../KitchenService';
import axios, {AxiosResponse} from 'axios';
import {
    CHANGE_APRONS,
    CHANGE_BOTTOM_FACADE_MATERIAL,
    CHANGE_BOTTOM_HANDLE,
    CHANGE_CONSTRUCTIVE_LIST,
    CHANGE_CORNERS,
    CHANGE_CORPUS_MATERIALS,
    CHANGE_FACADE_MATERIALS,
    CHANGE_FACADES,
    CHANGE_FLOOR,
    CHANGE_GLASSES,
    CHANGE_HANDLES,
    CHANGE_INTEGRATED_HANDLES,
    CHANGE_KIT_CODES,
    CHANGE_MATERIALS,
    CHANGE_PLINTHS,
    CHANGE_SELECT_APRON,
    CHANGE_SELECT_CORNER,
    CHANGE_SELECT_CORPUS_MATERIAL,
    CHANGE_SELECT_FLOOR,
    CHANGE_SELECT_GLASS,
    CHANGE_SELECT_INTEGRATED_HANDLE,
    CHANGE_SELECT_KIT_CODE,
    CHANGE_SELECT_PLINTH,
    CHANGE_SELECT_TABLETOP,
    CHANGE_SELECT_WALL,
    CHANGE_SERVICES,
    CHANGE_TABLETOPS,
    CHANGE_TECHNOLOG_MAP,
    CHANGE_TOP_FACADE_MATERIAL,
    CHANGE_TOP_HANDLE,
    CHANGE_UNIT_LIST,
    CHANGE_WALL,
    HIDE_LOADING,
    LOADING_PRICE,
    MESSAGE_TYPE_ERROR,
    SHOW_LOADING
} from '../../../../constants';
import {TPoint2D} from '../../../../../common-code/types/TPoint2D';
import {TLevel} from '../../../../../common-code/types/TLevel';
import {
    ALIGN_LEFT,
    ALIGN_RIGHT,
    ALL_FACADES, APRON_PLANK_TYPE_ANGLE, APRON_PLANK_TYPE_CONNECT, APRON_PLANK_TYPE_END,
    CATALOG_CALCULATE_TYPE_DETAILS,
    CATALOG_CALCULATE_TYPE_MODULE,
    CHECK_CATALOG_STRICT,
    DEFAULT_HANDLE_ID,
    FACADE_FOR_SECOND,
    FACADE_FUNCTIONAL_TYPE_DEFAULT,
    FACADE_MATERIAL_TYPE_CORPUS_MATERIAL,
    FACADE_MATERIAL_TYPE_DEFAULT,
    FACADE_MATERIAL_TYPE_SECOND,
    FACADE_MODEL_TYPE_PLANE,
    FACADE_OPEN_TYPE_VERTICAL_PIVOT,
    FURNITURE_TYPE_KITCHEN,
    GOOD_TYPE_APRON,
    GOOD_TYPE_CORNER,
    GOOD_TYPE_FACADE,
    GOOD_TYPE_FURNITURE,
    GOOD_TYPE_HANDLE,
    GOOD_TYPE_LEG,
    GOOD_TYPE_PLINTH,
    GOOD_TYPE_TABLETOP,
    GROUP_ACCESSORIES,
    GROUP_BIG_EQUIPMENTS,
    GROUP_BOTTOM_EQUIPMENTS,
    GROUP_BUILTIN_EQUIPMENTS,
    GROUP_EQUIPMENTS,
    GROUP_FACADES,
    GROUP_SINK_EQUIPMENTS,
    GROUP_TOP_EQUIPMENTS,
    HANDLE_ID_INTEGRATION,
    KDETAIL_PRICE_TYPE_COLLECTIONS,
    LEVEL_BOTTOM,
    LEVEL_TOP,
    NONE_MATERIAL,
    OPTION_TYPE_CORPUS_MATERIAL,
    OPTION_TYPE_FACADE_MATERIAL,
    OPTION_TYPE_HIDDEN_NUMBER,
    OPTION_TYPE_JSON,
    OPTION_TYPE_NUMBER,
    OPTION_TYPE_RADIOBUTTON,
    OPTION_TYPE_RANGE,
    OPTION_TYPE_SELECT,
    OPTION_TYPE_TABLETOP_MATERIAL, TABLETOP_PLANK_TYPE_ANGLE, TABLETOP_PLANK_TYPE_CONNECT, TABLETOP_PLANK_TYPE_END
} from '../../../../../common-code/constants';
import {IEquipmentModelData} from '../../../../../common-code/interfaces/IEquipmentModelData';
import {ThreeEquipment} from '../../../objects/threeD/equipments/ThreeEquipment/ThreeEquipment';
import {IImportOffer} from '../../../../../common-code/interfaces/api/IImportOffer';
import {TFacadeSizes} from "../../../../../common-code/types/TFacadeSizes";
import {TCreateGroup} from "../../../../../common-code/types/TCreateGroup";
import {TSelectItem} from '../../../../../common-code/types/TSelectItem';
import {ThreeFacade} from '../../../objects/threeD/details/ThreeFacade/ThreeFacade';
import {ThreeKUnitDetail} from '../../../objects/threeD/details/ThreeKUnitDetail/ThreeKUnitDetail';
import {IDetailOfferData} from '../../../../../common-code/interfaces/IDetailOfferData';
import {TClassName} from '../../../../../common-code/types/TClassName';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {ITechnologMap} from '../../../../../common-code/interfaces/ITechnologMap';
import {ITabletopData} from '../../../../../common-code/interfaces/materials/ITabletopData';
import {IApronData} from '../../../../../common-code/interfaces/materials/IApronData';
import {ICornerData} from '../../../../../common-code/interfaces/materials/ICornerData';
import {IPlinthData} from '../../../../../common-code/interfaces/materials/IPlinthData';
import {ICreateGroup} from '../../../../../common-code/interfaces/createData/ICreateGroup';
import {IUnitKits} from '../../../../../common-code/interfaces/catalog/IUnitKits';
import {IFacadeData} from '../../../../../common-code/interfaces/materials/IFacadeData';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {IHandleData} from '../../../../../common-code/interfaces/materials/IHandleData';
import {IUnitKitPrices} from '../../../../../common-code/interfaces/catalog/IUnitKitPrices';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import {CommonHelper, KitchenHelper} from 'common-code';
import {IOption} from '../../../../../common-code/interfaces/option/IOption';
import {IOptionGroup} from '../../../../../common-code/interfaces/option/IOptionGroup';
import {IOptionHiddenText} from '../../../../../common-code/interfaces/option/IOptionHiddenText';
import {IProductPrice} from '../../../../../common-code/interfaces/catalog/IproductPrice';
import {IRoomMaterials} from '../../../../../common-code/interfaces/materials/IRoomMaterials';
import {IProjectData} from '../../../../../common-code/interfaces/project/IProjectData';
import {ISaveFacadeData} from '../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {IFacadeModelData} from '../../../../../common-code/interfaces/materials/IFacadeModelData';
import {ICreateObjectDataWidths} from '../../../../../common-code/interfaces/createData/ICreateObjectDataWidths';
import {IOptionHiddenNumber} from '../../../../../common-code/interfaces/option/IOptionHiddenNumber';
import {IOptionSelect} from '../../../../../common-code/interfaces/option/IOptionSelect';
import {IOptionRadioButton} from '../../../../../common-code/interfaces/option/IOptionRadioButton';
import {ICreateObjectDataHeights} from '../../../../../common-code/interfaces/createData/ICreateObjectDataHeights';
import {ICreateObjectDataDepths} from '../../../../../common-code/interfaces/createData/ICreateObjectDataDepths';
import {ICreateObjectSelectOptions} from '../../../../../common-code/interfaces/createData/ICreateObjectSelectOptions';
import {IModulePriceParams} from '../../../../../common-code/interfaces/catalog/IModulePriceParams';
import {
    ICreateObjectDisableSelects
} from '../../../../../common-code/interfaces/createData/ICreateObjectDisableSelects';
import {i18n} from '../../../../i18n';
import {IOptionCorpusMaterial} from '../../../../../common-code/interfaces/option/IOptionCorpusMaterial';
import {IOptionHiddenJson} from '../../../../../common-code/interfaces/option/IOptionHiddenJson';
import {IOptionFacadeMaterial} from '../../../../../common-code/interfaces/option/IOptionFacadeMaterial';
import {IFacadeMaterialItem} from '../../../../../common-code/interfaces/materials/IFacadeMaterialItem';
import {IModulePriceData} from '../../../../../common-code/interfaces/catalog/IModulePriceData';
import {TOfferExternalId} from '../../../../../common-code/types/TOfferExternalId';
import {IServiceData} from '../../../../../common-code/interfaces/catalog/IServiceData';
import {ICatalogCodeFilterParams} from '../../../../interfaces/ICatalogCodeFilterParams';
import {ICorpusMaterials} from '../../../../../common-code/interfaces/materials/ICorpusMaterials';
import {IFacadeMaterials} from '../../../../../common-code/interfaces/materials/IFacadeMaterials';
import {IDetailPriceData} from '../../../../../common-code/interfaces/catalog/IDetailPriceData';
import {ICorpusMaterialIds} from '../../../../../common-code/interfaces/catalog/ICorpusMaterialIds';
import {IFacadeMaterialIds} from '../../../../../common-code/interfaces/catalog/IFacadeMaterialIds';
import {ITechnologMaps} from '../../../../../common-code/interfaces/ITechnologMaps';
import {
    ThreeConstructiveModel
} from '../../../objects/threeD/constructive/ThreeConstructiveModel/ThreeConstructiveModel';
import {IConstructiveModelData} from '../../../../../common-code/interfaces/room/IConstructiveModelData';
import {IHandleModelData} from '../../../../../common-code/interfaces/materials/IHandleModelData';
import {ISaveHandleData} from '../../../../../common-code/interfaces/saveData/ISaveHandleData';
import {IFacadeHandleData} from '../../../../../common-code/interfaces/materials/IFacadeHandleData';
import {TFacadeOpenType} from '../../../../../common-code/types/TFacadeOpenType';
import {TDirectionSideType} from '../../../../../common-code/types/TDirectionSideType';
import {THandleType} from '../../../../../common-code/types/THandleType';
import {IAlign} from '../../../../../common-code/interfaces/geometry/IAlign';
import {ISpecFormFilter} from '../../../../../common-code/interfaces/catalog/ISpecFormFilter';
import {CommonObjectHelper} from '../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {TSizes} from '../../../../../common-code/types/geometry/TSizes';
import {ThreeHandle} from '../../../objects/threeD/details/ThreeHandle/ThreeHandle';
import {IDetailData} from '../../../../../common-code/interfaces/materials/IDetailData';
import {TGoodType} from '../../../../../common-code/types/TGoodType';
import {
    ICreateObjectDataFacadeMaterial
} from '../../../../../common-code/interfaces/createData/ICreateObjectDataFacadeMaterial';
import {ThreeLeg} from '../../../objects/threeD/details/ThreeLeg/ThreeLeg';
import {TSizes3D} from '../../../../../common-code/types/TSizes3D';
import {IIntegratedHandleData} from '../../../../../common-code/interfaces/materials/IIntegratedHandleData';
import {ISaveKUnitDetailData} from '../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {IOptionTabletopMaterial} from '../../../../../common-code/interfaces/option/IOptionTabletopMaterial';
import {IOptionRange} from '../../../../../common-code/interfaces/option/IOptionRange';
import {TRange} from '../../../../../common-code/types/math/TRange';
import {TExtremumSizes} from '../../../../../common-code/types/math/TExtremumSizes';
import {isDevelop} from '../../../../helpers';
import {TOptionalRange} from '../../../../../common-code/types/math/TOptionalRange';
import {IFacadePriceData} from '../../../../../common-code/interfaces/catalog/IFacadePriceData';
import {TFacadeMaterialType} from '../../../../../common-code/types/materials/TFacadeMaterialType';
import {IOptionNumber} from '../../../../../common-code/interfaces/option/IOptionNumber';
import {IAccessoryPlank} from '../../../../../common-code/interfaces/plank/IAccessoryPlank';
import {TApronPlankType} from '../../../../../common-code/types/TApronPlankType';
import {TTabletopPlankType} from '../../../../../common-code/types/TTabletopPlankType';

export class DataManager {
    service: KitchenService;
    ready: boolean;

    floors?: IMaterialData[];
    selectFloor?: IMaterialData;
    walls?: IMaterialData[];
    selectWall?: IMaterialData;
    collectionId: string;
    technologMaps?: ITechnologMaps;
    tabletops?: ITabletopData[];
    selectTabletop?: ITabletopData;
    aprons?: IApronData[];
    selectApron?: IApronData;
    corners?: ICornerData[];
    selectCorner?: ICornerData;
    integratedHandles?: IIntegratedHandleData[];
    selectIntegratedHandle?: IIntegratedHandleData;
    plinths?: IPlinthData[];
    selectPlinth?: IPlinthData;
    units: ICreateGroup[];
    constructiveList?: ICreateGroup[];
    unitKits: IUnitKits;
    facades?: IFacadeData[];
    materials?: IMaterialData[];
    panelMaterials?: IMaterialData[];
    facadeMaterials?: IFacadeMaterialData[];
    bottomFacadeMaterial?: IFacadeMaterialData;
    topFacadeMaterial?: IFacadeMaterialData;
    corpusMaterials?: IMaterialData[];
    selectCorpusMaterial?: IMaterialData;
    glasses?: IMaterialData[];
    selectGlass?: IMaterialData;
    equipmentModels?: IEquipmentModelData[];
    constructiveModels?: IConstructiveModelData[];
    handles?: IHandleData[];
    handleModels?: IHandleModelData[];
    topHandle?: IHandleData;
    bottomHandle?: IHandleData;
    prices: IUnitKitPrices;
    kitCodes?: TSelectItem[];
    selectKitCode?: TSelectItem;
    services?: IServiceData[];
    checkUids?: string[];

    constructor(service: KitchenService) {
        this.service = service;
        this.ready = false;
        this.collectionId = this.initCollectionId();
        this.units = [];
        this.unitKits = {};
        this.prices = {};
        // this.checkUids = ["penal-2dveri-visokiy-nisha"];
    }

    public async initState() {
        if (this.ready) {
            return;
        }
        this.service.sendToRedux({type: SHOW_LOADING})
        await Promise.all([
            this.getEntityData('/api/entity/technolog-maps', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/units', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/tabletops', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/aprons', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/corners', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/plinths', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/room-materials', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/facades', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/materials', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/corpus-materials', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/facade-materials', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/equipment-models', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/handles', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/constructive-list', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/panel-materials', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/services', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/kit-codes', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/handle-models', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/constructive-models', {
                collection: this.collectionId
            }),
            this.getEntityData('/api/entity/integrated-handles', {
                collection: this.collectionId
            }),
        ]).then((results) => {
            this.setTechnologMaps(results[0] as ITechnologMaps);
            this.setUnits(results[1] as ICreateGroup[]);
            this.setAprons(results[3] as IApronData[]);
            this.setCorners(results[4] as ICornerData[]);
            this.setRoomMaterials(results[6]);
            this.setFacades(results[7] as IFacadeData[]);
            this.setMaterials(results[8] as IMaterialData[]);
            this.setCorpusMaterials(results[9] as IMaterialData[]);
            this.setFacadeMaterials(results[10] as IFacadeMaterialData[]);
            this.setTabletops(results[2] as ITabletopData[]);
            this.setPlinths(results[5] as IPlinthData[]);
            this.setEquipmentModels(results[11] as IEquipmentModelData[]);
            this.setHandles(results[12] as IHandleData[]);
            this.setConstructiveList(results[13] as ICreateGroup[]);
            this.setPanelMaterials(results[14] as ICreateGroup[]);
            this.setServices(results[15] as IServiceData[]);
            this.setKitCodes(results[16] as TSelectItem[]);
            this.setHandleModels(results[17] as IHandleModelData[]);
            this.setConstructiveModels(results[18] as IConstructiveModelData[]);
            this.setIntegratedHandles(results[19] as IIntegratedHandleData[]);
        }).catch(() => {
            this.service.sendToRedux({type: HIDE_LOADING})
            this.service.showMessage({
                type: MESSAGE_TYPE_ERROR,
                message: i18n.t('Не удалось загрузить начальные данные для конструктора')
            })
        });
        this.ready = true;
        await this.loadUnitKits().catch((error) => {
            this.service.sendToRedux({type: HIDE_LOADING});
            console.error('!loadUnitKits', error);
            this.service.showMessage({
                type: MESSAGE_TYPE_ERROR,
                message: i18n.t('Не удалось загрузить каталог товаров')
            })
        });
        this.service.sendToRedux({type: HIDE_LOADING});
    }

    public remove() {
        this.technologMaps = undefined;
        this.tabletops = undefined;
        this.selectTabletop = undefined;
        this.aprons = undefined;
        this.selectApron = undefined;
        this.corners = undefined;
        this.selectCorner = undefined;
        this.plinths = undefined;
        this.selectPlinth = undefined;
        this.units = [];
        this.service.setUnitsToKitchenHelper();
        this.unitKits = {};
        this.service.setUnitKitsToKitchenHelper();
        this.prices = {};
        this.service.setUnitPricesToKitchenHelper();
        this.floors = undefined;
        this.selectFloor = undefined;
        this.walls = undefined;
        this.selectWall = undefined;
        this.facades = undefined;
        this.handles = undefined;
        this.materials = undefined;
        this.bottomFacadeMaterial = undefined;
        this.topFacadeMaterial = undefined;
        this.corpusMaterials = undefined;
        this.service.setCorpusMaterialsToKitchenHelper();
        this.facadeMaterials = undefined;
        this.service.setFacadeMaterialsToKitchenHelper();
        this.selectCorpusMaterial = undefined;
        this.constructiveList = undefined;
        this.kitCodes = undefined;
        this.selectKitCode = undefined;
        this.services = undefined;
    }

    public checkSelectData() {
        this.checkSelectCorpusMaterial();
        this.checkSelectPlinth();
    }

    public searchOffers(searchText: string, filter?: ISpecFormFilter): Promise<IImportOffer[]> {
        return new Promise<IImportOffer[]>((resolve) => {
            if (!this.units || !this.unitKits) {
                resolve([]);

                return;
            }
            let catalogCode: string;
            let offer: IImportOffer;
            let searchOffers: { [key: string]: { offer: IImportOffer, rating: number } } = {};
            let searchOffer: { offer: IImportOffer, rating: number } | undefined;
            let externalId: 'externalGuid' | 'vendorCode';

            externalId = this.service.getOfferExternalId();
            for (catalogCode in this.unitKits) {
                if (filter) {
                    if (filter.catalogCodes && !filter.catalogCodes.includes(catalogCode)) {
                        continue;
                    }
                }
                for (offer of this.unitKits[catalogCode]) {
                    if (filter) {
                        if (filter.functionalTypes &&
                            (!offer.functionalType ||
                                !filter.functionalTypes.includes(offer.functionalType))) {
                            continue;
                        }
                        if (filter.modelId && offer.modelId !== filter.modelId) {
                            continue;
                        }
                    }
                    searchOffer = CommonObjectHelper.searchInOffer(searchText, offer);
                    if (searchOffer) {
                        searchOffers[offer[externalId]] = searchOffer;
                    }
                }
            }
            let sortOffers: { offer: IImportOffer, rating: number }[];
            let sortOffer: { offer: IImportOffer, rating: number };
            let resultOffers: IImportOffer[] = [];

            sortOffers = Object.values(searchOffers).sort((a, b) => {
                return b.rating - a.rating;
            });
            for (sortOffer of sortOffers) {
                resultOffers.push(sortOffer.offer);
            }
            resolve(resultOffers);
        });
    }

    public getAllCurrentOffersExternalIds(): string[] {
        if (!this.units || !this.unitKits) {
            return [];
        }
        let externalId: 'externalGuid' | 'vendorCode';
        let externalIds: {[key: string]: string} = {};
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;
        let offers: IImportOffer[];
        let accessoryOffers: IImportOffer[] = [];
        let offer: IImportOffer;

        externalId = this.service.getOfferExternalId();
        for (group of this.units) {
            for (createUnit of group.items) {
                if (createUnit.notPrice) {
                    continue;
                }
                offers = this.getOffersByCreateObjectData(createUnit);
                for (offer of offers) {
                    if (offer[externalId]) {
                        externalIds[offer[externalId]] = offer[externalId];
                    }
                }
            }
        }
        this.setSelectAccessoryOffers(accessoryOffers);
        for (offer of accessoryOffers) {
            if (offer[externalId]) {
                externalIds[offer[externalId]] = offer[externalId];
            }
        }

        return Object.values(externalIds);
    }

    public getFacadesByFunctionalType(functionalType: string): IImportOffer[] {
        let facadeGood: IImportOffer;
        let facadeGoods: IImportOffer[] = [];
        if (!this.unitKits || !this.unitKits[GOOD_TYPE_FACADE]) {
            return facadeGoods;
        }
        for (facadeGood of this.unitKits[GOOD_TYPE_FACADE]) {
            if (facadeGood.functionalType === functionalType) {
                facadeGoods.push(facadeGood);
            }
        }

        return facadeGoods;
    }

    public setSelectKitCode(kitCode: TSelectItem) {
        this.selectKitCode = kitCode;
        this.service.sendToRedux({
            type: CHANGE_SELECT_KIT_CODE,
            payload: CommonHelper.deepCopy(this.selectKitCode)
        });
    }

    public setApronHeight(apronData: IApronData, height: number, setState?: boolean) {
        let newApronData: IApronData;

        if (!this.selectApron) {
            return false;
        }
        if (!apronData.heights ||
            !apronData.heights.includes(height)) {
            return false;
        }
        newApronData = CommonHelper.deepCopy(apronData);
        newApronData.height = height;
        if (this.selectApron.id !== newApronData.id || this.selectApron.height !== newApronData.height) {
            this.service.setApron(newApronData, setState);
        }
    }

    public setTabletopHeight(tabletopData: ITabletopData, height: number, setState?: boolean) {
        let newTabletopData: ITabletopData;

        if (!this.selectTabletop) {
            return false;
        }
        if (!tabletopData.heights ||
            !tabletopData.heights.includes(height)) {
            return false;
        }
        newTabletopData = CommonHelper.deepCopy(tabletopData);
        newTabletopData.height = height;
        if (this.selectTabletop.id !== newTabletopData.id || this.selectTabletop.height !== newTabletopData.height) {
            this.service.setTabletop(newTabletopData, setState);
        }
    }

    public getCreateEquipmentsFromClassName(className: TClassName): ICreateObjectData[] {
        let equipments: ICreateObjectData[] = [];
        let equipment: ICreateObjectData;
        let group: ICreateGroup;
        let option: IOption | IOptionGroup;
        let itemClassName: TClassName;
        let equipmentGroups: TCreateGroup[];

        if (!this.units) {
            return equipments;
        }
        equipmentGroups = [
            GROUP_EQUIPMENTS,
            GROUP_BUILTIN_EQUIPMENTS,
            GROUP_BOTTOM_EQUIPMENTS,
            GROUP_TOP_EQUIPMENTS,
            GROUP_BIG_EQUIPMENTS,
            GROUP_SINK_EQUIPMENTS
        ];
        for (group of this.units) {
            if (equipmentGroups.includes(group.id)) {
                for (equipment of group.items) {
                    for (option of equipment.options) {
                        if (option.id === 'className') {
                            itemClassName = (option as IOptionHiddenText).value as TClassName;
                            if (itemClassName === className) {
                                equipments.push(equipment);
                                break;
                            }
                        }
                    }
                }
            }
        }

        return equipments;
    }

    public getCatalogCodeOfferIds(
        catalogCode: string,
        filterParams: ICatalogCodeFilterParams
    ): string[] {
        let offerIds: string[] = [];
        let offer: IImportOffer;

        if (this.unitKits && this.unitKits[catalogCode]) {
            for (offer of this.unitKits[catalogCode]) {
                if (filterParams.functionalType && offer.functionalType !== filterParams.functionalType) {
                    continue;
                }
                offerIds.push(offer[this.service.getOfferExternalId()]);
            }
        }

        return offerIds;
    }

    public clearPricesCache() {
        this.prices = {};
        this.service.setUnitPricesToKitchenHelper();
    }

    public getFacadeIds(): string[] | undefined {
        let facadeIds: string[] = [];
        let facade: IFacadeData;

        if (!this.facades) {
            return undefined;
        }
        for (facade of this.facades) {
            facadeIds.push(facade.id);
        }

        return facadeIds.length ? facadeIds : undefined;
    }

    public getFacadeMaterialIds(facadeId: string): string[] | undefined {
        let facadeMaterialIds: string[] = [];
        let facadeMaterial: IFacadeMaterialData;

        if (!this.facadeMaterials) {
            return undefined;
        }
        for (facadeMaterial of this.facadeMaterials) {
            if (facadeMaterial.facade === facadeId) {
                facadeMaterialIds.push(facadeMaterial.id);
            }
        }

        return facadeMaterialIds.length ? facadeMaterialIds : undefined;
    }

    public getAvailableFacadeIds(): string[] | undefined {
        let allFacadeIds: string[] | undefined;

        if (!this.units) {
            return undefined;
        }
        allFacadeIds = this.getFacadeIds();

        return allFacadeIds;
    }

    public getAvailableFacadeMaterialIds(facadeId: string): string[] | undefined {
        let facadeMaterials: { [key: string]: string } = {};
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;
        let availableFacadeMaterial: string;
        let availableFacadeMaterials: string[] | undefined;
        let allFacadeMaterialIds: string[] | undefined;
        let width: string;
        let allFacadeMaterials: string[] = [];

        if (!this.units) {
            return undefined;
        }
        allFacadeMaterialIds = this.getFacadeMaterialIds(facadeId);
        for (group of this.units) {
            for (createUnit of group.items) {
                if (createUnit.notPrice) {
                    continue;
                }
                if (createUnit.disable) {
                    continue;
                }
                if (createUnit.availableFacadeMaterials) {
                    allFacadeMaterials = [];
                    for (width in createUnit.availableFacadeMaterials) {
                        availableFacadeMaterials = createUnit.availableFacadeMaterials[width][facadeId];
                        if (availableFacadeMaterials) {
                            allFacadeMaterials = allFacadeMaterials.concat(availableFacadeMaterials);
                        }
                    }
                    for (availableFacadeMaterial of allFacadeMaterials) {
                        facadeMaterials[availableFacadeMaterial] = availableFacadeMaterial;
                    }
                } else if (allFacadeMaterialIds) {
                    return allFacadeMaterialIds;
                }
            }
        }

        return Object.keys(facadeMaterials).length ? Object.keys(facadeMaterials) : allFacadeMaterialIds;
    }

    public getDetailCollectionOffers(detailData: IDetailData, type: TGoodType, functionalTypes?: string[]): IImportOffer[] {
        let index: string;
        let allOffers: IImportOffer[];
        let offers: IImportOffer[] = [];
        let offer: IImportOffer | undefined;
        let offerPrice: IModulePriceData;

        if (detailData.offers) {
            for (index in detailData.offers) {
                if (detailData.offers[index].active !== undefined && !detailData.offers[index].active) {
                    continue;
                }
                offerPrice = this.service.getDetailPriceDataById(
                    detailData.offers[index].id,
                    0,
                    this.service.getPriceCellByGoodType(type),
                    0
                );
                if (offerPrice.active === false) {
                    continue;
                }
                if (this.service.getOptions().checkStore && !offerPrice.stock) {
                    continue;
                }
                offer = this.service.getOfferById(detailData.offers[index].id);
                if (offer) {
                    if (offer.functionalType && functionalTypes &&
                        functionalTypes.length && !functionalTypes.includes(offer.functionalType)) {
                        continue;
                    }
                    offers.push(offer);
                }
            }
        }
        allOffers = this.service.getOffersByCatalogCode(type);
        for (offer of allOffers) {
            if (detailData.id !== offer.corpusMaterial && detailData.colorFrom1C !== offer.corpusMaterial) {
                continue;
            }
            if (offer.functionalType && functionalTypes &&
                functionalTypes.length && !functionalTypes.includes(offer.functionalType)) {
                continue;
            }
            offerPrice = this.service.getDetailPriceDataById(
                offer[this.service.getOfferExternalId()],
                0,
                this.service.getPriceCellByGoodType(type),
                0
            );
            if (offerPrice.active === false) {
                continue;
            }
            if (this.service.getOptions().checkStore && !offerPrice.stock) {
                continue;
            }
            offers.push(offer);
        }

        return offers;
    }

    public isReady() {
        return (this.ready && !!this.technologMaps &&
            !!this.selectTabletop && !!this.selectApron &&
            !!this.selectCorner && !!this.selectPlinth);
    }

    public getTechnologMap(facadeId: string): ITechnologMap {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getTechnologMap');
        }
        let technologMap: ITechnologMap | undefined;

        technologMap = this.technologMaps[facadeId] || this.technologMaps["default"];
        if (!technologMap) {
            throw new Error('error-DataManager-getTechnologMap');
        }

        return technologMap;
    }

    public getFloorMaterial(materialId?: string): IMaterialData {
        if (!this.isReady() || !this.selectFloor || !this.floors) {
            throw new Error('error-DataManager-getPlinthDepth');
        }
        let floorData: IMaterialData;
        for (floorData of this.floors) {
            if (floorData.id === materialId) {
                return floorData;
            }
        }
        return this.selectFloor;
    }

    public getWallMaterial(materialId?: string): IMaterialData {
        if (!this.isReady() || !this.selectWall || !this.walls) {
            throw new Error('error-DataManager-getPlinthDepth');
        }
        let wallData: IMaterialData;

        if (materialId) {
            for (wallData of this.walls) {
                if (wallData.id === materialId) {
                    return wallData;
                }
            }
        }
        return this.selectWall;
    }

    public getFacadeGoods(): IImportOffer[] | undefined {
        if (this.unitKits && this.unitKits[GOOD_TYPE_FACADE]) {
            return this.unitKits[GOOD_TYPE_FACADE];
        }

        return undefined;
    }

    public getFacadeOffer(facade: ThreeFacade): IImportOffer | undefined {
        // TODO
        if (!this.unitKits || !this.unitKits[GOOD_TYPE_FACADE]) {
            return undefined;
        }
        let importGood: IImportOffer;

        for (importGood of this.unitKits[GOOD_TYPE_FACADE]) {
            if (importGood.width === facade.getWidth() &&
                importGood.height === facade.getHeight() &&
                importGood.depth === facade.getDepth() &&
                importGood.facadeMaterial === facade.getFacadeMaterialId() &&
                importGood.functionalType === facade.getFunctionalType() &&
                (importGood.sideType === KitchenHelper.calculateSideType(facade.getSideType(), importGood.sideType))) {
                return importGood;
            }
        }
    }

    public getHandleOffer(handle: ThreeHandle): IImportOffer | undefined {
        if (!this.unitKits || !this.unitKits[GOOD_TYPE_HANDLE]) {
            return undefined;
        }
        let importOffer: IImportOffer;
        let offerExternalId: 'externalGuid' | 'vendorCode';
        let handleOfferId: string | undefined;

        offerExternalId = this.service.getOfferExternalId();
        handleOfferId = handle.getHandleDataOfferId(offerExternalId);
        if (!handleOfferId) {
            return undefined;
        }
        for (importOffer of this.unitKits[GOOD_TYPE_HANDLE]) {
            if (importOffer[offerExternalId] === handleOfferId) {
                return importOffer;
            }
        }
        return undefined;
    }

    public getLegOffer(leg: ThreeLeg): IImportOffer | undefined {
        if (!this.unitKits || !this.unitKits[GOOD_TYPE_LEG]) {
            return undefined;
        }
        let importOffer: IImportOffer;

        for (importOffer of this.unitKits[GOOD_TYPE_LEG]) {
            if (importOffer.height === leg.getHeight()) {
                return importOffer;
            }
        }
        return undefined;
    }

    public getFurnitureOffer(functionalType: string, sizes?: TSizes3D): IImportOffer | undefined {
        if (!this.unitKits || !this.unitKits[GOOD_TYPE_FURNITURE]) {
            return undefined;
        }
        let importOffer: IImportOffer;

        for (importOffer of this.unitKits[GOOD_TYPE_FURNITURE]) {
            if (importOffer.functionalType === functionalType &&
                (!sizes || (
                    sizes.height === importOffer.height &&
                    sizes.depth === importOffer.depth &&
                    sizes.width === importOffer.width))) {
                return importOffer;
            }
        }

        return undefined;
    }

    public getPlankOffer(plank: IAccessoryPlank): IImportOffer | undefined {
        if (!this.unitKits || !this.unitKits[GOOD_TYPE_FURNITURE]) {
            return undefined;
        }
        let importOffer: IImportOffer;

        for (importOffer of this.unitKits[GOOD_TYPE_FURNITURE]) {
            if (this.comparePlankFunctionalType(importOffer.functionalType, plank.functionalType) &&
                this.comparePlankSizes(importOffer, plank)) {
                return importOffer;
            }
        }

        return undefined;
    }

    public getDetailOffer(detail: ThreeKUnitDetail, type: string): IImportOffer | undefined {
        if (!this.unitKits || !this.unitKits[type]) {
            return undefined;
        }
        let importGood: IImportOffer;

        for (importGood of this.unitKits[type]) {
            if (importGood.width === detail.getGoodLength() &&
                importGood.height === detail.getGoodHeight() &&
                importGood.depth === detail.getGoodWidth() &&
                importGood.corpusMaterial === detail.getMaterialId() &&
                (!detail.getFunctionalType() || detail.getFunctionalType() === importGood.functionalType) &&
                (importGood.sideType === KitchenHelper.calculateSideType(detail.getSideType(), importGood.sideType))) {
                return importGood;
            }
        }

        return undefined;
    }

    public getImportGood(offerId: string, catalogCode?: string): IImportOffer | undefined {
        if (!this.unitKits) {
            return undefined;
        }
        let group: string;
        let importGood: IImportOffer;

        if (catalogCode && this.unitKits[catalogCode]) {
            for (importGood of this.unitKits[catalogCode]) {
                if (importGood[this.service.getOfferExternalId()] === offerId) {
                    return importGood;
                }
            }
        }

        for (group in this.unitKits) {
            for (importGood of this.unitKits[group]) {
                if (importGood[this.service.getOfferExternalId()] === offerId) {
                    return importGood;
                }
            }
        }
        return undefined;
    }

    public loadCreateObjectPrices(createObject: ICreateObjectData): Promise<IUnitKitPrices> {
        let externalIds: string[];

        externalIds = this.getCreateObjectOfferIds(createObject);

        return this.loadPrices(externalIds);
    }

    public getCreateObjectOfferIds(createObject: ICreateObjectData): string[] {
        switch (this.service.getCreateObjectCalculateType(createObject)) {
            case CATALOG_CALCULATE_TYPE_DETAILS:
                return this.getCreateObjectOfferIdsByDetails(createObject);
            case CATALOG_CALCULATE_TYPE_MODULE:
                return this.getCreateObjectOfferIdsByModule(createObject);
            default:
                return [];
        }
    }

    public checkLoadOfferPrice(offerId: string): boolean {
         if (!this.service.isCatalogCalculatePrice()) {
            return true;
        }
        return !!this.prices[offerId];
    }

    public loadPrices(productIds: string[]): Promise<IUnitKitPrices> {
        return new Promise<IUnitKitPrices>((resolve, reject) => {
            let prices: { [key: string]: IProductPrice } = {};
            let loadIds: { [key: string]: string } = {};
            let productId: string;

            if (!this.service.isCatalogCalculatePrice()) {
                resolve(prices);
                return;
            }
            for (productId of productIds) {
                if (!productId.length) {
                    continue;
                }
                if (this.prices[productId]) {
                    prices[productId] = this.prices[productId];
                } else {
                    loadIds[productId] = productId;
                }
            }
            if (Object.values(loadIds).length > 0) {
                this.service.sendToRedux({type: LOADING_PRICE, payload: true})
                this.loadApiPrices(Object.values(loadIds), this.service.appConfig.catalog.offerExternalId).then((loadPrices: IUnitKitPrices) => {
                    for (productId of productIds) {
                        if (loadPrices[productId]) {
                            this.prices[productId] = loadPrices[productId];
                        }
                        if (!this.prices[productId]) {
                            console.error('Not load price for ' + productId);
                            this.prices[productId] = {
                                id: productId,
                                price: 0,
                                active: false
                            }
                        }
                        prices[productId] = this.prices[productId];
                    }
                    this.service.sendToRedux({type: LOADING_PRICE, payload: false});
                    this.service.setUnitPricesToKitchenHelper();
                    resolve(prices);
                }).catch(() => {
                    this.service.sendToRedux({type: LOADING_PRICE, payload: false});
                    this.service.showMessage({
                        type: MESSAGE_TYPE_ERROR,
                        message: i18n.t('Не удалось получить все цены с сервера. Возможны ошибки в отображении каталога'),
                        params: {
                            id: 'loadPricesError'
                        }
                    });
                    reject('loadPostMessagePrices error!');
                })
            } else {
                resolve(prices);
            }
        });
    }

    public loadApiPrices(priceIds: string[], externalId?: TOfferExternalId): Promise<IUnitKitPrices> {
        return new Promise<{ [p: string]: IProductPrice }>((resolve, reject) => {
            let index: number;
            let count: number;
            let iterations: number;
            let iterationPriceIds: string[];
            let loadPromises: Promise<AxiosResponse>[] = [];
            const limit: number = 500;
            if (priceIds.length <= 0) {
                reject();
                throw new Error('loadApiPrices empty priceIds!');
            }
            count = priceIds.length;
            iterations = Math.ceil(count / limit);
            for (index = 0; index < iterations; index++) {
                iterationPriceIds = [...priceIds].splice(index * limit, limit);
                if (iterationPriceIds.length <= 0) {
                    break;
                }
                loadPromises.push(axios.post('/api/catalog/prices/', {
                    collection: this.collectionId,
                    priceId: this.service.getCatalogPriceId(),
                    config: this.service.appConfig.id,
                    ids: iterationPriceIds,
                    externalId: externalId
                }));
            }
            Promise.all(loadPromises)
                .then((responses: AxiosResponse[]) => {
                    let unitPrices: IUnitKitPrices = {};
                    let response: AxiosResponse;
                    let index: string;

                    for (response of responses) {
                        for (index in response.data) {
                            unitPrices[index] = response.data[index];
                        }
                    }
                    resolve(unitPrices);
                })
                .catch(() => {
                    reject();
                });
        })
    }

    public getGlassMaterial(materialId?: string, facadeId?: string): IMaterialData {
        let glass: IMaterialData;
        let defaultGlass: IMaterialData | undefined;

        if (this.glasses) {
            for (glass of this.glasses) {
                if (!defaultGlass && glass.isDefault &&
                    (!facadeId || !glass.enableFacades || glass.enableFacades.includes(facadeId)) &&
                    (!facadeId || !glass.disableFacades || !glass.disableFacades.includes(facadeId))) {
                    defaultGlass = glass;
                }
                if (!materialId && facadeId && (glass.enableFacades && glass.enableFacades.includes(facadeId))) {
                    return glass;
                }
                if (materialId && materialId === glass.id) {
                    return glass;
                }
            }
        }
        if (defaultGlass) {
            return defaultGlass;
        }

        if (this.selectGlass) {
            return this.selectGlass;
        }

        return {
            id: 'glassDefault',
            color: '#ffffff',
            title: 'Стекло по-умолчанию',
            opacity: 0.5,
        };
    }

    public getFacadeMaterialByFacadeId(facadeId: string, level: TLevel): IFacadeMaterialData | undefined {
        let facadeMaterial: IFacadeMaterialData;
        let facadeMaterials: IFacadeMaterialData[] = [];

        if (!this.facadeMaterials) {
            return undefined;
        }
        for (facadeMaterial of this.facadeMaterials) {
            if (facadeMaterial.facade === facadeId) {
                facadeMaterials.push(facadeMaterial);
            }
        }
        for (facadeMaterial of facadeMaterials) {
            if ((facadeMaterial.isDefault ||
                    (level === LEVEL_BOTTOM && facadeMaterial.isBottomDefault) ||
                    (level === LEVEL_TOP && facadeMaterial.isTopDefault)) &&
                (!facadeMaterial.level || facadeMaterial.level === level)) {
                return facadeMaterial;
            }
        }
        for (facadeMaterial of facadeMaterials) {
            if ((!facadeMaterial.level || facadeMaterial.level === level)) {
                return facadeMaterial;
            }
        }

        return undefined;
    }

    public getCorpusMaterialsObject(): ICorpusMaterials {
        let corpusMaterials: ICorpusMaterials = {};
        let corpusMaterial: IMaterialData;

        if (this.corpusMaterials && this.corpusMaterials.length) {
            for (corpusMaterial of this.corpusMaterials) {
                corpusMaterials[corpusMaterial.id] = corpusMaterial;
            }
        }

        return corpusMaterials;
    }

    public getGlassesObject(): ICorpusMaterials {
        let glasses: ICorpusMaterials = {};
        let corpusMaterial: IMaterialData;

        if (this.glasses && this.glasses.length) {
            for (corpusMaterial of this.glasses) {
                glasses[corpusMaterial.id] = corpusMaterial;
            }
        }

        return glasses;
    }

    public getCorpusMaterialById(materialId: string): IMaterialData | undefined {
        if (!this.corpusMaterials) {
            return undefined;
        }
        let corpusMaterial: IMaterialData;

        for (corpusMaterial of this.corpusMaterials) {
            if (corpusMaterial.id === materialId) {
                return corpusMaterial;
            }
        }

        return undefined;
    }

    public getFacadeMaterialsObject(): IFacadeMaterials {
        let facadeMaterials: IFacadeMaterials = {};
        let facadeMaterial: IFacadeMaterialData;

        if (this.facadeMaterials && this.facadeMaterials.length) {
            for (facadeMaterial of this.facadeMaterials) {
                facadeMaterials[facadeMaterial.id] = facadeMaterial;
            }
        }

        return facadeMaterials;
    }

    public getFacadeMaterialById(materialId: string): IFacadeMaterialData | undefined {
        let facadeMaterial: IFacadeMaterialData;

        if (!this.facadeMaterials) {
            return undefined;
        }
        for (facadeMaterial of this.facadeMaterials) {
            if (facadeMaterial.id === materialId) {
                return facadeMaterial;
            }
        }
        return undefined;
    }

    public getFacadeMaterial(level: TLevel, facadeMaterialId?: string): IFacadeMaterialData {
        if (!this.isReady() || !this.topFacadeMaterial || !this.bottomFacadeMaterial) {
            throw new Error('error-DataManager-getFacadeMaterial');
        }
        let material: IFacadeMaterialData | undefined;

        if (facadeMaterialId) {
            material = this.getFacadeMaterialById(facadeMaterialId);
            if (material) {
                return material;
            }
        }
        switch (level) {
            case LEVEL_TOP:
                return this.topFacadeMaterial;
            case LEVEL_BOTTOM:
            default:
                return this.bottomFacadeMaterial;
        }
    }

    public getCorpusFacadeMaterial(facadeMaterialId?: string): IFacadeMaterialData | undefined {
        if (!this.isReady()) {
            return undefined;
        }
        let material: IFacadeMaterialData | undefined;
        let facadeMaterial: IFacadeMaterialData;
        let defaultCorpusFacadeMaterial: IFacadeMaterialData | undefined;

        if (facadeMaterialId) {
            material = this.getFacadeMaterialById(ALL_FACADES + '-' + facadeMaterialId);
            if (material && material.facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL) {
                return material;
            }
        }
        if (this.facadeMaterials) {
            for (facadeMaterial of this.facadeMaterials) {
                if (!defaultCorpusFacadeMaterial &&
                    facadeMaterial.facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL) {
                    defaultCorpusFacadeMaterial = facadeMaterial;
                }
                if (facadeMaterial.facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL &&
                    this.selectCorpusMaterial && this.selectCorpusMaterial.id === facadeMaterial.material) {
                    return facadeMaterial;
                }
            }
        }
        if (defaultCorpusFacadeMaterial) {
            return defaultCorpusFacadeMaterial;
        }

        return undefined;
    }

    public getPanelMaterial(materialId: string): IMaterialData | undefined {
        if (!this.panelMaterials) {
            return undefined;
        }
        let materialData: IMaterialData;

        for (materialData of this.panelMaterials) {
            if (materialData.id === materialId) {
                return materialData;
            }
        }

        return undefined;
    }

    public getMaterial(level: TLevel, materialId?: string): IMaterialData {
        if (!this.isReady() || !this.topFacadeMaterial || !this.bottomFacadeMaterial) {
            throw new Error('error-DataManager-getMaterial');
        }
        let material: IMaterialData | undefined;

        if (materialId) {
            material = this.getMaterialById(materialId);
            if (material) {
                return material;
            }
        }
        switch (level) {
            case LEVEL_TOP:
                material = this.getMaterialById(this.topFacadeMaterial.material);
                if (material) {
                    return material;
                }
                break;
            case LEVEL_BOTTOM:
            default:
                material = this.getMaterialById(this.bottomFacadeMaterial.material);
                if (material) {
                    return material;
                }
                break;
        }

        throw new Error('error-DataManager-getMaterial');
    }

    public getMaterialById(materialId: string): IMaterialData | undefined {
        if (!this.materials) {
            return undefined;
        }
        let materialData: IMaterialData;

        for (materialData of this.materials) {
            if (materialData.id === materialId) {
                return materialData;
            }
        }

        return undefined;
    }

    public getPlinthDepth(): number {
        if (!this.isReady() || !this.selectPlinth) {
            throw new Error('error-DataManager-getPlinthDepth');
        }

        return this.selectPlinth.depth;
    }

    public getPlinthHeight(): number {
        if (!this.isReady() || !this.selectPlinth) {
            throw new Error('error-DataManager-getPlinthHeight');
        }

        return this.selectPlinth.height;
    }

    public getApronHeight(): number {
        if (!this.isReady() || !this.selectApron) {
            throw new Error('error-DataManager-getApronHeight');
        }

        return this.selectApron.height;
    }

    public getTabletopHeight(): number {
        if (!this.isReady() || !this.selectTabletop) {
            throw new Error('error-DataManager-getTabletopHeight');
        }

        return this.selectTabletop.height;
    }

    public getTabletopFrontGap(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getTabletopFrontGap');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return +technologMap.tabletops.frontGap;
    }

    public getTabletopCuttingGap(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getTabletopCuttingGap');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return technologMap.tabletops.cuttingGap !== undefined && !isNaN(+technologMap.tabletops.cuttingGap) ?
            +technologMap.tabletops.cuttingGap : 0;
    }

    public getApronCuttingGap(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getApronCuttingGap');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return technologMap.aprons.cuttingGap !== undefined && !isNaN(+technologMap.aprons.cuttingGap) ?
            +technologMap.aprons.cuttingGap : 0;
    }

    public getPlinthCuttingGap(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getPlinthCuttingGap');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return technologMap.plinths.cuttingGap !== undefined && !isNaN(+technologMap.plinths.cuttingGap) ?
            +technologMap.plinths.cuttingGap : 0;
    }

    public getTabletopWidth(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getTabletopFrontGap');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return +technologMap.tabletops.defaultWidth;
    }

    public getCornerHeight(): number {
        if (!this.isReady() || !this.selectCorner) {
            throw new Error('error-DataManager-getCornerHeight');
        }

        return this.selectCorner.height;
    }

    public getIntegrationHandleHeight(): number {
        if (!this.isReady() || !this.selectIntegratedHandle) {
            throw new Error('error-DataManager-getIntegrationHandleHeight');
        }

        return this.selectIntegratedHandle.height;
    }

    public getCornerWidth(): number {
        if (!this.isReady() || !this.selectCorner) {
            throw new Error('error-DataManager-getCornerWidth');
        }

        return this.selectCorner.width;
    }

    public getIntegrationHandleWidth(): number {
        if (!this.isReady() || !this.selectIntegratedHandle) {
            throw new Error('error-DataManager-getIntegrationHandleWidth');
        }

        return this.selectIntegratedHandle.width;
    }

    public getCornerContourPath(): TPoint2D[] | undefined {
        if (this.isReady() && this.selectCorner && this.selectCorner.contourPath) {
            return this.selectCorner.contourPath;
        }
        return undefined;
    }

    public getBottomUnitHeight(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getBottomUnitHeight');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return +technologMap.bottomUnits.defaultHeight;
    }

    public getBottomUnitDepth(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getBottomUnitDepth');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return +technologMap.bottomUnits.defaultDepth;
    }

    public getBottomUnitLegsHeight(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getBottomUnitLegsHeight');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return +technologMap.bottomUnits.legsHeight;
    }

    public getTopUnitHeight(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getTopUnitHeight');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return +technologMap.topUnits.defaultHeight;
    }

    public getInstallApronHeight(facadeId: string): number {
        if (!this.isReady() || !this.technologMaps) {
            throw new Error('error-DataManager-getTabletopFrontGap');
        }
        const technologMap: ITechnologMap = this.getTechnologMap(facadeId);

        return +technologMap.aprons.installHeight;
    }

    public getTabletopMaterial(materialId?: string): ITabletopData {
        if (!this.isReady() || !this.tabletops || !this.selectTabletop) {
            throw new Error('error-DataManager-getTabletopMaterial');
        }
        let tabletopData: ITabletopData;

        if (!materialId) {
            return this.selectTabletop;
        }
        for (tabletopData of this.tabletops) {
            if (tabletopData.id === materialId) {
                return tabletopData;
            }
        }

        return this.selectTabletop;
    }

    public getCorpusMaterial(materialId?: string, facadeMaterial?: IFacadeMaterialData): IMaterialData {
        if (!this.isReady() || !this.corpusMaterials || !this.selectCorpusMaterial) {
            throw new Error('error-DataManager-getCorpusMaterial');
        }
        if (materialId === NONE_MATERIAL) {
            return {
                id: NONE_MATERIAL,
                title: i18n.t('Без материала'),
            }
        }
        let materialData: IMaterialData;
        let selectCorpusMaterial: IMaterialData | undefined;

        if (!materialId) {
            if (!facadeMaterial) {
                selectCorpusMaterial = this.selectCorpusMaterial;
            }
            if (facadeMaterial && (!this.selectCorpusMaterial.level || (this.selectCorpusMaterial.level &&
                    (!facadeMaterial || facadeMaterial.level === this.selectCorpusMaterial.level))) &&
                (!facadeMaterial.corpusMaterial || facadeMaterial.corpusMaterial === this.selectCorpusMaterial.id)) {
                if ((!this.selectCorpusMaterial.enableFacades ||
                        this.selectCorpusMaterial.enableFacades.includes(facadeMaterial.facade)) &&
                    (!this.selectCorpusMaterial.disableFacades ||
                        !this.selectCorpusMaterial.disableFacades.includes(facadeMaterial.facade))) {
                    selectCorpusMaterial = this.selectCorpusMaterial;
                }
            }
        }
        if (!selectCorpusMaterial) {
            for (materialData of this.corpusMaterials) {
                if (!materialId || materialData.id === materialId) {
                    if (!facadeMaterial) {
                        selectCorpusMaterial = materialData;
                        break;
                    }
                    if (facadeMaterial && (!materialData.level || (materialData.level &&
                            (!facadeMaterial || facadeMaterial.level === materialData.level))) &&
                        (!facadeMaterial.corpusMaterial || facadeMaterial.corpusMaterial === materialData.id)) {
                        if ((!materialData.enableFacades ||
                                materialData.enableFacades.includes(facadeMaterial.facade)) &&
                            (!materialData.disableFacades ||
                                !materialData.disableFacades.includes(facadeMaterial.facade))) {
                            selectCorpusMaterial = materialData;
                            break;
                        }
                    }
                }
            }
        }

        return selectCorpusMaterial || this.selectCorpusMaterial;
    }

    public getApronMaterial(materialId?: string): IApronData {
        if (!this.isReady() || !this.aprons || !this.selectApron) {
            throw new Error('error-DataManager-getApronMaterial');
        }
        let apronData: IApronData;

        if (!materialId) {
            return this.selectApron;
        }
        for (apronData of this.aprons) {
            if (apronData.disable === true) {
                continue;
            }
            if (apronData.id === materialId) {
                return apronData;
            }
        }

        return this.selectApron;
    }

    public getCornerMaterial(materialId?: string): ICornerData {
        if (!this.isReady() || !this.corners || !this.selectCorner) {
            throw new Error('error-DataManager-getCornerMaterial');
        }
        let cornerData: ICornerData;

        if (!materialId) {
            return this.selectCorner;
        }
        for (cornerData of this.corners) {
            if (cornerData.id === materialId) {
                return cornerData;
            }
        }

        return this.selectCorner;
    }

    public getIntegratedHandleMaterial(materialId?: string): IIntegratedHandleData {
        if (!this.isReady() || !this.integratedHandles || !this.selectIntegratedHandle) {
            throw new Error('error-DataManager-getIntegratedHandleMaterial');
        }
        let integratedHandleData: IIntegratedHandleData;

        if (!materialId) {
            return this.selectIntegratedHandle;
        }
        for (integratedHandleData of this.integratedHandles) {
            if (integratedHandleData.id === materialId) {
                return integratedHandleData;
            }
        }

        return this.selectIntegratedHandle;
    }

    public getPlinthMaterial(materialId?: string, corpusMaterialId?: string): IPlinthData {
        if (!this.isReady() || !this.plinths || !this.selectPlinth) {
            throw new Error('error-DataManager-getPlinthMaterial');
        }
        let plinthData: IPlinthData;
        let corpusMaterial: IMaterialData | undefined;

        if (corpusMaterialId) {
            corpusMaterial = this.getCorpusMaterial(corpusMaterialId);
        }
        if (!materialId &&
            (!corpusMaterial ||
                !corpusMaterial.plinthMaterial ||
                corpusMaterial.plinthMaterial === this.selectPlinth.id)) {
            return this.selectPlinth;
        }
        if (!materialId && corpusMaterial && corpusMaterial.plinthMaterial) {
            materialId = corpusMaterial.plinthMaterial;
        }
        for (plinthData of this.plinths) {
            if (plinthData.id === materialId) {
                return plinthData;
            }
        }

        return this.selectPlinth;
    }

    public getTopFirstYPosition(): number {
        let bottomFacadeId: string;

        bottomFacadeId = this.bottomFacadeMaterial ? this.bottomFacadeMaterial.facade : "default";
        return this.getBottomUnitHeight(bottomFacadeId) + this.getBottomUnitLegsHeight(bottomFacadeId) +
            this.getInstallApronHeight(bottomFacadeId);
    }

    public getInstallTopUnitHeight() {
        let bottomFacadeId: string;
        let topFacadeId: string;

        bottomFacadeId = this.bottomFacadeMaterial ? this.bottomFacadeMaterial.facade : "default";
        topFacadeId = this.topFacadeMaterial ? this.topFacadeMaterial.facade : "default";
        return this.getBottomUnitHeight(bottomFacadeId) + this.getBottomUnitLegsHeight(bottomFacadeId) +
            this.getInstallApronHeight(bottomFacadeId) + this.getTopUnitHeight(topFacadeId);
    }

    public getInstallBottomUnitHeight() {
        let bottomFacadeId: string;

        bottomFacadeId = this.bottomFacadeMaterial ? this.bottomFacadeMaterial.facade : "default";
        return this.getBottomUnitHeight(bottomFacadeId) + this.getBottomUnitLegsHeight(bottomFacadeId) +
            this.getTabletopHeight();
    }

    public getFacadeMaterials(facadeId: string): IFacadeMaterialData[] {
        let facadeMaterial: IFacadeMaterialData;
        let facadeMaterials: IFacadeMaterialData[] = [];

        if (!this.facadeMaterials) {
            return facadeMaterials;
        }
        for (facadeMaterial of this.facadeMaterials) {
            if (facadeMaterial.facade === facadeId) {
                facadeMaterials.push(facadeMaterial);
            }
        }
        return facadeMaterials;
    }

    public getFacadeData(id: string): IFacadeData | undefined {
        if (!this.facades) {
            throw new Error('error-DataManager-getFacadeData');
        }
        let facadeData: IFacadeData;

        for (facadeData of this.facades) {
            if (facadeData.id === id) {
                return facadeData;
            }
        }

        return undefined;
    }

    public getCreateUnitByUid(uid: string): ICreateObjectData | undefined {
        if (!this.units) {
            return undefined;
        }
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;

        for (group of this.units) {
            for (createUnit of group.items) {
                if (createUnit.uid === uid) {
                    return createUnit;
                }
            }
        }

        return undefined;
    }

    public getCreateConstructiveByUid(uid: string): ICreateObjectData | undefined {
        if (!this.constructiveList) {
            return undefined;
        }
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;

        for (group of this.constructiveList) {
            for (createUnit of group.items) {
                if (createUnit.uid === uid) {
                    return createUnit;
                }
            }
        }

        return undefined;
    }

    public getCreateUnitByCatalogCode(catalogCode: string): ICreateObjectData | undefined {
        if (!this.units) {
            return undefined;
        }
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;

        for (group of this.units) {
            for (createUnit of group.items) {
                if (createUnit.catalogCode === catalogCode) {
                    return createUnit;
                }
            }
        }

        return undefined;
    }

    public getHandleData(id: string): IHandleData | undefined {
        if (!this.handles) {
            throw new Error('error-DataManager-getHandleData');
        }
        let handleData: IHandleData;
        let defaultHandle: IHandleData | undefined;

        for (handleData of this.handles) {
            if (handleData.id === DEFAULT_HANDLE_ID) {
                defaultHandle = handleData;
            }
             // TODO: fix
            if (handleData.id === id.toLowerCase()) {
                return handleData;
            }
        }
        if (defaultHandle) {
            return defaultHandle;
        }

        return undefined;
    }

    public getDefaultFacadeData(level: TLevel): IFacadeData {
        if (!this.facades || !this.topFacadeMaterial || !this.bottomFacadeMaterial) {
            throw new Error('error-DataManager-getDefaultFacadeData');
        }
        let facadeData: IFacadeData;
        let facadeMaterial: IFacadeMaterialData;

        switch (level) {
            case LEVEL_TOP:
                facadeMaterial = this.topFacadeMaterial;
                break;
            case LEVEL_BOTTOM:
            default:
                facadeMaterial = this.bottomFacadeMaterial;
                break;
        }
        for (facadeData of this.facades) {
            if (facadeData.id === facadeMaterial.facade) {
                return facadeData;
            }
        }
        throw new Error('error-DataManager-getDefaultFacadeData');
    }

    public setBottomFacadeMaterial(data: IFacadeMaterialData) {
        if (data.level && data.level !== LEVEL_BOTTOM) {
            return;
        }
        let newHandle: IHandleData | undefined;
        let newFacade: IFacadeData | undefined;
        let newPlinth: IPlinthData | undefined;
        let newPlinthId: string | undefined;

        this.bottomFacadeMaterial = data;
        this.service.sendToRedux({
            type: CHANGE_BOTTOM_FACADE_MATERIAL,
            payload: CommonHelper.deepCopy(this.bottomFacadeMaterial)
        });
        newFacade = this.getFacadeData(this.bottomFacadeMaterial.facade);
        if (newFacade && newFacade.plinthMaterial) {
            newPlinthId = newFacade.plinthMaterial;
        }
        if (data.plinthMaterial) {
            newPlinthId = data.plinthMaterial;
        }
        if (newFacade && newFacade.handle && newFacade.handle.id &&
            this.bottomHandle && this.bottomHandle.notPrice) {
            newHandle = this.getHandleData(newFacade.handle.id);
            if (newHandle && newHandle.id !== this.bottomHandle.id) {
                this.setBottomHandle(newHandle);
            }
        }
        if (newPlinthId) {
            newPlinth = this.getPlinthMaterial(newPlinthId);
            if (newPlinth && (!this.selectPlinth || (this.selectPlinth && newPlinth.id !== this.selectPlinth.id))) {
                this.setPlinth(newPlinth);
            }
        }
    }

    public setTopFacadeMaterial(data: IFacadeMaterialData) {
        if (data.level && data.level !== LEVEL_TOP) {
            return;
        }
        let newHandle: IHandleData | undefined;
        let newFacade: IFacadeData | undefined;

        this.topFacadeMaterial = data;
        this.service.sendToRedux({
            type: CHANGE_TOP_FACADE_MATERIAL,
            payload: CommonHelper.deepCopy(this.topFacadeMaterial)
        });
        newFacade = this.getFacadeData(this.topFacadeMaterial.facade);
        if (newFacade && newFacade.handle && newFacade.handle.id &&
            this.topHandle && this.topHandle.notPrice) {
            newHandle = this.getHandleData(newFacade.handle.id);
            if (newHandle && newHandle.id !== this.topHandle.id) {
                this.setTopHandle(newHandle);
            }
        }
    }

    public setTabletop(data: ITabletopData, cornerMaterial?: ICornerData) {
        if (this.selectTabletop && this.selectTabletop.id === data.id &&
            this.selectTabletop.height === data.height) {
            return;
        }
        this.selectTabletop = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_TABLETOP,
            payload: CommonHelper.deepCopy(this.selectTabletop)
        });
        if (cornerMaterial) {
            this.setCorner(cornerMaterial)
        }
    }

    public setApron(data: IApronData) {
        if (this.selectApron && this.selectApron.id === data.id &&
            this.selectApron.height === data.height) {
            return;
        }
        this.selectApron = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_APRON,
            payload: CommonHelper.deepCopy(this.selectApron)
        });
    }

    public setCorner(data: ICornerData) {
        if (this.selectCorner && this.selectCorner.id === data.id) {
            return;
        }
        this.selectCorner = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_CORNER,
            payload: CommonHelper.deepCopy(this.selectCorner)
        });
    }

    public setIntegratedHandle(data: IIntegratedHandleData) {
        if (this.selectIntegratedHandle && this.selectIntegratedHandle.id === data.id) {
            return;
        }
        this.selectIntegratedHandle = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_INTEGRATED_HANDLE,
            payload: CommonHelper.deepCopy(this.selectIntegratedHandle)
        });
    }

    public setPlinth(data: IPlinthData) {
        if (this.selectPlinth && this.selectPlinth.id === data.id &&
            this.selectPlinth.height === data.height) {
            return;
        }
        this.selectPlinth = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_PLINTH,
            payload: CommonHelper.deepCopy(this.selectPlinth)
        });
    }

    public setPlinthHeight(plinthData: IPlinthData, height: number, setState?: boolean) {
        let newPlinthData: IPlinthData;

        if (!this.selectPlinth) {
            return;
        }
        if (!plinthData.heights ||
            !plinthData.heights.includes(height)) {
            return;
        }
        newPlinthData = CommonHelper.deepCopy(plinthData);
        newPlinthData.height = height;
        if (this.selectPlinth.id !== newPlinthData.id || this.selectPlinth.height !== newPlinthData.height) {
            this.service.setPlinth(newPlinthData, undefined, setState);
        }
    }

    public setFloor(data: IMaterialData) {
        if (this.selectFloor && this.selectFloor.id === data.id) {
            return;
        }
        this.selectFloor = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_FLOOR,
            payload: CommonHelper.deepCopy(this.selectFloor)
        });
    }

    public setWall(data: IMaterialData) {
        if (this.selectWall && this.selectWall.id === data.id) {
            return;
        }
        this.selectWall = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_WALL,
            payload: CommonHelper.deepCopy(this.selectWall)
        });
    }

    public setCorpusMaterial(data: IMaterialData) {
        if (this.selectCorpusMaterial && this.selectCorpusMaterial.id === data.id) {
            return;
        }
        this.selectCorpusMaterial = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_CORPUS_MATERIAL,
            payload: CommonHelper.deepCopy(this.selectCorpusMaterial)
        });
    }

    public setGlass(data: IMaterialData) {
        if (this.selectGlass && this.selectGlass.id === data.id) {
            return;
        }
        this.selectGlass = data;
        this.service.sendToRedux({
            type: CHANGE_SELECT_GLASS,
            payload: CommonHelper.deepCopy(this.selectGlass)
        });
    }

    public getEquipmentModel(equipment: ThreeEquipment): IEquipmentModelData {
        let equipmentModel;
        let currentEquipmentModel: IEquipmentModelData | undefined;
        let defaultEquipmentModel: IEquipmentModelData | undefined;

        if (!this.equipmentModels) {
            throw new Error('error-DataManager-getEquipmentModel');
        }

        for (equipmentModel of this.equipmentModels) {
            if (equipment.getThreeModelId() === equipmentModel.id) {
                return equipmentModel;
            }
            if (equipment.getThreeModelId() === equipmentModel.model) {
                if (!defaultEquipmentModel) {
                    defaultEquipmentModel = equipmentModel;
                }
                if (!currentEquipmentModel) {
                    currentEquipmentModel = equipmentModel;
                } else if (Math.abs((equipment.getHeight() - currentEquipmentModel.height)) > Math.abs((equipment.getHeight() - equipmentModel.height)) ||
                    Math.abs((equipment.getWidth() - currentEquipmentModel.width)) > Math.abs((equipment.getWidth() - equipmentModel.width)) ||
                    Math.abs((equipment.getDepth() - currentEquipmentModel.depth)) > Math.abs((equipment.getDepth() - equipmentModel.depth))) {
                    currentEquipmentModel = equipmentModel;
                }
            }
        }
        if (currentEquipmentModel) {
            return currentEquipmentModel;
        }
        if (defaultEquipmentModel) {
            return defaultEquipmentModel;
        }

        throw new Error('error-DataManager-getEquipmentModel');
    }

    public getConstructiveModel(constructive: ThreeConstructiveModel): IConstructiveModelData {
        let constructiveModel;
        let currentConstructiveModel: IConstructiveModelData | undefined;
        let defaultConstructiveModel: IConstructiveModelData | undefined;

        if (!this.constructiveModels) {
            throw new Error('error-DataManager-getConstructiveModel');
        }

        for (constructiveModel of this.constructiveModels) {
            if (constructive.getThreeModelId() === constructiveModel.model) {
                if (!defaultConstructiveModel) {
                    defaultConstructiveModel = constructiveModel;
                }
                if (!currentConstructiveModel) {
                    currentConstructiveModel = constructiveModel;
                } else if (Math.abs((constructive.getHeight() - currentConstructiveModel.height)) > Math.abs((constructive.getHeight() - constructiveModel.height)) ||
                    Math.abs((constructive.getWidth() - currentConstructiveModel.width)) > Math.abs((constructive.getWidth() - constructiveModel.width)) ||
                    Math.abs((constructive.getDepth() - currentConstructiveModel.depth)) > Math.abs((constructive.getDepth() - constructiveModel.depth))) {
                    currentConstructiveModel = constructiveModel;
                }
            }
        }
        if (currentConstructiveModel) {
            return currentConstructiveModel;
        }
        if (defaultConstructiveModel) {
            return defaultConstructiveModel;
        }

        throw new Error('error-DataManager-getConstructiveModel');
    }

    public getMaxDetailLength(material: string, code: string): number | undefined {
        if (!this.unitKits ||
            !this.unitKits[code] ||
            this.unitKits[code].length <= 0) {
            return undefined;
        }
        let tabletop: IImportOffer;
        for (tabletop of this.unitKits[code]) {
            if (tabletop.corpusMaterial === material) {
                return tabletop.width;
            }
        }

        return undefined;
    }

    public getKitCodeById(kitCodeId: string): TSelectItem | undefined {
        if (!this.kitCodes) {
            return undefined;
        }
        let kitCode: TSelectItem;

        for (kitCode of this.kitCodes) {
            if (kitCode.id === kitCodeId) {
                return kitCode;
            }
        }

        return undefined;
    }

    public disableCatalog(showLoading?: boolean) {
        // Добавил скрытие меню, так как ругалось на изменение
        // редакс хранилища из-за открытия свойств объекта
        this.service.hideMenus();
        if (showLoading !== false) {
            this.service.sendToRedux({type: SHOW_LOADING});
        }
        this.disableUnits();
        this.disableAccessories();
        if (showLoading !== false) {
            this.service.sendToRedux({type: HIDE_LOADING});
        }
    }

    public disableUnits() {
        this.enableUnits();
        this.disableUnitsByOffers();
        this.service.sendToRedux({
            type: CHANGE_UNIT_LIST,
            payload: CommonHelper.deepCopy(this.units)
        });
    }

    public disableAccessories() {
        let result: {list: IDetailData[], select: IDetailData} | undefined;

        result = this.disableAccessory(this.aprons, this.selectApron, GOOD_TYPE_APRON);
        if (result && result.list && result.list.length && result.select) {
            this.setAprons(result.list as IApronData[], this.selectApron);
            if (result.select.id !== this.selectApron?.id) {
                this.service.setApron(result.select as IApronData);
            }
        } else if (result) {
            console.error('!disableAccessory aprons', result);
        }
        result = this.disableAccessory(this.tabletops, this.selectTabletop, GOOD_TYPE_TABLETOP);
        if (result && result.list && result.list.length && result.select) {
            this.setTabletops(result.list as ITabletopData[], this.selectTabletop);
            if (result.select.id !== this.selectTabletop?.id) {
                this.service.setTabletop(result.select as ITabletopData);
            }
        } else if (result) {
            console.error('!disableAccessory tabletops', result);
        }
        result = this.disableAccessory(this.corners, this.selectCorner, GOOD_TYPE_CORNER);
        if (result && result.list && result.list.length && result.select) {
            this.setCorners(result.list as ICornerData[], this.selectCorner);
            if (result.select.id !== this.selectCorner?.id) {
                this.service.setCorner(result.select as ICornerData);
            }
        } else if (result) {
            console.error('!disableAccessory corners', result);
        }
        result = this.disableAccessory(this.plinths, this.selectPlinth, GOOD_TYPE_PLINTH);
        if (result && result.list && result.list.length && result.select) {
            this.setPlinths(result.list as IPlinthData[], this.selectPlinth);
            if (result.select.id !== this.selectPlinth?.id) {
                this.service.setPlinth(result.select as IPlinthData);
                if (result.select.height !== this.getPlinthHeight()) {
                    this.setPlinthHeight(result.select as IPlinthData, this.getPlinthHeight());
                }
            }
        } else if (result) {
            console.error('!disableAccessory plinths', result);
        }
    }

    public disableAccessory(
        list: IDetailData[] | undefined,
        select: IDetailData | undefined,
        type: TGoodType,
    ): {list: IDetailData[], select: IDetailData} | undefined {
        if (!this.unitKits ||
            !list ||
            !select ||
            !this.unitKits[type]) {
            return undefined;
        }
        let index: string;
        let detailOffer: IImportOffer;
        let detailOffers: { [key: string]: IImportOffer } = {};
        let offersData: IDetailOfferData[] | undefined;
        let offerData: IDetailOfferData;
        let offerStore: number | undefined;
        let newSelect: IDetailData;
        let offers: IImportOffer[];
        let offerProductPrice: IProductPrice | undefined;

        for (detailOffer of this.unitKits[type]) {
            detailOffers[detailOffer[this.service.getOfferExternalId()]] = detailOffer;
        }
        for (index in list) {
            delete list[index].disable;
            offersData = list[index].offers;
            if (offersData) {
                for (offerData of offersData) {
                    if (!detailOffers[offerData.id]) {
                        offerData.active = false;
                    }
                    offerProductPrice = this.service.getOfferProductPrice(offerData.id);
                    if (offerProductPrice) {
                        if (offerProductPrice.active !== undefined &&
                            !offerProductPrice.active) {
                            offerData.active = false;
                            continue;
                        }
                        if (this.service.getOptions().checkStore) {
                            offerStore = offerProductPrice.stock;
                            if (offerStore === undefined || offerStore <= 0) {
                                offerData.active = false;
                            }
                        }
                    }
                }
                if (offersData.filter(value => (value.active === false)).length === offersData.length) {
                    if (select.id === list[index].id) {
                        select.disable = true;
                    }
                    list[index].disable = true;
                }
            } else if (list[index].type === KDETAIL_PRICE_TYPE_COLLECTIONS){
                offers = this.service.getDetailCollectionOffers(list[index], type, ["default"]);
                if (!offers || !offers.length) {
                    list[index].disable = true;
                }
            }
            if (list[index].id === select.id) {
                select.disable = list[index].disable;
            }
        }
        newSelect = select;
        if (select.disable) {
            for (index in list) {
                if (list[index].disable) {
                    continue;
                }
                newSelect = list[index];
                break;
            }
        }

        return {list: list, select: newSelect};
    }

    public isCreateUnitCorpusMaterialNone(createObject: ICreateObjectData): boolean {
        let index: string;
        let index2: string;
        for (index in createObject.options) {
            if (createObject.options[index].id === 'corpusMaterial' &&
                (createObject.options[index] as IOption).value === NONE_MATERIAL) {
                return true;
            }
            if (createObject.options[index].id === 'corpus') {
                for (index2 in (createObject.options[index] as IOptionGroup).options) {
                    if ((createObject.options[index] as IOptionGroup).options[index2].id === 'material' &&
                        (createObject.options[index] as IOptionGroup).options[index2].value === NONE_MATERIAL) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public calculateCreateObjectCorpusColors(
        createObject: ICreateObjectData,
        objectFacadeMaterials: IOptionFacadeMaterial | undefined,
        materialId?: string,
        width?: number
    ): IOptionCorpusMaterial | undefined {
        if (!this.isReady() || !this.corpusMaterials || !this.selectCorpusMaterial ||
            !this.bottomFacadeMaterial || !this.topFacadeMaterial) {
            return undefined;
        }
        if (this.isCreateUnitCorpusMaterialNone(createObject)) {
            return undefined;
        }
        let corpusMaterial: IMaterialData;
        let currentCorpusMaterial: IMaterialData;
        let materialItems: IMaterialData[] = [];
        let defaultValue: string | undefined;
        let currentFacadeMaterial: IFacadeMaterialData | undefined;
        let facadeMaterial: IFacadeMaterialData;
        let widthKey: string;

        widthKey = width ? '' + width : 'default';
        if (objectFacadeMaterials) {
            if (objectFacadeMaterials.value) {
                currentFacadeMaterial = this.getFacadeMaterialById(objectFacadeMaterials.value);
            }
            if (!currentFacadeMaterial && objectFacadeMaterials.defaultValue) {
                currentFacadeMaterial = this.getFacadeMaterialById(objectFacadeMaterials.value);
            }
        }
        if (currentFacadeMaterial) {
            facadeMaterial = currentFacadeMaterial;
        } else {
            facadeMaterial = createObject.level === LEVEL_TOP ? this.topFacadeMaterial : this.bottomFacadeMaterial;
        }
        currentCorpusMaterial = this.getCorpusMaterial(materialId, facadeMaterial);
        for (corpusMaterial of this.corpusMaterials) {
            if (corpusMaterial.isCustom) {
                continue;
            }
            if (corpusMaterial.enableFacades && !corpusMaterial.enableFacades.includes(facadeMaterial.facade)) {
                continue;
            }
            if (corpusMaterial.disableFacades && corpusMaterial.disableFacades.includes(facadeMaterial.facade)) {
                continue;
            }
            if (corpusMaterial.level && corpusMaterial.level !== createObject.level) {
                continue;
            }
            if (!createObject.availableCorpusMaterials || !createObject.availableCorpusMaterials[widthKey] ||
                createObject.availableCorpusMaterials[widthKey].indexOf(corpusMaterial.id) !== -1) {
                if (corpusMaterial.id === currentCorpusMaterial.id) {
                    defaultValue = corpusMaterial.id;
                }
                materialItems.push(corpusMaterial)
            }
        }
        if (materialItems.length > 0) {
            return {
                id: 'material',
                type: OPTION_TYPE_CORPUS_MATERIAL,
                title: i18n.t('Цвет корпуса'),
                sort: 100,
                items: materialItems,
                value: defaultValue || materialItems[0].id,
            }
        }

        return undefined;
    }

    // TODO сделать расчет и вывод котлетностей
    public calculateCreateObjectKitCodes(
        objectData: ICreateObjectData,
        materialId?: string,
        width?: number
    ): IOptionRadioButton | undefined {
        return undefined;
    }

    public hasCreateUnitFacades(createUnit: ICreateObjectData): boolean {
        let option: IOption | IOptionGroup;

        for (option of createUnit.options) {
            if (option.id === 'facades' && Object.values((option as IOptionHiddenJson).value as ISaveFacadeData[])
                .filter((item: ISaveFacadeData) => {
                    return item.facadeMaterialType === FACADE_MATERIAL_TYPE_DEFAULT || !item.facadeMaterialType;
                }).length > 0) {
                return true;
            }
        }

        return false;
    }

    public hasCreateUnitCorpusMaterialFacades(createUnit: ICreateObjectData): boolean {
        let option: IOption | IOptionGroup;

        for (option of createUnit.options) {
            if (option.id === 'facades' && Object.values((option as IOptionHiddenJson).value as ISaveFacadeData[])
                .filter((item: ISaveFacadeData) => {
                    return item.facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL;
                }).length > 0) {
                return true;
            }
        }

        return false;
    }

    public hasCreateUnitTabletopMaterials(createUnit: ICreateObjectData): boolean {
        let option: IOption | IOptionGroup;

        for (option of createUnit.options) {
            if (option.id === 'tabletops' && Object.values((option as IOptionHiddenJson).value as ISaveKUnitDetailData[])
                .filter((item: ISaveKUnitDetailData) => {
                    return item.includeModulePrice === true;
                }).length > 0) {
                return true;
            }
        }

        return false;
    }

    public calculateCreateObjectFacadeMaterials(
        createObject: ICreateObjectData,
        width?: number
    ): IOptionFacadeMaterial | undefined {
        if (!this.isReady() || !this.facadeMaterials || !this.bottomFacadeMaterial || !this.topFacadeMaterial) {
            return undefined;
        }
        if (!this.hasCreateUnitFacades(createObject)) {
            return undefined;
        }

        let currentFacade: IFacadeData | undefined;
        let facadeMaterials: IFacadeMaterialData[];
        let currentFacadeMaterial: IFacadeMaterialData;
        let facadeMaterial: IFacadeMaterialData;
        let facadeMaterialItems: IFacadeMaterialItem[] = [];
        let itemMaterialData: IMaterialData | undefined;
        let defaultValue: string | undefined;
        let availableFacadeMaterials: string[] | undefined;
        let widthKey: string;

        widthKey = width ? '' + width : 'default';
        currentFacadeMaterial = createObject.level === LEVEL_BOTTOM ? this.bottomFacadeMaterial : this.topFacadeMaterial;
        currentFacade = this.getFacadeData(currentFacadeMaterial.facade);
        if (!currentFacade) {
            return undefined;
        }
        facadeMaterials = this.getFacadeMaterials(currentFacade.id);
        for (facadeMaterial of facadeMaterials) {
            if (facadeMaterial.isCustom) {
                continue;
            }
            if (createObject.availableFacadeMaterials &&
                createObject.availableFacadeMaterials[widthKey]) {
                availableFacadeMaterials = createObject.availableFacadeMaterials[widthKey][facadeMaterial.facade];
            } else {
                availableFacadeMaterials = undefined;
            }
            if (!availableFacadeMaterials ||
                (availableFacadeMaterials && availableFacadeMaterials.indexOf(facadeMaterial.id) !== -1)) {

                itemMaterialData = this.getMaterialById(facadeMaterial.material);
                if (itemMaterialData) {
                    if (facadeMaterial.id === currentFacadeMaterial.id) {
                        defaultValue = facadeMaterial.id;
                    }
                    facadeMaterialItems.push({
                        facadeMaterial: facadeMaterial,
                        facade: currentFacade,
                        material: itemMaterialData
                    })
                }
            }
        }
        if (facadeMaterialItems.length > 0) {
            return {
                id: 'facadeMaterial',
                type: OPTION_TYPE_FACADE_MATERIAL,
                title: i18n.t('Цвет фасада'),
                sort: 100,
                items: facadeMaterialItems,
                value: defaultValue || facadeMaterialItems[0].facadeMaterial.id,
            }
        }

        return undefined;
    }

    public calculateCreateObjectCorpusFacadeMaterials(
        createObject: ICreateObjectData,
        width?: number
    ): IOptionFacadeMaterial | undefined {
        if (!this.isReady() || !this.facadeMaterials || !this.bottomFacadeMaterial || !this.topFacadeMaterial) {
            return undefined;
        }
        if (!this.hasCreateUnitCorpusMaterialFacades(createObject)) {
            return undefined;
        }

        let facadeMaterials: IFacadeMaterialData[];
        let currentCorpusMaterial: IMaterialData;
        let facadeMaterial: IFacadeMaterialData;
        let facadeMaterialItems: IFacadeMaterialItem[] = [];
        let itemMaterialData: IMaterialData | undefined;
        let itemFacadeData: IFacadeData | undefined;
        let defaultValue: string | undefined;
        let widthKey: string;

        widthKey = width ? '' + width : 'default';
        currentCorpusMaterial = this.getCorpusMaterial();
        facadeMaterials = this.getFacadeMaterials(ALL_FACADES);
        for (facadeMaterial of facadeMaterials) {
            if (facadeMaterial.isCustom || facadeMaterial.facadeMaterialType !== FACADE_MATERIAL_TYPE_CORPUS_MATERIAL) {
                continue;
            }
            itemMaterialData = this.getMaterialById(facadeMaterial.material);
            itemFacadeData = this.getFacadeData(facadeMaterial.facade);
            if (itemMaterialData && itemFacadeData) {
                if (!createObject.availableCorpusFacadeMaterials || !createObject.availableCorpusFacadeMaterials[widthKey] ||
                    createObject.availableCorpusFacadeMaterials[widthKey].indexOf(itemMaterialData.id) !== -1) {
                    if (facadeMaterial.material === currentCorpusMaterial.id) {
                        defaultValue = facadeMaterial.id;
                    }
                    facadeMaterialItems.push({
                        facadeMaterial: facadeMaterial,
                        material: itemMaterialData,
                        facade: itemFacadeData
                    });
                }
            }
        }
        if (facadeMaterialItems.length > 0) {
            return {
                id: 'corpusFacadeMaterial',
                type: OPTION_TYPE_FACADE_MATERIAL,
                title: i18n.t('Цвет фасада в цвет корпуса'),
                sort: 100,
                items: facadeMaterialItems,
                value: defaultValue || facadeMaterialItems[0].facadeMaterial.material,
            }
        }

        return undefined;
    }

    public calculateCreateObjectTabletopMaterials(
        createObject: ICreateObjectData,
        width?: number
    ): IOptionTabletopMaterial | undefined {
        if (!this.isReady() || !this.tabletops || !this.selectTabletop) {
            return undefined;
        }
        if (!this.hasCreateUnitTabletopMaterials(createObject)) {
            return undefined;
        }

        let tabletopMaterials: ITabletopData[] = [];
        let tabletopMaterial: ITabletopData;
        let currentTabletopMaterial: ITabletopData;
        let defaultTabletopMaterial: ITabletopData | undefined;
        let defaultValue: string | undefined;
        let widthKey: string;

        widthKey = width ? '' + width : 'default';
        currentTabletopMaterial = this.getTabletopMaterial();
        for (tabletopMaterial of this.tabletops) {
            if (tabletopMaterial.disable) {
                continue;
            }
            if (!createObject.availableTabletopMaterials || !createObject.availableTabletopMaterials[widthKey] ||
                createObject.availableTabletopMaterials[widthKey].includes(tabletopMaterial.id)) {
                if (tabletopMaterial.id === currentTabletopMaterial.id) {
                    defaultValue = tabletopMaterial.id
                }
                if (tabletopMaterial.isDefault && !defaultTabletopMaterial) {
                    defaultTabletopMaterial = tabletopMaterial;
                }
                tabletopMaterials.push(tabletopMaterial);
            }
        }
        if (!defaultValue && defaultTabletopMaterial) {
            defaultValue = defaultTabletopMaterial.id;
        }
        if (tabletopMaterials.length > 0) {
            return {
                id: 'tabletopMaterial',
                type: OPTION_TYPE_TABLETOP_MATERIAL,
                title: i18n.t('Столешница'),
                sort: 100,
                items: tabletopMaterials,
                value: defaultValue || tabletopMaterials[0].id,
            }
        }

        return undefined;
    }

    public getDefaultHandle(level: TLevel): IHandleData {
        if (!this.handles) {
            throw new Error('DataManager getDefaultHandle error');
        }
        let handle: IHandleData;
        let defaultHandle: IHandleData | undefined;
        let facadeMaterial: IFacadeMaterialData | undefined;
        switch (level) {
            case LEVEL_BOTTOM:
                facadeMaterial = this.bottomFacadeMaterial;
                break;
            case LEVEL_TOP:
                facadeMaterial = this.topFacadeMaterial;
                break;
        }
        if (!facadeMaterial) {
            throw new Error('DataManager getDefaultHandle error');
        }
        for (handle of this.handles) {
            if (!defaultHandle &&
                (handle.id === DEFAULT_HANDLE_ID || handle.isDefault)) {
                defaultHandle = handle;
            }
            if (handle.enableFacades && handle.enableFacades.includes(facadeMaterial.facade)) {
                return handle;
            }
            if (facadeMaterial.facade === handle.id) {
                return handle;
            }
        }
        if (defaultHandle) {
            return defaultHandle;
        }
        if (this.handles[0]) {
            return this.handles[0];
        }

        throw new Error('DataManager getDefaultHandle error');
    }

    public setTopHandle(handle: IHandleData): IHandleData {
        let newTopHandle: IHandleData;

        newTopHandle = handle;
        if (handle.enableFacades && this.topFacadeMaterial &&
            !handle.enableFacades.includes(this.topFacadeMaterial.facade)) {
            newTopHandle = this.getDefaultHandle(LEVEL_TOP);
        }
        if (this.topHandle && newTopHandle.id === this.topHandle.id) {
            return this.topHandle;
        }
        this.topHandle = newTopHandle;
        this.service.sendToRedux({
            type: CHANGE_TOP_HANDLE,
            payload: CommonHelper.deepCopy(this.topHandle)
        });

        return this.topHandle;
    }

    public setBottomHandle(handle: IHandleData): IHandleData {
        let newBottomHandle: IHandleData;

        newBottomHandle = handle;
        if (handle.enableFacades && this.bottomFacadeMaterial &&
            !handle.enableFacades.includes(this.bottomFacadeMaterial.facade)) {
            newBottomHandle = this.getDefaultHandle(LEVEL_BOTTOM);
        }
        if (this.bottomHandle && newBottomHandle.id === this.bottomHandle.id) {
            return this.bottomHandle;
        }
        this.bottomHandle = newBottomHandle;
        this.service.sendToRedux({
            type: CHANGE_BOTTOM_HANDLE,
            payload: CommonHelper.deepCopy(this.bottomHandle)
        });

        return this.bottomHandle;
    }

    public isCreateUnitDisableByFacadeThreeModel(createUnit: ICreateObjectData, facade: IFacadeData): boolean {
        let facadesData: ISaveFacadeData[];
        let index: string;
        let facadeData: ISaveFacadeData;
        let createOptions: any;
        let corpusSizes: TSizes;
        let sizes: TFacadeSizes;

        createOptions = this.service.getDefaultOptions(createUnit);
        corpusSizes = DataManager.calculateCorpusSizes(createOptions);
        if (createOptions.facades && createOptions.facades.length) {
            facadesData = createOptions.facades as ISaveFacadeData[];
            for (index in facadesData) {
                if (facadesData[index].modelType === FACADE_MODEL_TYPE_PLANE) {
                    continue;
                }
                facadeData = facadesData[index];
                sizes = KitchenHelper.calculateFacadeSizes(
                    {...facadeData.initSizes},
                    corpusSizes,
                    this.service,
                    {...facadeData.gap},
                    {...facadeData.calculateSizes}
                );
                if (this.isDisableUnitByThreeModelFacade(facadeData, facade, sizes)) {
                    return true;
                }
            }
        }

        return false;
    }

    public getCreateGroupUnits(types?: TCreateGroup[], level?: TLevel): ICreateGroup[] | undefined {
        if (!this.units || !this.units.length) {
            return undefined;
        }

        let units: ICreateGroup[] = CommonHelper.deepCopy(this.units);
        let group: ICreateGroup;
        let kitchenTypes: TCreateGroup[];

        let facadeMaterial: IFacadeMaterialData | undefined;

        try {
            facadeMaterial = this.getFacadeMaterial(level || LEVEL_BOTTOM);
        } catch (e) {
            facadeMaterial = undefined;
        }

        if (types) {
            units = units.filter(group => types.includes(group.id));
        }
        if (level) {
            for (group of units) {
                group.items = group.items.filter(item => level === item.level);
            }
        }
        if (facadeMaterial && this.service.getTechnologFurnitureType(facadeMaterial.facade) !== FURNITURE_TYPE_KITCHEN) {
            kitchenTypes = [GROUP_ACCESSORIES, GROUP_FACADES, GROUP_SINK_EQUIPMENTS, GROUP_BUILTIN_EQUIPMENTS];
            units = units.filter(group => !kitchenTypes.includes(group.id));
        }
        units = units.filter(group => group.items.length > 0);

        return units;
    }

    public calculateHandleData(
        saveHandleData?: ISaveHandleData,
        facadeHandleData?: IFacadeHandleData | IHandleData,
        facadeOpenType?: TFacadeOpenType,
        level?: TLevel,
        facadeSizes?: TFacadeSizes,
        facadeSideType?: TDirectionSideType
    ): ISaveHandleData | undefined {
        let newHandleData: ISaveHandleData;
        let horizontalMove: boolean;

        if (!saveHandleData || !facadeHandleData || !facadeSizes || facadeHandleData.id === HANDLE_ID_INTEGRATION) {
            return undefined;
        }

        horizontalMove = facadeHandleData.horizontalMove !== undefined ?
            facadeHandleData.horizontalMove : (saveHandleData.horizontalMove !== undefined ? saveHandleData.horizontalMove : true)

        newHandleData = {
            ...saveHandleData,
            id: saveHandleData.id,
            modelId: this.service.getHandleModelId(saveHandleData.modelId, facadeHandleData.id, level),
            align: this.calculateHandleAlign(
                saveHandleData,
                facadeHandleData,
                facadeOpenType,
                facadeSideType,
                horizontalMove
            ),
            margin: facadeHandleData.margin || saveHandleData.margin,
            position: this.service.getInitHandlePosition(facadeHandleData, saveHandleData, facadeSizes),
            location: this.calculateHandleLocation(saveHandleData, facadeHandleData, facadeOpenType),
            rotation: this.service.getInitHandleRotation(facadeHandleData, saveHandleData, facadeSizes),
            horizontalMove: horizontalMove
        };


        return newHandleData;
    }

    public calculateStretchCreateObjectData(objectData: ICreateObjectData): ICreateObjectData {
        if (!this.service.isStretchCreateObject(objectData)) {
            return objectData;
        }

        let option: IOption | IOptionGroup;
        let childOption: IOption;
        let rangeOption: IOptionRange;
        let extremumSizes: TExtremumSizes | undefined;
        let index: string;
        let range: TRange | undefined;
        let isStretch: boolean;

        isStretch = false;
        for (option of objectData.options) {
            if (option.id === 'corpus' && 'options' in option) {
                for (childOption of option.options) {
                    if (childOption.id === 'extremumSizes') {
                        extremumSizes = childOption.value as TExtremumSizes;
                        break;
                    }
                }
                if (extremumSizes) {
                    for (index in option.options) {
                        childOption = option.options[index];
                        if (childOption.id === 'width' && extremumSizes.width) {
                            range = this.getSizeItemRange(
                                childOption,
                                extremumSizes.width,
                                objectData.disableSelects?.corpus_width
                            );
                            if (range) {
                                isStretch = true;
                                rangeOption = {
                                    id: 'width',
                                    title: i18n.t('Ширина корпуса'),
                                    type: OPTION_TYPE_RANGE,
                                    sort: 0,
                                    min: range.min,
                                    max: range.max,
                                    defaultValue: this.getOptionNumberValue(childOption),
                                    step: 1,
                                    value: this.getOptionNumberValue(childOption),
                                };
                                option.options[index] = rangeOption;
                            }
                        }
                        if (childOption.id === 'height' && extremumSizes.height) {
                            range = this.getSizeItemRange(
                                childOption,
                                extremumSizes.height,
                                objectData.disableSelects?.corpus_height
                            );
                            if (range) {
                                isStretch = true;
                                rangeOption = {
                                    id: 'height',
                                    title: i18n.t('Высота корпуса'),
                                    type: OPTION_TYPE_RANGE,
                                    sort: 0,
                                    min: range.min,
                                    max: range.max,
                                    defaultValue: this.getOptionNumberValue(childOption),
                                    step: 1,
                                    value: this.getOptionNumberValue(childOption),
                                };
                                option.options[index] = rangeOption;
                            }
                        }
                        if (childOption.id === 'depth' && extremumSizes.depth) {
                            range = this.getSizeItemRange(
                                childOption,
                                extremumSizes.depth,
                                objectData.disableSelects?.corpus_depth
                            );
                            if (range) {
                                isStretch = true;
                                rangeOption = {
                                    id: 'depth',
                                    title: i18n.t('Глубина корпуса'),
                                    type: OPTION_TYPE_RANGE,
                                    sort: 0,
                                    min: range.min,
                                    max: range.max,
                                    defaultValue: this.getOptionNumberValue(childOption),
                                    step: 1,
                                    value: this.getOptionNumberValue(childOption),
                                };
                                option.options[index] = rangeOption;
                            }
                        }
                    }
                }
            }
        }
        objectData.isStretch = isStretch;

        return objectData;
    }

    ///////////////////////////////////////////////

    protected getOptionNumberValue(option: IOption): number | undefined {
        return !isNaN(+option.value) ? +option.value : (!isNaN(+option.defaultValue) ? +option.defaultValue : undefined)
    }

    protected getSizeItemRange(
        option: IOption,
        sizesRange: TOptionalRange,
        disableSelects?: string[]
    ): TRange | undefined {
        let range: TRange | undefined;
        let selectItems: TSelectItem[];
        let selectItem: TSelectItem;
        let selectRange: TRange;
        let itemValue: number | undefined;
        let rangeOption: IOptionRange;
        let selectOption: IOptionSelect | IOptionRadioButton;

        switch (option.type) {
            case OPTION_TYPE_RANGE:
                rangeOption = option as IOptionRange;
                range = {
                    min: rangeOption.min || 0,
                    max: rangeOption.max || 0,
                }
                break;
            case OPTION_TYPE_RADIOBUTTON:
            case OPTION_TYPE_SELECT:
                selectRange = {min: sizesRange.min || Infinity, max: sizesRange.max || -Infinity};
                if ('items' in option) {
                    selectOption = option as IOptionSelect;
                    selectItems = selectOption.items as TSelectItem[];
                    for (selectItem of selectItems) {
                        if (disableSelects && disableSelects.includes(selectItem.id)) {
                            continue;
                        }
                        itemValue = !isNaN(+selectItem.id) ? +selectItem.id : undefined;
                        if (itemValue && selectRange.min > itemValue) {
                            selectRange.min = itemValue;
                        }
                        if (itemValue && selectRange.max < itemValue) {
                            selectRange.max = itemValue;
                        }
                    }
                }
                range = {
                    min: isFinite(selectRange.min) ? selectRange.min : 0,
                    max: isFinite(selectRange.max) ? selectRange.max : 0
                }
                break;
            case OPTION_TYPE_HIDDEN_NUMBER:
                range = {min: sizesRange.min || 0, max: sizesRange.max || 0};
                itemValue = !isNaN(+option.value) ? +option.value : undefined;
                if (itemValue) {
                    if (range.min > itemValue) {
                        range.min = itemValue;
                    }
                    if (range.max < itemValue) {
                        range.max = itemValue;
                    }
                }
                break;
        }
        if (range && (range.min === range.max || range.min > range.max)) {
            return undefined;
        }

        return range;
    }

    protected setSelectAccessoryOffers(offers: IImportOffer[], limit: number = 100) {
        this.setSelectAccessoryItemOffers(offers, limit, this.unitKits[GOOD_TYPE_APRON], this.selectApron);
        this.setSelectAccessoryItemOffers(offers, limit, this.unitKits[GOOD_TYPE_TABLETOP], this.selectTabletop);
        this.setSelectAccessoryItemOffers(offers, limit, this.unitKits[GOOD_TYPE_PLINTH], this.selectPlinth);
        this.setSelectAccessoryItemOffers(offers, limit, this.unitKits[GOOD_TYPE_CORNER], this.selectCorner);
    }

    protected setSelectAccessoryItemOffers(
        offers: IImportOffer[],
        limit: number = 100,
        unitKitsOffers: IImportOffer[] | undefined,
        selectDetail: IDetailData | undefined
    ) {
        let key: string;
        let index: number;
        let offer: IImportOffer;
        let selectApronOffers: {[key: string]: string} = {};
        if (selectDetail && selectDetail.offers) {
            for (key in selectDetail.offers) {
                selectApronOffers[selectDetail.offers[key].id] = selectDetail.offers[key].id;
            }
        }

        if (unitKitsOffers) {
            index = 0;
            for (offer of unitKitsOffers) {
                if (index < limit) {
                    offers.push(offer);
                }
                if (selectDetail && offer.corpusMaterial === selectDetail.id) {
                    offers.push(offer);
                }
                if (selectApronOffers[offer.id]) {
                    offers.push(offer);
                }
                index++;
            }
        }
    }

    protected getCreateObjectOfferIdsByModule(createObject: ICreateObjectData): string[] {
        return this.getCatalogCodeOfferIds(createObject.catalogCode, {});
    }

    protected getCreateObjectOfferIdsByDetails(createObject: ICreateObjectData): string[] {
        let createOptions: any;
        let index: string;
        let facadesOfferIds: { [key: string]: boolean } = {};
        let filterId: string;
        let offerIds: { [key: string]: string } = {};
        let offerId: string;
        let itemOfferIds: string[] = [];
        let facadeMaterial: IFacadeMaterialData;

        facadeMaterial = this.getFacadeMaterial(createObject.level);
        createOptions = this.service.getDefaultOptions(createObject);
        if (createOptions.corpus) {
            if (createOptions.corpus.catalogCode) {
                itemOfferIds = this.getCatalogCodeOfferIds(createOptions.corpus.catalogCode, {});
                for (offerId of itemOfferIds) {
                    offerIds[offerId] = offerId;
                }
            }
            if (createOptions.corpus.furniture) {
                for (index in createOptions.corpus.furniture) {
                    itemOfferIds = this.getCatalogCodeOfferIds(
                        'furniture',
                        {
                            functionalType: createOptions.corpus.furniture[index].functionalType
                        });
                    for (offerId of itemOfferIds) {
                        offerIds[offerId] = offerId;
                    }
                }
            }
        }
        if (createOptions.facades) {
            for (index in createOptions.facades) {
                filterId = CommonHelper.md5({
                    facade: facadeMaterial.facade,
                    functionalType: createOptions.facades[index].functionalType || FACADE_FUNCTIONAL_TYPE_DEFAULT
                });
                if (!facadesOfferIds[filterId]) {
                    facadesOfferIds[filterId] = true;
                    itemOfferIds = this.getCatalogCodeOfferIds(
                        'facade',
                        {
                            facade: facadeMaterial.facade,
                            functionalType: createOptions.facades[index].functionalType || FACADE_FUNCTIONAL_TYPE_DEFAULT
                        });
                    for (offerId of itemOfferIds) {
                        offerIds[offerId] = offerId;
                    }
                }
            }
        }
        if (createOptions.tabletopMaterial && createOptions.tabletops) {
            for (index in createOptions.tabletops) {
                if (createOptions.tabletops[index].includeModulePrice) {
                    itemOfferIds = this.getCatalogCodeOfferIds(
                        'tabletop',
                        {
                            functionalType: createOptions.tabletops[index].functionalType
                        });
                    for (offerId of itemOfferIds) {
                        offerIds[offerId] = offerId;
                    }
                }
            }
        }

        return Object.values(offerIds);
    }

    protected getOffersByCreateObjectData(createUnit: ICreateObjectData): IImportOffer[] {
        let offers: IImportOffer[] = [];
        let facade: IFacadeData | undefined;
        let offer: IImportOffer;
        let catalogCodes: string[];
        let catalogCode: string;

        if (!this.unitKits || createUnit.notPrice || !createUnit.catalogCode ||
            !this.bottomFacadeMaterial || !this.topFacadeMaterial) {
            return offers;
        }
        catalogCodes = this.service.getCreateObjectCatalogCodes(createUnit);
        facade = this.getFacadeData(createUnit.level === LEVEL_BOTTOM ? this.bottomFacadeMaterial.facade : this.topFacadeMaterial.facade)
        for (catalogCode of catalogCodes) {
            if (this.unitKits[catalogCode]) {
                for (offer of this.unitKits[catalogCode]) {
                    if (offer.facadeMaterial === NONE_MATERIAL || !facade ||
                        (facade && offer.facadeMaterial.includes(facade.id))) {
                        offers.push(offer);
                    }
                }
            }
        }

        return offers;
    }

    protected checkSelectCorpusMaterial() {
        let corpusMaterial: IMaterialData;

        if (!this.topFacadeMaterial || !this.bottomFacadeMaterial || !this.selectCorpusMaterial || !this.corpusMaterials) {
            return;
        }

        if (this.selectCorpusMaterial.disableFacades &&
            (this.selectCorpusMaterial.disableFacades.includes(this.topFacadeMaterial.facade) ||
                this.selectCorpusMaterial.disableFacades.includes(this.bottomFacadeMaterial.facade))) {
            for (corpusMaterial of this.corpusMaterials) {
                if (!corpusMaterial.enableFacades && !corpusMaterial.disableFacades) {
                    this.setCorpusMaterial(corpusMaterial);
                    break;
                }
                if (corpusMaterial.enableFacades &&
                    corpusMaterial.enableFacades.includes(this.topFacadeMaterial.facade) &&
                    corpusMaterial.enableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setCorpusMaterial(corpusMaterial);
                    break;
                }
                if (corpusMaterial.disableFacades &&
                    !corpusMaterial.disableFacades.includes(this.topFacadeMaterial.facade) &&
                    !corpusMaterial.disableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setCorpusMaterial(corpusMaterial);
                    break;
                }
            }
        }
        if (this.selectCorpusMaterial.enableFacades &&
            (!this.selectCorpusMaterial.enableFacades.includes(this.topFacadeMaterial.facade) ||
                !this.selectCorpusMaterial.enableFacades.includes(this.bottomFacadeMaterial.facade))) {
            for (corpusMaterial of this.corpusMaterials) {
                if (!corpusMaterial.enableFacades && !corpusMaterial.disableFacades) {
                    this.setCorpusMaterial(corpusMaterial);
                    break;
                }
                if (corpusMaterial.enableFacades &&
                    corpusMaterial.enableFacades.includes(this.topFacadeMaterial.facade) &&
                    corpusMaterial.enableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setCorpusMaterial(corpusMaterial);
                    break;
                }
                if (corpusMaterial.disableFacades &&
                    !corpusMaterial.disableFacades.includes(this.topFacadeMaterial.facade) &&
                    !corpusMaterial.disableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setCorpusMaterial(corpusMaterial);
                    break;
                }
            }
        }
    }

    protected checkSelectPlinth() {
        let plinth: IPlinthData;

        if (!this.topFacadeMaterial || !this.bottomFacadeMaterial || !this.selectPlinth || !this.plinths) {
            return;
        }

        if (this.selectPlinth.disableFacades &&
            (this.selectPlinth.disableFacades.includes(this.topFacadeMaterial.facade) ||
                this.selectPlinth.disableFacades.includes(this.bottomFacadeMaterial.facade))) {
            for (plinth of this.plinths) {
                if (!plinth.enableFacades && !plinth.disableFacades) {
                    this.setPlinth(plinth);
                    break;
                }
                if (plinth.enableFacades &&
                    plinth.enableFacades.includes(this.topFacadeMaterial.facade) &&
                    plinth.enableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setPlinth(plinth);
                    break;
                }
                if (plinth.disableFacades &&
                    !plinth.disableFacades.includes(this.topFacadeMaterial.facade) &&
                    !plinth.disableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setPlinth(plinth);
                    break;
                }
            }
        }
        if (this.selectPlinth.enableFacades &&
            (!this.selectPlinth.enableFacades.includes(this.topFacadeMaterial.facade) ||
                !this.selectPlinth.enableFacades.includes(this.bottomFacadeMaterial.facade))) {
            for (plinth of this.plinths) {
                if (!plinth.enableFacades && !plinth.disableFacades) {
                    this.setPlinth(plinth);
                    break;
                }
                if (plinth.enableFacades &&
                    plinth.enableFacades.includes(this.topFacadeMaterial.facade) &&
                    plinth.enableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setPlinth(plinth);
                    break;
                }
                if (plinth.disableFacades &&
                    !plinth.disableFacades.includes(this.topFacadeMaterial.facade) &&
                    !plinth.disableFacades.includes(this.bottomFacadeMaterial.facade)) {
                    this.setPlinth(plinth);
                    break;
                }
            }
        }

        if (this.selectCorpusMaterial && this.selectCorpusMaterial.plinthMaterial !== this.selectPlinth.id) {
            for (plinth of this.plinths) {
                if (!plinth.enableFacades && !plinth.disableFacades && this.selectCorpusMaterial.plinthMaterial === plinth.id) {
                    this.setPlinth(plinth);
                    break;
                }
                if (plinth.enableFacades &&
                    plinth.enableFacades.includes(this.topFacadeMaterial.facade) &&
                    plinth.enableFacades.includes(this.bottomFacadeMaterial.facade) && this.selectCorpusMaterial.plinthMaterial === plinth.id) {
                    this.setPlinth(plinth);
                    break;
                }
                if (plinth.disableFacades &&
                    !plinth.disableFacades.includes(this.topFacadeMaterial.facade) &&
                    !plinth.disableFacades.includes(this.bottomFacadeMaterial.facade) && this.selectCorpusMaterial.plinthMaterial === plinth.id) {
                    this.setPlinth(plinth);
                    break;
                }
            }
        }
    }

    protected calculateHandleLocation(
        saveHandleData?: ISaveHandleData,
        facadeHandleData?: IFacadeHandleData | IHandleData,
        facadeOpenType?: TFacadeOpenType
    ): THandleType | undefined {
        let facadeHandleLocation: THandleType | undefined;

        if (saveHandleData?.location) {
            facadeHandleLocation = saveHandleData.location;
        }
        if (facadeHandleData?.location) {
            facadeHandleLocation = facadeHandleData.location;
        }
        if (facadeHandleData?.locations) {
            if (facadeHandleData.locations.default) {
                facadeHandleLocation = facadeHandleData.locations.default;
            }
            if (facadeOpenType && facadeHandleData.locations[facadeOpenType]) {
                facadeHandleLocation = facadeHandleData.locations[facadeOpenType];
            }
        }
        return facadeHandleLocation;
    }

    protected calculateHandleAlign(
        saveHandleData?: ISaveHandleData,
        facadeHandleData?: IFacadeHandleData | IHandleData,
        facadeOpenType?: TFacadeOpenType,
        facadeSideType?: TDirectionSideType,
        horizontalMove?: boolean
    ): IAlign | undefined {
        let align: IAlign | undefined;
        let facadeAlign: IAlign | undefined;
        let resultAlign: IAlign;

        if (saveHandleData?.align) {
            align = saveHandleData.align;
        }
        if (facadeHandleData?.align) {
            facadeAlign = facadeHandleData.align;
        }
        if (facadeHandleData?.aligns) {
            if (facadeHandleData.aligns.default) {
                facadeAlign = facadeHandleData.aligns.default;
            }
            if (facadeOpenType && facadeHandleData.aligns[facadeOpenType]) {
                facadeAlign = facadeHandleData.aligns[facadeOpenType];
            }
        }

        resultAlign = {
            x: facadeAlign?.x || align?.x,
            y: facadeAlign?.y || align?.y
        };
        if (horizontalMove !== false && facadeOpenType === FACADE_OPEN_TYPE_VERTICAL_PIVOT) {
            switch (facadeSideType) {
                case ALIGN_LEFT:
                    resultAlign.x = ALIGN_RIGHT;
                    break;
                case ALIGN_RIGHT:
                    resultAlign.x = ALIGN_LEFT;
                    break;
            }
        }

        return resultAlign;
    }

    ///////////////////////////////////////////////

    private comparePlankFunctionalType(
        offerFunctionalType: string | undefined,
        plankFunctionalType: TApronPlankType | TTabletopPlankType
    ): boolean {
        let compareFunctionalType: TApronPlankType | TTabletopPlankType | undefined;

        switch (offerFunctionalType) {
            case "Щелевая  для стеновых панелей":
                compareFunctionalType = APRON_PLANK_TYPE_CONNECT;
                break;
            case "Торцевая для стеновых панелей":
                compareFunctionalType = APRON_PLANK_TYPE_END;
                break;
            case "Угловая соединительная для стеновых панелей":
                compareFunctionalType = APRON_PLANK_TYPE_ANGLE;
                break;
            case "Щелевая  для столешниц":
                compareFunctionalType = TABLETOP_PLANK_TYPE_CONNECT;
                break;
            case "Торцевая для столешниц":
                compareFunctionalType = TABLETOP_PLANK_TYPE_END;
                break;
            case "Угловая соединительная для столешниц":
                compareFunctionalType = TABLETOP_PLANK_TYPE_ANGLE;
                break;
            default:
                console.log('offerFunctionalType', offerFunctionalType);
                compareFunctionalType = undefined;
                break;
        }

        return compareFunctionalType === plankFunctionalType;
    }

    private comparePlankSizes(importOffer: IImportOffer, plank: IAccessoryPlank): boolean {
        switch (plank.type) {
            case 'apron':
                if ('height' in plank && 'depth' in plank) {
                    return importOffer.height === plank.height;
                }
                break;
            case 'tabletop':
                if ('height' in plank && 'depth' in plank) {
                    return importOffer.height === plank.height;
                }
                break;
        }

        return false;
    }

    private initCollectionId(): string {
        if (this.service.projectData.collection !== undefined) {
            return this.service.projectData.collection;
        }
        if (this.service.urlOptions.collection) {
            return this.service.urlOptions.collection;
        }

        throw new Error('error-DataManager-initCollectionId');
    }

    private async setUnits(units: ICreateGroup[]) {
        if (isDevelop() && this.service.urlOptions.notPrice) {
            let group: ICreateGroup;
            let item: ICreateObjectData;

            for (group of units) {
                for (item of group.items) {
                    item.notPrice = true;
                }
            }
        }
        this.units = units;
        this.service.setUnitsToKitchenHelper();
    }

    private setConstructiveList(list: ICreateGroup[]) {
        this.constructiveList = list;
        this.service.sendToRedux({
            type: CHANGE_CONSTRUCTIVE_LIST,
            payload: CommonHelper.deepCopy(this.constructiveList)
        });
    }

    private setTechnologMaps(technologMaps: ITechnologMaps) {
        this.technologMaps = technologMaps;
        this.service.sendToRedux({
            type: CHANGE_TECHNOLOG_MAP,
            payload: CommonHelper.deepCopy(this.technologMaps)
        });
    }

    private setTabletops(tabletops: ITabletopData[], selectTabletop?: ITabletopData) {
        let tabletopData: ITabletopData;
        let defaultTabletopData: { id: string; height: number } | undefined;
        let defaultTabletop: ITabletopData | undefined;
        let facade: IFacadeData | undefined;

        if (this.bottomFacadeMaterial) {
            facade = this.getFacadeData(this.bottomFacadeMaterial.facade);
            if (facade && facade.tabletopMaterial) {
                defaultTabletopData = facade.tabletopMaterial;
            }
            if (this.bottomFacadeMaterial.tabletopMaterial) {
                defaultTabletopData = this.bottomFacadeMaterial.tabletopMaterial;
            }
        }
        this.tabletops = tabletops;
        for (tabletopData of this.tabletops) {
            if (defaultTabletopData && defaultTabletopData.id === tabletopData.id &&
                (!tabletopData.heights || (tabletopData.heights && tabletopData.heights.includes(defaultTabletopData.height)))) {
                selectTabletop = tabletopData;
                selectTabletop.height = defaultTabletopData.height;
                break;
            }
            if (tabletopData.isDefault && !defaultTabletop) {
                defaultTabletop = tabletopData;
            }
        }
        if (!selectTabletop && defaultTabletop) {
            selectTabletop = defaultTabletop;
        }
        if (!selectTabletop) {
            selectTabletop = this.tabletops[0];
        }
        this.service.sendToRedux({
            type: CHANGE_TABLETOPS,
            payload: CommonHelper.deepCopy(this.tabletops)
        });
        this.setTabletop(selectTabletop);
    }

    private setAprons(aprons: IApronData[], selectApron?: IApronData) {
        let apronData: IApronData;
        let defaultSelectApron: IApronData | undefined;

        this.aprons = aprons;
        for (apronData of this.aprons) {
            if (!this.selectApron) {
                defaultSelectApron = apronData;
            }
            if (!selectApron && apronData.isDefault) {
                defaultSelectApron = apronData;
                break;
            }
        }
        if (!selectApron && defaultSelectApron) {
            selectApron = defaultSelectApron;
        }
        if (selectApron) {
            this.setApron(selectApron)
        }
        this.service.sendToRedux({
            type: CHANGE_APRONS,
            payload: CommonHelper.deepCopy(this.aprons)
        });
    }

    private setCorners(corners: ICornerData[], selectCorner?: ICornerData) {
        let cornerData: ICornerData;
        let defaultSelectCorner: ICornerData | undefined;

        this.corners = corners;
        for (cornerData of this.corners) {
            if (!this.selectCorner) {
                defaultSelectCorner = cornerData;
            }
            if (cornerData.isDefault) {
                defaultSelectCorner = cornerData;
                break;
            }
        }
        if (!selectCorner && defaultSelectCorner) {
            selectCorner = defaultSelectCorner;
        }
        if (selectCorner) {
            this.setCorner(selectCorner);
        }
        this.service.sendToRedux({
            type: CHANGE_CORNERS,
            payload: CommonHelper.deepCopy(this.corners)
        });
    }

    private setIntegratedHandles(integratedHandles: IIntegratedHandleData[], selectIntegratedHandle?: IIntegratedHandleData) {
        let integratedHandle: IIntegratedHandleData;
        let defaultSelectIntegratedHandle: IIntegratedHandleData | undefined;

        this.integratedHandles = integratedHandles;
        for (integratedHandle of this.integratedHandles) {
            if (!this.selectIntegratedHandle) {
                defaultSelectIntegratedHandle = integratedHandle;
            }
            if (integratedHandle.isDefault) {
                defaultSelectIntegratedHandle = integratedHandle;
                break;
            }
        }
        if (!selectIntegratedHandle && defaultSelectIntegratedHandle) {
            selectIntegratedHandle = defaultSelectIntegratedHandle;
        }
        if (selectIntegratedHandle) {
            this.setIntegratedHandle(selectIntegratedHandle);
        }
        this.service.sendToRedux({
            type: CHANGE_INTEGRATED_HANDLES,
            payload: CommonHelper.deepCopy(this.integratedHandles)
        });
    }

    private setPlinths(plinths: IPlinthData[], selectPlinth?: IPlinthData) {
        let plinthData: IPlinthData;
        let facade: IFacadeData | undefined;
        let defaultPlinthId: string | undefined;
        let defaultPlinth: IPlinthData | undefined;

        this.plinths = plinths;
        if (this.selectCorpusMaterial && this.selectCorpusMaterial.plinthMaterial) {
            defaultPlinthId = this.selectCorpusMaterial.plinthMaterial;
        }
        if (this.bottomFacadeMaterial) {
            facade = this.getFacadeData(this.bottomFacadeMaterial.facade);
            if (facade && facade.plinthMaterial) {
                defaultPlinthId = facade.plinthMaterial;
            }
            if (this.bottomFacadeMaterial.plinthMaterial) {
                defaultPlinthId = this.bottomFacadeMaterial.plinthMaterial;
            }
        }
        for (plinthData of this.plinths) {
            if (defaultPlinthId && plinthData.id === defaultPlinthId) {
                defaultPlinth = plinthData;
                break;
            }
            if (plinthData.isDefault && !defaultPlinth) {
                defaultPlinth = plinthData;
            }
        }
        if (!selectPlinth && defaultPlinth) {
            selectPlinth = defaultPlinth;
        }
        if (!selectPlinth) {
            selectPlinth = this.plinths[0];
        }
        this.service.sendToRedux({
            type: CHANGE_PLINTHS,
            payload: CommonHelper.deepCopy(this.plinths)
        });
        this.setPlinth(selectPlinth);
    }

    private setRoomMaterials(roomMaterials: IRoomMaterials) {
        let itemMaterial: IMaterialData;
        let selectFloor: IMaterialData | undefined;
        let selectWall: IMaterialData | undefined;

        this.floors = roomMaterials.floor;
        for (itemMaterial of roomMaterials.floor) {
            if (!this.selectFloor) {
                selectFloor = itemMaterial;
            }
            if (itemMaterial.isDefault) {
                selectFloor = itemMaterial;
                break;
            }
        }
        if (selectFloor) {
            this.setFloor(selectFloor);
        }
        this.service.sendToRedux({
            type: CHANGE_FLOOR,
            payload: CommonHelper.deepCopy(this.floors)
        });

        this.walls = roomMaterials.wall;
        for (itemMaterial of roomMaterials.wall) {
            if (!this.selectWall) {
                selectWall = itemMaterial;
            }
            if (itemMaterial.isDefault) {
                selectWall = itemMaterial;
                break;
            }
        }
        if (selectWall) {
            this.setWall(selectWall);
        }
        this.service.sendToRedux({
            type: CHANGE_WALL,
            payload: CommonHelper.deepCopy(this.walls)
        });
    }

    private setFacades(facades: IFacadeData[]) {
        this.facades = facades;

        this.service.sendToRedux({
            type: CHANGE_FACADES,
            payload: CommonHelper.deepCopy(this.facades)
        });
    }

    private setHandleModels(handleModels: IHandleModelData[]) {
        this.handleModels = handleModels;
    }

    private setHandles(handles: IHandleData[]) {
        let handle: IHandleData;

        this.handles = handles;
        for (handle of this.handles) {
            if (!this.topHandle && this.topFacadeMaterial && this.topFacadeMaterial.facade === handle.id) {
                this.topHandle = handle;
            }
            if (!this.bottomHandle && this.bottomFacadeMaterial && this.bottomFacadeMaterial.facade === handle.id) {
                this.bottomHandle = handle;
            }
        }
        this.service.sendToRedux({
            type: CHANGE_HANDLES,
            payload: CommonHelper.deepCopy(this.handles)
        });
        this.service.sendToRedux({
            type: CHANGE_TOP_HANDLE,
            payload: CommonHelper.deepCopy(this.topHandle)
        });
        this.service.sendToRedux({
            type: CHANGE_BOTTOM_HANDLE,
            payload: CommonHelper.deepCopy(this.bottomHandle)
        });
    }

    private setMaterials(materials: IMaterialData[]) {
        this.materials = materials;
        this.service.sendToRedux({
            type: CHANGE_MATERIALS,
            payload: CommonHelper.deepCopy(this.materials)
        });
    }

    private setPanelMaterials(panelMaterials: IMaterialData[]) {
        this.panelMaterials = panelMaterials;
    }

    private setServices(services: IServiceData[]) {
        this.services = services;
        this.service.sendToRedux({
            type: CHANGE_SERVICES,
            payload: CommonHelper.deepCopy(this.services)
        });
    }

    private setKitCodes(kitCodes: TSelectItem[]) {
        this.kitCodes = kitCodes;
        this.service.sendToRedux({
            type: CHANGE_KIT_CODES,
            payload: CommonHelper.deepCopy(this.kitCodes)
        });
        let defaultKitCode: TSelectItem | undefined;
        let firstKitCode: TSelectItem | undefined;
        let kitCode: TSelectItem;

        for (kitCode of this.kitCodes) {
            if (!firstKitCode && !kitCode.disabled) {
                firstKitCode = kitCode;
            }
            if (kitCode.isDefault && !defaultKitCode && !kitCode.disabled) {
                defaultKitCode = kitCode;
            }
        }
        if (!defaultKitCode && firstKitCode) {
            defaultKitCode = firstKitCode;
        }
        if (defaultKitCode) {
            this.setSelectKitCode(defaultKitCode);
        }
    }

    private setFacadeMaterials(facadeMaterials: IFacadeMaterialData[]) {
        let facadeMaterial: IFacadeMaterialData;
        let bottomUrlFacadeMaterial: IFacadeMaterialData | undefined;
        let topUrlFacadeMaterial: IFacadeMaterialData | undefined;
        const projectData: IProjectData = this.service.projectData;

        this.facadeMaterials = facadeMaterials;

        for (facadeMaterial of this.facadeMaterials) {
            if (this.service.urlOptions.bottomFacade &&
                facadeMaterial.id === this.service.urlOptions.bottomFacade &&
                (!facadeMaterial.level || facadeMaterial.level === LEVEL_BOTTOM)) {
                bottomUrlFacadeMaterial = facadeMaterial;
            }
            if (this.service.urlOptions.topFacade &&
                facadeMaterial.id === this.service.urlOptions.topFacade &&
                (!facadeMaterial.level || facadeMaterial.level === LEVEL_TOP)) {
                topUrlFacadeMaterial = facadeMaterial;
            }
            if (!this.bottomFacadeMaterial && (!facadeMaterial.level || facadeMaterial.level === LEVEL_BOTTOM)) {
                this.bottomFacadeMaterial = facadeMaterial;
            }
            if (!this.topFacadeMaterial && (!facadeMaterial.level || facadeMaterial.level === LEVEL_TOP)) {
                this.topFacadeMaterial = facadeMaterial;
            }
            if (facadeMaterial.isDefault &&
                (!this.bottomFacadeMaterial || !this.bottomFacadeMaterial.isBottomDefault) &&
                (!facadeMaterial.level || facadeMaterial.level === LEVEL_BOTTOM)) {
                this.bottomFacadeMaterial = facadeMaterial;
            }
            if (facadeMaterial.isDefault &&
                (!this.topFacadeMaterial || !this.topFacadeMaterial.isBottomDefault) &&
                (!facadeMaterial.level || facadeMaterial.level === LEVEL_TOP)) {
                this.topFacadeMaterial = facadeMaterial;
            }
            if (facadeMaterial.isBottomDefault &&
                (!facadeMaterial.level || facadeMaterial.level === LEVEL_BOTTOM)) {
                this.bottomFacadeMaterial = facadeMaterial;
            }
            if (facadeMaterial.isTopDefault &&
                (!facadeMaterial.level || facadeMaterial.level === LEVEL_TOP)) {
                this.topFacadeMaterial = facadeMaterial;
            }
        }
        if (bottomUrlFacadeMaterial) {
            this.bottomFacadeMaterial = bottomUrlFacadeMaterial;
        }
        if (topUrlFacadeMaterial) {
            this.topFacadeMaterial = topUrlFacadeMaterial;
        }
        if (projectData.topFacade) {
            for (facadeMaterial of this.facadeMaterials) {
                if (facadeMaterial.id === projectData.topFacade) {
                    if ((!facadeMaterial.level || facadeMaterial.level === LEVEL_TOP)) {
                        this.topFacadeMaterial = facadeMaterial;
                    }
                    break;
                }
            }
        }
        if (projectData.bottomFacade) {
            for (facadeMaterial of this.facadeMaterials) {
                if (facadeMaterial.id === projectData.bottomFacade) {
                    if ((!facadeMaterial.level || facadeMaterial.level === LEVEL_BOTTOM)) {
                        this.bottomFacadeMaterial = facadeMaterial;
                    }
                    break;
                }
            }
        }
        this.service.sendToRedux({
            type: CHANGE_FACADE_MATERIALS,
            payload: CommonHelper.deepCopy(this.facadeMaterials)
        });
        this.service.sendToRedux({
            type: CHANGE_TOP_FACADE_MATERIAL,
            payload: CommonHelper.deepCopy(this.topFacadeMaterial)
        });
        this.service.sendToRedux({
            type: CHANGE_BOTTOM_FACADE_MATERIAL,
            payload: CommonHelper.deepCopy(this.bottomFacadeMaterial)
        });
        this.service.setFacadeMaterialsToKitchenHelper();
    }

    private setEquipmentModels(equipments: IEquipmentModelData[]) {
        this.equipmentModels = equipments;
    }

    private setConstructiveModels(constructive: IConstructiveModelData[]) {
        this.constructiveModels = constructive;
    }

    private setCorpusMaterials(materials: IMaterialData[]) {
        let materialData: IMaterialData;
        let selectCorpusMaterial: IMaterialData | undefined;
        let defaultCorpusMaterial: IMaterialData | undefined;
        const projectData: IProjectData = this.service.projectData;

        this.corpusMaterials = materials;
        for (materialData of this.corpusMaterials) {
            if (!defaultCorpusMaterial) {
                defaultCorpusMaterial = materialData;
            }
            if (materialData.isDefault) {
                defaultCorpusMaterial = materialData;
            }
            if (this.service.urlOptions.corpus &&
                materialData.id === this.service.urlOptions.corpus) {
                selectCorpusMaterial = materialData;
                break;
            }
        }
        if (!this.selectCorpusMaterial && defaultCorpusMaterial) {
            selectCorpusMaterial = defaultCorpusMaterial;
        }
        if (projectData.corpusMaterial) {
            for (materialData of this.corpusMaterials) {
                if (materialData.id === projectData.corpusMaterial) {
                    selectCorpusMaterial = materialData;
                    break;
                }
            }
        }
        if (selectCorpusMaterial) {
            this.setCorpusMaterial(selectCorpusMaterial);
        }
        this.service.sendToRedux({
            type: CHANGE_CORPUS_MATERIALS,
            payload: CommonHelper.deepCopy(this.corpusMaterials)
        });
        this.service.setCorpusMaterialsToKitchenHelper();
    }

    private setGlasses(glasses: IMaterialData[]) {
        let materialData: IMaterialData;
        let selectGlass: IMaterialData | undefined;
        let defaultGlass: IMaterialData | undefined;
        const projectData: IProjectData = this.service.projectData;

        this.glasses = glasses;
        for (materialData of this.glasses) {
            if (!defaultGlass) {
                defaultGlass = materialData;
            }
            if (materialData.isDefault) {
                defaultGlass = materialData;
            }
        }
        if (!this.selectGlass && defaultGlass) {
            selectGlass = defaultGlass;
        }
        if (projectData.glass) {
            for (materialData of this.glasses) {
                if (materialData.id === projectData.glass) {
                    selectGlass = materialData;
                    break;
                }
            }
        }
        if (selectGlass) {
            this.setGlass(selectGlass);
        }
        this.service.sendToRedux({
            type: CHANGE_GLASSES,
            payload: CommonHelper.deepCopy(this.glasses)
        });
        this.service.setGlassesToKitchenHelper();
    }

    private async getEntityData(url: string, params?: {}) {
        return axios.get(url, {params: params}).then(
            response => {
                return response.data;
            }
        ).catch(error => {
            return Promise.reject(error);
        });
    }

    private async loadUnitKits() {
        if (!this.units) {
            return;
        }
        return axios.get('/api/entity/unit-kits', {
            params: {
                collection: this.collectionId
            }
        }).then(
            response => {
                this.unitKits = response.data;
                this.afterLoadUnitKits();

                return response.data;
            }
        ).catch(error => {
            return Promise.reject(error);
        });
    }

    private afterLoadUnitKits() {
        this.service.setUnitKitsToKitchenHelper();
        this.sortAccessoryGoods(GOOD_TYPE_TABLETOP);
        this.sortAccessoryGoods(GOOD_TYPE_APRON);
        this.sortAccessoryGoods(GOOD_TYPE_PLINTH);
        this.sortAccessoryGoods(GOOD_TYPE_CORNER);
        this.disableCatalog();
    }

    private sortAccessoryGoods(catalogCode: string) {
        if (this.unitKits && this.unitKits[catalogCode]) {
            this.unitKits[catalogCode].sort((a, b) => {
                return b.width - a.width;
            })
        }
    }

    private enableUnits() {
        if (!this.units) {
            return;
        }
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;
        for (group of this.units) {
            for (createUnit of group.items) {
                if (createUnit.disable) {
                    delete createUnit.disable;
                }
                delete createUnit.availableCorpusMaterials;
                delete createUnit.availableFacadeMaterials;
                delete createUnit.disableSelects;
                delete createUnit.availableFacades;
            }
        }
    }

    private isDisableUnitByThreeModelFacade(facadeData: ISaveFacadeData, facade: IFacadeData, sizes: TFacadeSizes): boolean {
        let facadeThreeModel: IFacadeModelData | undefined;

        facadeThreeModel = this.service.getFacadeThreeModel(
            facade,
            {
                type: facadeData.modelType,
                depth: facade.depth,
                width: sizes.width,
                height: sizes.height
            },
            {
                height: 100,
                width: 50
            }
        );
        return !facadeThreeModel;
    }

    private isCreateUnitDisableByFacade(createUnit: ICreateObjectData, facadeId: string): boolean {
        if (createUnit.enableFacades && createUnit.enableFacades.indexOf(facadeId) === -1) {
            return true;
        }
        return !!createUnit.disableFacades && createUnit.disableFacades.indexOf(facadeId) !== -1;
    }

    private disableUnitByOffersTypeModules(createUnit: ICreateObjectData) {
        if (!this.units || !this.unitKits) {
            return;
        }
        if (!this.service.isCatalogCalculatePrice()) {
            return;
        }
        if (createUnit.notPrice) {
            return;
        }
        if (createUnit.disable) {
            return;
        }
        if (!this.unitKits[createUnit.catalogCode]) {
            createUnit.disable = true;
            return;
        }

        let selectOptions: ICreateObjectSelectOptions;
        let index: string;
        let index2: string;
        let selectItem: TSelectItem;
        let disableSelects: ICreateObjectDisableSelects | undefined;

        selectOptions = this.service.getCreateObjectDynamicSettings(createUnit);
        disableSelects = undefined;
        createUnit.disableSelects = undefined;
        if (Object.keys(selectOptions).length > 0) {
            for (index in selectOptions) {
                if (Array.isArray(selectOptions[index])) {
                    for (selectItem of (selectOptions[index] as TSelectItem[])) {
                        if (this.isDisableCreateUnitByOffer(createUnit, selectItem, index)) {
                            if (!disableSelects) {
                                disableSelects = {};
                            }
                            if (!disableSelects[index]) {
                                disableSelects[index] = [];
                            }
                            disableSelects[index].push(selectItem.id);
                        }
                    }
                    if (disableSelects &&
                        disableSelects[index].length === (selectOptions[index] as TSelectItem[]).length) {
                        createUnit.disable = true;
                        return;
                    }
                } else {
                    for (index2 in selectOptions[index]) {
                        for (selectItem of (selectOptions[index] as { [key: string]: TSelectItem[] })[index2]) {
                            if (this.isDisableCreateUnitByOffer(createUnit, selectItem, index2, index)) {
                                if (!disableSelects) {
                                    disableSelects = {};
                                }
                                if (!disableSelects[index + '_' + index2]) {
                                    disableSelects[index + '_' + index2] = [];
                                }
                                disableSelects[index + '_' + index2].push(selectItem.id);
                            }
                        }
                        if (disableSelects &&
                            disableSelects[index + '_' + index2].length === (selectOptions[index] as {
                                [key: string]: TSelectItem[]
                            })[index2].length) {
                            createUnit.disable = true;
                            return;
                        }
                    }
                }
            }
        } else {
            if (this.isDisableCreateUnitByOffer(createUnit)) {
                createUnit.disable = true;
                return;
            }
        }
        createUnit.disableSelects = disableSelects;
    }

    private isDisableCreateUnitByDetailOffers(
        createUnit: ICreateObjectData,
        selectItem?: TSelectItem,
        itemName?: string,
        groupName?: string
    ): boolean {
        let createOptions: any;
        let priceParams: IModulePriceParams | undefined;
        let modulePrices: IModulePriceData[] = [];
        let corpusMaterial: IMaterialData;
        let facadeMaterial: IFacadeMaterialData;
        let tabletopMaterial: ITabletopData;
        let corpusMaterials: IMaterialData[] | undefined;
        let facadeMaterials: IFacadeMaterialData[] | undefined;
        let tabletopMaterials: ITabletopData[] | undefined;
        let corpusFacadeMaterials: IFacadeMaterialData[] | undefined;
        let corpusFacadeMaterial: IFacadeMaterialData;
        let facade: IFacadeData | undefined;
        let index: string;
        let width: number | undefined;

        if (!this.unitKits || !this.bottomFacadeMaterial || !this.topFacadeMaterial) {
            return true;
        }
        createOptions = CommonHelper.deepCopy(this.service.getDefaultOptions(createUnit));
        if (selectItem && itemName) {
            width = (!isNaN(+selectItem.id)) ? +selectItem.id : undefined;
            if (groupName) {
                createOptions[groupName][itemName] = selectItem.id;
            } else {
                createOptions[itemName] = selectItem.id;
            }
        }

        priceParams = this.service.calculateCreateObjectPriceParams(createUnit, createOptions, width);
        if (!priceParams) {
            return true;
        }
        facade = this.getFacadeData(createUnit.level === LEVEL_BOTTOM ? this.bottomFacadeMaterial.facade : this.topFacadeMaterial.facade)
        corpusMaterials = this.getCatalogCorpusMaterials(priceParams);
        facadeMaterials = this.getCatalogFacadeMaterials(priceParams, facade);
        tabletopMaterials = this.getCatalogTabletopMaterials(priceParams);
        corpusFacadeMaterials = undefined;
        if (priceParams.facades) {
            for (index in priceParams.facades) {
                if (priceParams.facades[index].facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL) {
                    corpusFacadeMaterials = this.getCatalogFacadeMaterials(
                        priceParams,
                        undefined,
                        FACADE_MATERIAL_TYPE_CORPUS_MATERIAL
                    );
                    break;
                }
            }
        }
        if (corpusMaterials && facadeMaterials && tabletopMaterials) {
            for (corpusMaterial of corpusMaterials) {
                for (facadeMaterial of facadeMaterials) {
                    for (tabletopMaterial of tabletopMaterials) {
                        if (corpusFacadeMaterials && corpusFacadeMaterials.length) {
                            for (corpusFacadeMaterial of corpusFacadeMaterials) {
                                this.calculateItemModulePriceData(
                                    createUnit,
                                    modulePrices,
                                    priceParams,
                                    corpusMaterial,
                                    facadeMaterial,
                                    undefined,
                                    corpusFacadeMaterial,
                                    tabletopMaterial
                                );
                            }
                        } else {
                            this.calculateItemModulePriceData(
                                createUnit,
                                modulePrices,
                                priceParams,
                                corpusMaterial,
                                facadeMaterial,
                                undefined,
                                undefined,
                                tabletopMaterial
                            );
                        }
                    }
                }
            }
        }
        this.setCreateUnitAvailableCorpusMaterialsByModulePrices(createUnit, modulePrices, width);
        this.setCreateUnitAvailableCorpusFacadeMaterialsByModulePrices(createUnit, modulePrices, width);
        this.setCreateUnitAvailableFacadeMaterialsByModulePrices(createUnit, modulePrices, width);
        this.setCreateUnitAvailableTabletopMaterialsByModulePrices(createUnit, modulePrices, width);

        return this.isDisableUnitByDetailsPrices(modulePrices, createUnit);
    }

    private calculateItemModulePriceData(
        createUnit: ICreateObjectData,
        modulePrices: IModulePriceData[],
        priceParams: IModulePriceParams,
        corpusMaterial: IMaterialData,
        facadeMaterial: IFacadeMaterialData,
        corpusFacadeMaterial?: IFacadeMaterialData,
        secondFacadeMaterial?: IFacadeMaterialData,
        tabletopMaterial?: ITabletopData,
    ) {
        let itemPriceParams: IModulePriceParams;
        let modulePrice: IModulePriceData | undefined;

        itemPriceParams = this.calculateItemModulePriceParams(
            CommonHelper.deepCopy(priceParams),
            corpusMaterial,
            facadeMaterial,
            corpusFacadeMaterial,
            secondFacadeMaterial,
            tabletopMaterial
        );
        switch (itemPriceParams.calculateType) {
            case CATALOG_CALCULATE_TYPE_DETAILS:
                modulePrice = this.service.calculatePriceByDetails(itemPriceParams);
                if (modulePrice && !this.isDisableUnitByDetailsPrice(modulePrice, createUnit)) {
                    modulePrices.push(modulePrice);
                }
                break;
            case CATALOG_CALCULATE_TYPE_MODULE:
                modulePrice = this.service.calculatePriceByModule(itemPriceParams);
                if (modulePrice && !this.isDisableUnitByModulePrice([modulePrice])) {
                    modulePrices.push(modulePrice);
                }
                break;
        }
    }

    private calculateItemModulePriceParams(
        itemPriceParams: IModulePriceParams,
        corpusMaterial: IMaterialData,
        facadeMaterial: IFacadeMaterialData,
        corpusFacadeMaterial?: IFacadeMaterialData,
        secondFacadeMaterial?: IFacadeMaterialData,
        tabletopMaterial?: ITabletopData
    ): IModulePriceParams {
        let index: string;

        if (corpusMaterial.id !== NONE_MATERIAL &&
            itemPriceParams.corpusMaterial !== NONE_MATERIAL) {
            itemPriceParams.corpusMaterial = corpusMaterial.id;
        }
        if (itemPriceParams.corpus) {
            if (corpusMaterial.id !== NONE_MATERIAL &&
                itemPriceParams.corpus.material !== NONE_MATERIAL) {
                itemPriceParams.corpus.material = corpusMaterial.id;
            }
        }
        if (facadeMaterial.id !== NONE_MATERIAL) {
            itemPriceParams.facadeMaterial = facadeMaterial.id;
        }
        if (itemPriceParams.facades) {
            for (index in itemPriceParams.facades) {
                if (itemPriceParams.facades[index].for === FACADE_FOR_SECOND) {
                    continue;
                }
                if (itemPriceParams.facades[index].facadeMaterial === NONE_MATERIAL) {
                    continue;
                }
                switch (itemPriceParams.facades[index].facadeMaterialType) {
                    case FACADE_MATERIAL_TYPE_CORPUS_MATERIAL:
                        if (corpusFacadeMaterial) {
                            // Сделал ИД материал
                            itemPriceParams.facades[index].facadeMaterial = corpusFacadeMaterial.material;
                        }
                        break;
                    case FACADE_MATERIAL_TYPE_SECOND:
                        if (secondFacadeMaterial) {
                            itemPriceParams.facades[index].facadeMaterial = secondFacadeMaterial.id;
                        }
                        break;
                    default:
                        itemPriceParams.facades[index].facadeMaterial = facadeMaterial.id;
                        break;
                }
            }
        }
        if (tabletopMaterial && tabletopMaterial.id !== NONE_MATERIAL && itemPriceParams.tabletops) {
            for (index in itemPriceParams.tabletops) {
                itemPriceParams.tabletops[index].corpusMaterial = tabletopMaterial.id;
                itemPriceParams.tabletops[index].sizes.height = tabletopMaterial.height;
            }
        }

        return itemPriceParams;
    }

    private setCreateUnitAvailableCorpusMaterialsByModulePrices(
        createUnit: ICreateObjectData,
        modulePrices: IModulePriceData[],
        width?: number
    ) {
        let modulePrice: IModulePriceData;
        let availableCorpusMaterials: ICorpusMaterialIds = {};
        let materialId: string;
        let widthKey: string;

        widthKey = width ? '' + width : 'default';
        if (createUnit.availableCorpusMaterials && createUnit.availableCorpusMaterials[widthKey]) {
            for (materialId of createUnit.availableCorpusMaterials[widthKey]) {
                availableCorpusMaterials[materialId] = materialId;
            }
        }
        for (modulePrice of modulePrices) {
            if (!this.checkWidthFromModulePrice(modulePrice, width)) {
                continue;
            }
            if (modulePrice.corpus &&
                modulePrice.corpus.offer &&
                modulePrice.corpus.offer.corpusMaterial !== NONE_MATERIAL) {
                availableCorpusMaterials[modulePrice.corpus.offer.corpusMaterial] = modulePrice.corpus.offer.corpusMaterial;
            }
            if (modulePrice.module &&
                modulePrice.module.offer &&
                modulePrice.module.offer.corpusMaterial !== NONE_MATERIAL) {
                availableCorpusMaterials[modulePrice.module.offer.corpusMaterial] = modulePrice.module.offer.corpusMaterial;
            }
        }
        if (Object.keys(availableCorpusMaterials).length) {
            if (!createUnit.availableCorpusMaterials) {
                createUnit.availableCorpusMaterials = {};
            }
            createUnit.availableCorpusMaterials[widthKey] = Object.keys(availableCorpusMaterials);
        }
    }

    private setCreateUnitAvailableCorpusFacadeMaterialsByModulePrices(
        createUnit: ICreateObjectData,
        modulePrices: IModulePriceData[],
        width?: number
    ) {
        let modulePrice: IModulePriceData;
        let availableCorpusFacadeMaterials: ICorpusMaterialIds = {};
        let materialId: string;
        let widthKey: string;
        let facadePrice: IFacadePriceData;

        widthKey = width ? '' + width : 'default';
        if (createUnit.availableCorpusFacadeMaterials && createUnit.availableCorpusFacadeMaterials[widthKey]) {
            for (materialId of createUnit.availableCorpusFacadeMaterials[widthKey]) {
                availableCorpusFacadeMaterials[materialId] = materialId;
            }
        }
        for (modulePrice of modulePrices) {
            if (!this.checkWidthFromModulePrice(modulePrice, width)) {
                continue;
            }
            if (modulePrice.facades) {
                for (facadePrice of modulePrice.facades) {
                    if (facadePrice.offer && facadePrice.facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL) {
                        availableCorpusFacadeMaterials[facadePrice.offer.facadeMaterial] = facadePrice.offer.facadeMaterial;

                    }
                }
            }
        }
        if (Object.keys(availableCorpusFacadeMaterials).length) {
            if (!createUnit.availableCorpusFacadeMaterials) {
                createUnit.availableCorpusFacadeMaterials = {};
            }
            createUnit.availableCorpusFacadeMaterials[widthKey] = Object.keys(availableCorpusFacadeMaterials);
        }
    }

    private checkWidthFromModulePrice(modulePrice: IModulePriceData, width?: number): boolean {
        if (!width) {
            return true;
        }
        return modulePrice.sizes.width === width;
    }

    private setCreateUnitAvailableTabletopMaterialsByModulePrices(
        createUnit: ICreateObjectData,
        modulePrices: IModulePriceData[],
        width?: number
    ) {
        let modulePrice: IModulePriceData;
        let availableTabletopMaterials: ICorpusMaterialIds = {};
        let materialId: string;
        let widthKey: string;
        let detailPrice: IDetailPriceData;

        widthKey = width ? '' + width : 'default';
        if (createUnit.availableTabletopMaterials && createUnit.availableTabletopMaterials[widthKey]) {
            for (materialId of createUnit.availableTabletopMaterials[widthKey]) {
                availableTabletopMaterials[materialId] = materialId;
            }
        }
        for (modulePrice of modulePrices) {
            if (!this.checkWidthFromModulePrice(modulePrice, width)) {
                continue;
            }
            if (modulePrice.tabletops) {
                for (detailPrice of modulePrice.tabletops) {
                    if (detailPrice.offer) {
                        availableTabletopMaterials[detailPrice.offer.corpusMaterial] = detailPrice.offer.corpusMaterial;
                    }
                }
            }
        }
        if (Object.keys(availableTabletopMaterials).length) {
            if (!createUnit.availableTabletopMaterials) {
                createUnit.availableTabletopMaterials = {};
            }
            createUnit.availableTabletopMaterials[widthKey] = Object.keys(availableTabletopMaterials);
        }
    }

    private setCreateUnitAvailableFacadeMaterialsByModulePrices(
        createUnit: ICreateObjectData,
        modulePrices: IModulePriceData[],
        width?: number
    ) {
        let modulePrice: IModulePriceData;
        let availableFacadeMaterialObjects: { [key: string]: IFacadeMaterialIds } = {};
        let availableFacadeMaterials: ICreateObjectDataFacadeMaterial;
        let facade: IDetailPriceData;
        let facadeMaterials: IFacadeMaterials;
        let facadeMaterial: IFacadeMaterialData | undefined;
        let facadeId: string;
        let materialId: string;
        let materialIds: string[] | undefined;
        let widthKey: string;

        widthKey = width ? '' + width : 'default';
        facadeMaterials = this.getFacadeMaterialsObject();
        if (createUnit.availableFacadeMaterials !== undefined &&
            createUnit.availableFacadeMaterials[widthKey] !== undefined) {
            availableFacadeMaterials = createUnit.availableFacadeMaterials[widthKey];
            for (facadeId in availableFacadeMaterials) {
                materialIds = availableFacadeMaterials[facadeId];
                if (materialIds) {
                    for (materialId of materialIds) {
                        if (!availableFacadeMaterialObjects[facadeId]) {
                            availableFacadeMaterialObjects[facadeId] = {};
                        }
                        availableFacadeMaterialObjects[facadeId][materialId] = materialId;
                    }
                }
            }
        }
        for (modulePrice of modulePrices) {
            if (!this.checkWidthFromModulePrice(modulePrice, width)) {
                continue;
            }
            if (modulePrice.facades) {
                for (facade of modulePrice.facades) {
                    if (facade.offer &&
                        facade.offer.facadeMaterial !== NONE_MATERIAL) {
                        facadeMaterial = facadeMaterials[facade.offer.facadeMaterial];
                        if (facadeMaterial) {
                            if (!availableFacadeMaterialObjects[facadeMaterial.facade]) {
                                availableFacadeMaterialObjects[facadeMaterial.facade] = {};
                            }
                            availableFacadeMaterialObjects[facadeMaterial.facade][facadeMaterial.id] = facadeMaterial.id;
                        }
                    }
                }
            }
            if (modulePrice.module &&
                modulePrice.module.offer &&
                modulePrice.module.offer.facadeMaterial !== NONE_MATERIAL) {
                facadeMaterial = facadeMaterials[modulePrice.module.offer.facadeMaterial];
                if (facadeMaterial) {
                    if (!availableFacadeMaterialObjects[facadeMaterial.facade]) {
                        availableFacadeMaterialObjects[facadeMaterial.facade] = {};
                    }
                    availableFacadeMaterialObjects[facadeMaterial.facade][facadeMaterial.id] = facadeMaterial.id;
                }
            }
        }
        if (Object.keys(availableFacadeMaterialObjects).length) {
            availableFacadeMaterials = {};
            for (facadeId in availableFacadeMaterialObjects) {
                availableFacadeMaterials[facadeId] = Object.values(availableFacadeMaterialObjects[facadeId]);
            }
            if (!createUnit.availableFacadeMaterials) {
                createUnit.availableFacadeMaterials = {};
            }
            if (!createUnit.availableFacadeMaterials[widthKey]) {
                createUnit.availableFacadeMaterials[widthKey] = {};
            }
            createUnit.availableFacadeMaterials[widthKey] = availableFacadeMaterials;
        }
    }

    private getCatalogCorpusMaterials(priceParams: IModulePriceParams): IMaterialData[] | undefined {
        if (!this.corpusMaterials) {
            return undefined;
        }
        if (priceParams.corpusMaterial === NONE_MATERIAL ||
            (priceParams.corpus && priceParams.corpus.material === NONE_MATERIAL)) {
            return [{id: NONE_MATERIAL, title: i18n.t('Без материала')} as IMaterialData];
        }

        return this.corpusMaterials.filter(item => item.isCustom !== true);
    }

    private getCatalogTabletopMaterials(priceParams: IModulePriceParams): ITabletopData[] | undefined {
        if (!this.tabletops) {
            return undefined;
        }
        if (!priceParams.tabletops) {
            return [{
                id: NONE_MATERIAL,
                title: i18n.t('Без материала'),
                height: 0,
                materialType: '',
            }] as ITabletopData[];
        }
        let tabletops: ITabletopData[];

        tabletops = this.tabletops;

        return tabletops;
    }

    private getCatalogFacadeMaterials(
        priceParams: IModulePriceParams,
        facade?: IFacadeData,
        facadeMaterialType?: TFacadeMaterialType
    ): IFacadeMaterialData[] | undefined {
        if (!this.facadeMaterials) {
            return undefined;
        }
        if (priceParams.facadeMaterial === NONE_MATERIAL) {
            return [{
                id: NONE_MATERIAL,
                title: i18n.t('Без материала'),
                facade: NONE_MATERIAL,
                material: NONE_MATERIAL
            }];
        }
        let facadeMaterials: IFacadeMaterialData[] = [];
        let facadeMaterial: IFacadeMaterialData;

        for (facadeMaterial of this.facadeMaterials) {
            if ((!facade || (facade && facade.id === facadeMaterial.facade && !facadeMaterial.isCustom)) &&
                (!facadeMaterialType || facadeMaterialType === facadeMaterial.facadeMaterialType ||
                    (facadeMaterialType === FACADE_MATERIAL_TYPE_DEFAULT && !facadeMaterial.facadeMaterialType))) {
                facadeMaterials.push(facadeMaterial);
            }
        }

        return facadeMaterials.length ? facadeMaterials : undefined;
    }

    private isDisableUnitByDetailsPrices(modulePrices: IModulePriceData[], createUnit: ICreateObjectData): boolean {
        let modulePrice: IModulePriceData;

        if (!modulePrices || !modulePrices.length) {
            return true;
        }
        for (modulePrice of modulePrices) {
            if (!this.isDisableUnitByDetailsPrice(modulePrice, createUnit)) {
                return false;
            }
        }

        return true;
    }

    private isDisableUnitByDetailsPrice(
        modulePrice: IModulePriceData | undefined,
        createUnit: ICreateObjectData
    ): boolean {
        if (!this.service.isCatalogCalculatePrice()) {
            return false;
        }
        if (!modulePrice || !this.service.checkModulePriceActiveAndStore(modulePrice)) {
            return true;
        }

        return this.service.getCheckCatalogType(
            createUnit,
            (createUnit.canStretch && createUnit.isStretch)) === CHECK_CATALOG_STRICT &&
            !modulePrice.offer;
    }

    private isDisableCreateUnitByOffer(createUnit: ICreateObjectData, selectItem?: TSelectItem, itemName?: string, groupName?: string): boolean {
        let createOptions: any;
        let priceParams: IModulePriceParams | undefined;
        let modulePrices: IModulePriceData[] = [];
        let corpusMaterial: IMaterialData;
        let facadeMaterial: IFacadeMaterialData;
        let corpusMaterials: IMaterialData[] | undefined;
        let facadeMaterials: IFacadeMaterialData[] | undefined;
        let tabletopMaterials: ITabletopData[] | undefined;
        let tabletopMaterial: ITabletopData;
        let corpusFacadeMaterials: IFacadeMaterialData[] | undefined;
        let corpusFacadeMaterial: IFacadeMaterialData;
        let facade: IFacadeData | undefined;
        let index: string;
        let width: number | undefined;

        if (!this.unitKits ||
            !this.unitKits[createUnit.catalogCode] ||
            !this.bottomFacadeMaterial ||
            !this.topFacadeMaterial) {
            return true;
        }
        createOptions = CommonHelper.deepCopy(this.service.getDefaultOptions(createUnit));
        if (selectItem && itemName) {
            width = (!isNaN(+selectItem.id)) ? +selectItem.id : undefined;
            if (groupName) {
                createOptions[groupName][itemName] = selectItem.id;
            } else {
                createOptions[itemName] = selectItem.id;
            }
        }

        priceParams = this.service.calculateCreateObjectPriceParams(createUnit, createOptions, width);
        if (!priceParams) {
            return true;
        }
        facade = this.getFacadeData(createUnit.level === LEVEL_BOTTOM ? this.bottomFacadeMaterial.facade : this.topFacadeMaterial.facade)
        corpusMaterials = this.getCatalogCorpusMaterials(priceParams);
        facadeMaterials = this.getCatalogFacadeMaterials(priceParams, facade);
        tabletopMaterials = this.getCatalogTabletopMaterials(priceParams);
        corpusFacadeMaterials = undefined;
        if (priceParams.facades) {
            for (index in priceParams.facades) {
                if (priceParams.facades[index].facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL) {
                    corpusFacadeMaterials = this.getCatalogFacadeMaterials(priceParams, facade, FACADE_MATERIAL_TYPE_CORPUS_MATERIAL);
                    break;
                }
            }
        }
        if (corpusMaterials && facadeMaterials && tabletopMaterials) {
            for (corpusMaterial of corpusMaterials) {
                for (facadeMaterial of facadeMaterials) {
                    for (tabletopMaterial of tabletopMaterials) {
                        if (corpusFacadeMaterials && corpusFacadeMaterials.length) {
                            for (corpusFacadeMaterial of corpusFacadeMaterials) {
                                this.calculateItemModulePriceData(
                                    createUnit,
                                    modulePrices,
                                    priceParams,
                                    corpusMaterial,
                                    facadeMaterial,
                                    undefined,
                                    corpusFacadeMaterial,
                                    tabletopMaterial
                                );
                            }
                        } else {
                            this.calculateItemModulePriceData(
                                createUnit,
                                modulePrices,
                                priceParams,
                                corpusMaterial,
                                facadeMaterial,
                                undefined,
                                undefined,
                                tabletopMaterial
                            );
                        }
                    }
                }
            }
        }
        this.setCreateUnitAvailableCorpusMaterialsByModulePrices(createUnit, modulePrices, width);
        this.setCreateUnitAvailableCorpusFacadeMaterialsByModulePrices(createUnit, modulePrices, width);
        this.setCreateUnitAvailableFacadeMaterialsByModulePrices(createUnit, modulePrices, width);
        this.setCreateUnitAvailableTabletopMaterialsByModulePrices(createUnit, modulePrices, width);

        return this.isDisableUnitByModulePrice(modulePrices);
    }

    private isDisableUnitByModulePrice(
        modulePrices: IModulePriceData[]
    ): boolean {
        let successModulePrices: IModulePriceData[];

        if (!this.service.isCatalogCalculatePrice()) {
            return false;
        }

        successModulePrices = modulePrices.filter(itemPriceData => itemPriceData.errors.length <= 0);
        if (successModulePrices.length <= 0) {
            return true;
        }
        if (this.service.getOptions().preloadPrices) {
            successModulePrices = successModulePrices.filter(itemPriceData => itemPriceData.active !== false);
            if (successModulePrices.length <= 0) {
                return true;
            }
            if (this.service.getOptions().checkStore) {
                successModulePrices = successModulePrices.filter(itemPriceData => itemPriceData.stock === undefined || itemPriceData.stock <= 0);
                if (successModulePrices.length <= 0) {
                    return true;
                }
            }
        }

        return false;
    }

    private disableUnitByOffersTypeDetails(createUnit: ICreateObjectData) {
        if (!this.units || !this.unitKits) {
            return;
        }
        if (!this.service.isCatalogCalculatePrice()) {
            return;
        }
        if (createUnit.notPrice) {
            return;
        }
        if (createUnit.disable) {
            return;
        }

        let selectOptions: ICreateObjectSelectOptions;
        let index: string;
        let index2: string;
        let selectItem: TSelectItem;
        let disableSelects: ICreateObjectDisableSelects | undefined;

        selectOptions = this.service.getCreateObjectDynamicSettings(createUnit);
        disableSelects = undefined;
        createUnit.disableSelects = undefined;
        if (Object.keys(selectOptions).length > 0) {
            for (index in selectOptions) {
                if (Array.isArray(selectOptions[index])) {
                    for (selectItem of (selectOptions[index] as TSelectItem[])) {
                        if (this.isDisableCreateUnitByDetailOffers(createUnit, selectItem, index)) {
                            if (!disableSelects) {
                                disableSelects = {};
                            }
                            if (!disableSelects[index]) {
                                disableSelects[index] = [];
                            }
                            disableSelects[index].push(selectItem.id);
                        }
                    }
                    if (disableSelects &&
                        disableSelects[index].length === (selectOptions[index] as TSelectItem[]).length) {
                        createUnit.disable = true;
                        return;
                    }
                } else {
                    for (index2 in selectOptions[index]) {
                        for (selectItem of (selectOptions[index] as { [key: string]: TSelectItem[] })[index2]) {
                            if (this.isDisableCreateUnitByDetailOffers(createUnit, selectItem, index2, index)) {
                                if (!disableSelects) {
                                    disableSelects = {};
                                }
                                if (!disableSelects[index + '_' + index2]) {
                                    disableSelects[index + '_' + index2] = [];
                                }
                                disableSelects[index + '_' + index2].push(selectItem.id);
                            }
                        }
                        if (disableSelects &&
                            disableSelects[index + '_' + index2].length === (selectOptions[index] as {
                                [key: string]: TSelectItem[]
                            })[index2].length) {
                            createUnit.disable = true;
                            return;
                        }
                    }
                }
            }
        } else {
            if (this.isDisableCreateUnitByDetailOffers(createUnit)) {
                createUnit.disable = true;
                return;
            }
        }
        createUnit.disableSelects = disableSelects;
    }

    private disableUnitsByOffers() {
        if (!this.units || !this.unitKits || !this.bottomFacadeMaterial || !this.topFacadeMaterial) {
            return;
        }
        if (!this.service.isCatalogCalculatePrice()) {
            return;
        }
        let group: ICreateGroup;
        let createUnit: ICreateObjectData;
        let facade: IFacadeData | undefined;

        for (group of this.units) {
            for (createUnit of group.items) {
                if (this.checkUids && this.checkUids.includes(createUnit.uid)) {
                    debugger;
                }
                if (createUnit.isAccessory) {
                    this.disableAccessoryUnit(createUnit);
                    continue;
                }
                if (createUnit.notPrice) {
                    continue;
                }
                if (createUnit.disable) {
                    continue;
                }
                switch (this.service.getCreateObjectCalculateType(createUnit)) {
                    case CATALOG_CALCULATE_TYPE_MODULE:
                        this.disableUnitCatalogCodeByModule(createUnit);
                        break;
                    case CATALOG_CALCULATE_TYPE_DETAILS:
                        this.disableUnitCatalogCodeByDetails(createUnit);
                        break;
                }
                if (createUnit.disable) {
                    continue;
                }
                facade = this.getFacadeData(createUnit.level === LEVEL_BOTTOM ? this.bottomFacadeMaterial.facade : this.topFacadeMaterial.facade)
                if (!facade) {
                    createUnit.disable = true;
                    continue;
                }
                if (this.isCreateUnitDisableByFacade(createUnit, facade.id)) {
                    createUnit.disable = true;
                    continue;
                }
                // if (this.isCreateUnitDisableByFacadeThreeModel(createUnit, facade)) {
                //     console.log('isCreateUnitDisableByFacadeThreeModel', createUnit.uid);
                //     createUnit.disable = true;
                //     continue;
                // }
                switch (this.service.getCreateObjectCalculateType(createUnit)) {
                    case CATALOG_CALCULATE_TYPE_MODULE:
                        this.disableUnitByOffersTypeModules(createUnit);
                        break;
                    case CATALOG_CALCULATE_TYPE_DETAILS:
                        this.disableUnitByOffersTypeDetails(createUnit);
                        break;
                }
            }
        }

    }

    private disableUnitCatalogCodeByDetails(createUnit: ICreateObjectData) {
        if (!this.units) {
            return;
        }
        if (!this.unitKits) {
            return;
        }
        if (!this.service.isCatalogCalculatePrice()) {
            return;
        }
        let detailOptions: IOption | IOptionGroup;
        let option: IOption;
        let isSetCatalogCode: boolean;

        if (createUnit.notPrice) {
            return;
        }
        for (detailOptions of createUnit.options) {
            if (detailOptions.id === 'corpus') {
                isSetCatalogCode = false;
                for (option of (detailOptions as IOptionGroup).options) {
                    if (option.id === 'material' && option.value === NONE_MATERIAL) {
                        isSetCatalogCode = true;
                    }
                    if (option.id === 'catalogCode') {
                        isSetCatalogCode = true;
                        if (!this.unitKits[option.value] || option.value.length <= 0) {
                            createUnit.disable = true;
                            return;
                        }
                    }
                }
                if (!isSetCatalogCode) {
                    createUnit.disable = true;
                    return;
                }
            }
            if (createUnit.disable) {
                return;
            }
        }
    }

    private disableUnitCatalogCodeByModule(createUnit: ICreateObjectData) {
        if (!this.units) {
            return;
        }
        if (!this.unitKits) {
            return;
        }
        if (!this.service.isCatalogCalculatePrice()) {
            return;
        }
        if (createUnit.notPrice) {
            return;
        }
        if (!this.unitKits[createUnit.catalogCode]) {
            createUnit.disable = true;
            return;
        }
    }

    private disableAccessoryUnit(createUnit: ICreateObjectData) {
        if (!createUnit.isAccessory) {
            return;
        }
        switch (createUnit.catalogCode) {
            case GOOD_TYPE_TABLETOP:
            case GOOD_TYPE_APRON:
                this.setAvailableAccessoryDataToCreateUnit(createUnit);
                break;
            default:
                console.warn('Replace disableAccessoryUnit', createUnit.catalogCode);
        }
    }

    private setAvailableAccessoryDataToCreateUnit(createUnit: ICreateObjectData) {
        createUnit.availableHeights = undefined;
        createUnit.widthRange = undefined;
        createUnit.depthRange = undefined;

        let availableHeights: {[key: string]: string} = {};
        let importOffer: IImportOffer;
        let externalId: 'externalGuid' | 'vendorCode';
        let widthRange: TRange;
        let depthRange: TRange;
        let maxWidth: number = -Infinity;
        let maxDepth: number = -Infinity;
        let selectOptions: ICreateObjectSelectOptions;
        let heightItems: TSelectItem[];
        let heightItem: TSelectItem;
        let disableSelects: ICreateObjectDisableSelects | undefined;
        let offerProductPrice: IProductPrice | undefined;

        externalId = this.service.getOfferExternalId();
        if (!this.unitKits[createUnit.catalogCode]) {
            return;
        }
        selectOptions = this.service.getCreateObjectDynamicSettings(createUnit);
        widthRange = this.getAccessoryDefaultWidthRange(createUnit.catalogCode);
        depthRange = this.getAccessoryDefaultDepthRange(createUnit.catalogCode);
        for (importOffer of this.unitKits[createUnit.catalogCode]) {
            offerProductPrice = this.service.getOfferProductPrice(importOffer[externalId]);
            if (offerProductPrice &&
                ((offerProductPrice.active !== undefined && !offerProductPrice.active) ||
                    (this.service.getOptions().checkStore && !offerProductPrice.stock))) {
                continue;
            }
            if (maxWidth < importOffer.width) {
                maxWidth = importOffer.width
            }
            if (maxDepth < importOffer.depth) {
                maxDepth = importOffer.depth
            }
            availableHeights['' + importOffer.height] = '' + importOffer.height;
        }
        if (isFinite(maxWidth)) {
            widthRange.max = maxWidth;
        }
        if (isFinite(maxDepth)) {
            depthRange.max = maxDepth;
        }

        createUnit.availableHeights = Object.values(availableHeights).sort((a, b) => (+a) - (+b));
        createUnit.widthRange = widthRange;
        createUnit.depthRange = depthRange;
        if (createUnit.availableHeights && selectOptions &&
            selectOptions.sizes && 'height' in selectOptions.sizes) {
            heightItems = selectOptions.sizes.height as TSelectItem[];
            disableSelects = {
                'sizes_height': []
            };
            for (heightItem of heightItems) {
                if (!createUnit.availableHeights.includes(heightItem.id)) {
                    disableSelects['sizes_height'].push(heightItem.id);
                }
            }
        }
        createUnit.disableSelects = disableSelects;
    }

    private getAccessoryDefaultWidthRange(catalogCode: string): TRange {
        switch (catalogCode) {
            case GOOD_TYPE_TABLETOP:
            case GOOD_TYPE_APRON:
            default:
                return {min: 10, max: this.service.getRoomLength()}
        }
    }

    private getAccessoryDefaultDepthRange(catalogCode: string): TRange {
        switch (catalogCode) {
            case GOOD_TYPE_TABLETOP:
                return {min: 10, max: 600}
            case GOOD_TYPE_APRON:
                return {min: 10, max: 600}
            default:
                return {min: 10, max: this.service.getRoom().getHeight()}
        }
    }

    ///////////////////////////////////////////////

    public static calculateCreateObjectWidths(createObject: ICreateObjectData): ICreateObjectDataWidths {
        let option: IOption | IOptionGroup;
        let childOption: IOption;
        let selectItem: TSelectItem;
        let radioButtonItem: TSelectItem;
        let widths: TSelectItem[] = [];
        let defaultWidth: string | undefined;
        let corpusWidths: TSelectItem[] = [];
        let defaultCorpusWidth: string | undefined;

        for (option of createObject.options) {
            if (option.id === 'sizes') {
                if ((option as IOption).type === OPTION_TYPE_JSON) {
                    defaultWidth = (option as IOption).value.width;
                } else {
                    for (childOption of (option as IOptionGroup).options) {
                        if (childOption.id === 'width') {
                            switch ((childOption as IOption).type) {
                                case OPTION_TYPE_HIDDEN_NUMBER:
                                    defaultWidth = '' + (childOption as IOptionHiddenNumber).value;
                                    break;
                                case OPTION_TYPE_SELECT:
                                    if ((childOption as IOptionSelect).defaultValue !== undefined) {
                                        defaultWidth = (childOption as IOptionSelect).defaultValue;
                                    }
                                    for (selectItem of (childOption as IOptionSelect).items) {
                                        if (createObject.disableSelects && createObject.disableSelects[option.id + '_' + childOption.id] &&
                                            createObject.disableSelects[option.id + '_' + childOption.id].includes(selectItem.id)) {
                                            if (selectItem.id === defaultWidth) {
                                                defaultWidth = undefined;
                                            }
                                            widths.push({...selectItem, disabled: true});
                                        } else {
                                            widths.push({...selectItem});
                                        }
                                    }
                                    if (!defaultWidth) {
                                        for (selectItem of widths) {
                                            if (!selectItem.disabled) {
                                                defaultWidth = selectItem.id;
                                                break;
                                            }
                                        }
                                    }
                                    break;
                                case OPTION_TYPE_RADIOBUTTON:
                                    if ((childOption as IOptionRadioButton).defaultValue !== undefined) {
                                        defaultWidth = (childOption as IOptionRadioButton).defaultValue;
                                    }
                                    for (radioButtonItem of (childOption as IOptionRadioButton).items) {
                                        if (createObject.disableSelects && createObject.disableSelects[option.id + '_' + childOption.id] &&
                                            createObject.disableSelects[option.id + '_' + childOption.id].includes(radioButtonItem.id)) {
                                            if (radioButtonItem.id === defaultWidth) {
                                                defaultWidth = undefined;
                                            }
                                            widths.push({...radioButtonItem, disabled: true});
                                        } else {
                                            widths.push({...radioButtonItem});
                                        }
                                    }
                                    if (!defaultWidth) {
                                        for (radioButtonItem of widths) {
                                            if (!radioButtonItem.disabled) {
                                                defaultWidth = radioButtonItem.id;
                                                break;
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
            if (option.id === 'corpus') {
                if ((option as IOption).type === OPTION_TYPE_JSON) {
                    defaultCorpusWidth = (option as IOption).value.width;
                } else {
                    for (childOption of (option as IOptionGroup).options) {
                        if (childOption.id === 'width') {
                            switch ((childOption as IOption).type) {
                                case OPTION_TYPE_HIDDEN_NUMBER:
                                    defaultCorpusWidth = '' + (childOption as IOptionHiddenNumber).value;
                                    break;
                                case OPTION_TYPE_SELECT:
                                    if ((childOption as IOptionSelect).defaultValue !== undefined) {
                                        defaultCorpusWidth = (childOption as IOptionSelect).defaultValue;
                                    }
                                    for (selectItem of (childOption as IOptionSelect).items) {
                                        if (createObject.disableSelects && createObject.disableSelects[option.id + '_' + childOption.id] &&
                                            createObject.disableSelects[option.id + '_' + childOption.id].includes(selectItem.id)) {
                                            if (selectItem.id === defaultCorpusWidth) {
                                                defaultCorpusWidth = undefined;
                                            }
                                            corpusWidths.push({...selectItem, disabled: true});
                                        } else {
                                            corpusWidths.push({...selectItem});
                                        }
                                    }
                                    if (!defaultCorpusWidth) {
                                        for (selectItem of corpusWidths) {
                                            if (!selectItem.disabled) {
                                                defaultCorpusWidth = selectItem.id;
                                                break;
                                            }
                                        }
                                    }
                                    break;
                                case OPTION_TYPE_RADIOBUTTON:
                                    if ((childOption as IOptionRadioButton).defaultValue !== undefined) {
                                        defaultCorpusWidth = (childOption as IOptionRadioButton).defaultValue;
                                    }
                                    for (radioButtonItem of (childOption as IOptionRadioButton).items) {
                                        if (createObject.disableSelects && createObject.disableSelects[option.id + '_' + childOption.id] &&
                                            createObject.disableSelects[option.id + '_' + childOption.id].includes(radioButtonItem.id)) {
                                            if (radioButtonItem.id === defaultCorpusWidth) {
                                                defaultCorpusWidth = undefined;
                                            }
                                            corpusWidths.push({...radioButtonItem, disabled: true});
                                        } else {
                                            corpusWidths.push({...radioButtonItem});
                                        }
                                    }
                                    if (!defaultCorpusWidth) {
                                        for (radioButtonItem of corpusWidths) {
                                            if (!radioButtonItem.disabled) {
                                                defaultCorpusWidth = radioButtonItem.id;
                                                break;
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
        }

        return {
            widths: widths,
            defaultWidth: defaultWidth,
            corpusWidths: corpusWidths,
            defaultCorpusWidth: defaultCorpusWidth
        };
    }

    public static calculateCreateObjectHeights(createObject: ICreateObjectData): ICreateObjectDataHeights {
        let option: IOption | IOptionGroup;
        let childOption: IOption;
        let selectItem: TSelectItem;
        let radioButtonItem: TSelectItem;
        let heights: string[] = [];
        let defaultHeight: string | undefined;
        let corpusHeights: string[] = [];
        let defaultCorpusHeight: string | undefined;

        for (option of createObject.options) {
            if (option.id === 'sizes') {
                if ((option as IOption).type === OPTION_TYPE_JSON) {
                    defaultHeight = (option as IOption).value.height;
                } else {
                    for (childOption of (option as IOptionGroup).options) {
                        if (childOption.id === 'height') {
                            switch ((childOption as IOption).type) {
                                case OPTION_TYPE_HIDDEN_NUMBER:
                                    defaultHeight = '' + (childOption as IOptionHiddenNumber).value;
                                    break;
                                case OPTION_TYPE_NUMBER:
                                case OPTION_TYPE_RANGE:
                                    defaultHeight = '' + (childOption as IOptionNumber).value;
                                    break;
                                case OPTION_TYPE_SELECT:
                                    if ((childOption as IOptionSelect).defaultValue !== undefined) {
                                        defaultHeight = (childOption as IOptionSelect).defaultValue;
                                    }
                                    for (selectItem of (childOption as IOptionSelect).items) {
                                        if (createObject.availableHeights && !createObject.availableHeights.includes(selectItem.id)) {
                                            continue;
                                        }
                                        heights.push(selectItem.id);
                                    }
                                    if (defaultHeight && !heights.includes(defaultHeight)) {
                                        defaultHeight = heights[0];
                                    }
                                    break;
                                case OPTION_TYPE_RADIOBUTTON:
                                    if ((childOption as IOptionRadioButton).defaultValue !== undefined) {
                                        defaultHeight = (childOption as IOptionRadioButton).defaultValue;
                                    }
                                    for (radioButtonItem of (childOption as IOptionRadioButton).items) {
                                        if (createObject.availableHeights && !createObject.availableHeights.includes(radioButtonItem.id)) {
                                            continue;
                                        }
                                        heights.push(radioButtonItem.id);
                                    }
                                    if (defaultHeight && !heights.includes(defaultHeight)) {
                                        defaultHeight = heights[0];
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
            if (option.id === 'corpus') {
                if ((option as IOption).type === OPTION_TYPE_JSON) {
                    defaultCorpusHeight = (option as IOption).value.height;
                } else {
                    for (childOption of (option as IOptionGroup).options) {
                        if (childOption.id === 'height') {
                            switch ((childOption as IOption).type) {
                                case OPTION_TYPE_HIDDEN_NUMBER:
                                    defaultCorpusHeight = '' + (childOption as IOptionHiddenNumber).value;
                                    break;
                                case OPTION_TYPE_NUMBER:
                                case OPTION_TYPE_RANGE:
                                    defaultCorpusHeight = '' + (childOption as IOptionNumber).value;
                                    break;
                                case OPTION_TYPE_SELECT:
                                    if ((childOption as IOptionSelect).defaultValue !== undefined) {
                                        defaultCorpusHeight = (childOption as IOptionSelect).defaultValue;
                                    }
                                    for (selectItem of (childOption as IOptionSelect).items) {
                                        if (createObject.availableHeights && !createObject.availableHeights.includes(selectItem.id)) {
                                            continue;
                                        }
                                        corpusHeights.push(selectItem.id);
                                    }
                                    if (defaultCorpusHeight && !corpusHeights.includes(defaultCorpusHeight)) {
                                        defaultCorpusHeight = corpusHeights[0];
                                    }
                                    break;
                                case OPTION_TYPE_RADIOBUTTON:
                                    if ((childOption as IOptionRadioButton).defaultValue !== undefined) {
                                        defaultCorpusHeight = (childOption as IOptionRadioButton).defaultValue;
                                    }
                                    for (radioButtonItem of (childOption as IOptionRadioButton).items) {
                                        if (createObject.availableHeights && !createObject.availableHeights.includes(radioButtonItem.id)) {
                                            continue;
                                        }
                                        corpusHeights.push(radioButtonItem.id);
                                    }
                                    if (defaultCorpusHeight && !corpusHeights.includes(defaultCorpusHeight)) {
                                        defaultCorpusHeight = corpusHeights[0];
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
        }

        return {
            heights: heights,
            defaultHeight: defaultHeight,
            corpusHeights: corpusHeights,
            defaultCorpusHeight: defaultCorpusHeight
        };
    }

    public static calculateCreateObjectDepths(createObject: ICreateObjectData): ICreateObjectDataDepths {
        let option: IOption | IOptionGroup;
        let childOption: IOption;
        let selectItem: TSelectItem;
        let radioButtonItem: TSelectItem;
        let depths: string[] = [];
        let defaultDepth: string | undefined;
        let corpusDepths: string[] = [];
        let defaultCorpusDepth: string | undefined;

        for (option of createObject.options) {
            if (option.id === 'sizes') {
                if ((option as IOption).type === OPTION_TYPE_JSON) {
                    defaultDepth = (option as IOption).value.depth;
                } else {
                    for (childOption of (option as IOptionGroup).options) {
                        if (childOption.id === 'depth') {
                            switch ((childOption as IOption).type) {
                                case OPTION_TYPE_HIDDEN_NUMBER:
                                    defaultDepth = '' + (childOption as IOptionHiddenNumber).value;
                                    break;
                                case OPTION_TYPE_NUMBER:
                                case OPTION_TYPE_RANGE:
                                    defaultDepth = '' + (childOption as IOptionNumber).value;
                                    break;
                                case OPTION_TYPE_SELECT:
                                    if ((childOption as IOptionSelect).defaultValue !== undefined) {
                                        defaultDepth = (childOption as IOptionSelect).defaultValue;
                                    }
                                    for (selectItem of (childOption as IOptionSelect).items) {
                                        depths.push(selectItem.id);
                                    }
                                    break;
                                case OPTION_TYPE_RADIOBUTTON:
                                    if ((childOption as IOptionRadioButton).defaultValue !== undefined) {
                                        defaultDepth = (childOption as IOptionRadioButton).defaultValue;
                                    }
                                    for (radioButtonItem of (childOption as IOptionRadioButton).items) {
                                        depths.push(radioButtonItem.id);
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
            if (option.id === 'corpus') {
                if ((option as IOption).type === OPTION_TYPE_JSON) {
                    defaultCorpusDepth = (option as IOption).value.height;
                } else {
                    for (childOption of (option as IOptionGroup).options) {
                        if (childOption.id === 'depth') {
                            switch ((childOption as IOption).type) {
                                case OPTION_TYPE_HIDDEN_NUMBER:
                                    defaultCorpusDepth = '' + (childOption as IOptionHiddenNumber).value;
                                    break;
                                case OPTION_TYPE_NUMBER:
                                    defaultCorpusDepth = '' + (childOption as IOptionNumber).value;
                                    break;
                                case OPTION_TYPE_SELECT:
                                    if ((childOption as IOptionSelect).defaultValue !== undefined) {
                                        defaultCorpusDepth = (childOption as IOptionSelect).defaultValue;
                                    }
                                    for (selectItem of (childOption as IOptionSelect).items) {
                                        corpusDepths.push(selectItem.id);
                                    }
                                    break;
                                case OPTION_TYPE_RADIOBUTTON:
                                    if ((childOption as IOptionRadioButton).defaultValue !== undefined) {
                                        defaultCorpusDepth = (childOption as IOptionRadioButton).defaultValue;
                                    }
                                    for (radioButtonItem of (childOption as IOptionRadioButton).items) {
                                        corpusDepths.push(radioButtonItem.id);
                                    }
                                    break;
                            }
                        }
                    }
                }
            }
        }

        return {
            depths: depths,
            defaultDepth: defaultDepth,
            corpusDepths: corpusDepths,
            defaultCorpusDepth: defaultCorpusDepth
        };
    }

    public static calculateCorpusSizes(createOptions: any): TSizes {
        let unitSizes: TSizes | undefined;
        let corpusSizes: TSizes | undefined;

        if (createOptions.corpus &&
            createOptions.corpus.width &&
            createOptions.corpus.height &&
            createOptions.corpus.depth) {
            corpusSizes = {
                length: +createOptions.corpus.width,
                height: +createOptions.corpus.height,
                width: +createOptions.corpus.depth
            }
        }
        if (createOptions.sizes &&
            createOptions.sizes.width &&
            createOptions.sizes.height &&
            createOptions.sizes.depth) {
            unitSizes = {
                length: +createOptions.sizes.width,
                height: +createOptions.sizes.height,
                width: +createOptions.sizes.depth
            }
        }

        if (corpusSizes &&
            !isNaN(corpusSizes.length) &&
            !isNaN(corpusSizes.width) &&
            !isNaN(corpusSizes.height)) {
            return corpusSizes;
        }
        if (unitSizes &&
            !isNaN(unitSizes.length) &&
            !isNaN(unitSizes.width) &&
            !isNaN(unitSizes.height)) {
            return unitSizes;
        }
        throw new Error('error-DataManage-calculateCorpusSizes');
    }
}