import React, { useState } from 'react'
import { INewHandleModelData, initialErrors, initialModel } from '../defaultValues'
import { Button } from '../../../../components/Button'
import HadleModel from './HandleModel'
import { cnFormHandle, INewHandleData } from '../FormHandle'
import ModalHandleModel from './ModalHandleModel'
import { THandleModelType } from 'common-code/lib/types/THandleModelType'
import { TModelExtension } from '../../../../../../common-code/types/TModelExtension'
import { saveModel3D } from '../../api/MaterialServices'
import { useDispatch } from 'react-redux'
import { HIDE_LOADING, SHOW_LOADING } from '../../../../../constants'
import { IHandleModelData } from '../../../../../../common-code/interfaces/materials/IHandleModelData'
import { transliterate } from '../../utils/transliterate'
import { BUTTON_LEFT_ICON_PLUS_2, BUTTON_MODE_LIGHT_GREEN } from '../../../../constants'

export const validateHandleModel = (
    newHandleModel: INewHandleModelData,
    newHandle: INewHandleData
) => {
    const { width, height, depth, type } = newHandleModel
    const { id, title } = newHandle
    if (!id && !title) return 'Укажите название ручки'
    if (!width || width === '0') return 'Укажите ширину'
    if (!height || height === '0') return 'Укажите высоту'
    if (!depth || depth === '0') return 'Укажите глубину'
    if (!type) return 'Укажите тип'
    return ''
}

export const createHandleModel = (newHandleModel: INewHandleModelData) => {
    const { width, height, depth, type, ext } = newHandleModel
    return {
        id: `${type}_${width}-${depth}-${height}`,
        width: Number(width),
        height: Number(height),
        depth: Number(depth),
        type: type as THandleModelType,
        ext: ext as TModelExtension,
    }
}

const HandleModels = ({
    newHandle,
    setNewHandle,
    setErrors
}: {
    newHandle: INewHandleData
    setNewHandle: (newHandle: INewHandleData) => void
    setErrors: (errors: any) => void
}) => {
    const dispatch = useDispatch()
    const [newHandleModel, setNewHandleModel] = useState<INewHandleModelData>(
        initialModel(null)
    )
    const [modal, setModal] = React.useState<boolean>(false)

    const { models, id, title } = newHandle

    const handleToggleModal = () => {
        if (!title) {
            setErrors({ title: 'Укажите название ручки' })
            return
        }
        if (title.length < 5) {
            setErrors({ title: 'Минимальная длина названия ручки 5 символов' })
            return 
        }
        setErrors(initialErrors)
        setModal(!modal)
    }
    const saveModelToServer = async (modelNew: IHandleModelData) => {
        if (!newHandleModel.file) return
        dispatch({ type: SHOW_LOADING })
        // для новых моделей генерируем id из названия ручки
        const idHandle = id ? id : transliterate(title)

        const modelData = {
            path: `/static-files/handle/${idHandle}`,
            name: `${modelNew.id}.glb`,
            file: newHandleModel.file,
        }
        try {
            return await saveModel3D(modelData)
        } finally {
            dispatch({ type: HIDE_LOADING })
        }
    }

    const handleSubmit = async () => {
        const error = validateHandleModel(newHandleModel, newHandle)
        if (error) {
            setNewHandleModel({ ...newHandleModel, error })
            return
        }
        // создаем модель
        const modelNew = createHandleModel(newHandleModel)
        // добавляем проверку на уникальность
        const isExists = models.some(
            (model) => model.id === modelNew.id
        )
        if (isExists) {
            setNewHandleModel({ ...newHandleModel, error: 'Модель с такими параметрами уже существует' })
            return
        }
        // сохраняем модель на сервер
        const isSaved = newHandleModel.file
            ? await saveModelToServer(modelNew)
            : true // если нет файла, считаем что модель сохранена
        // если сохранена или нет файла, добавляем модель в handle
        if (isSaved) {
            setNewHandle({
                ...newHandle,
                models: [...models, modelNew],
            })
            setNewHandleModel(initialModel(null))
            setModal(false)
        } else {
            setNewHandleModel({
                ...newHandleModel,
                error: 'Произошла ошибка при сохранении модели',
            })
        }
    }
    return (
        <>
            <h4 className={cnFormHandle('title')}>Модели ручек</h4>
            {models.map((model, index) => (
                <HadleModel
                    model={model}
                    key={`${index}${model.id}`}
                    index={index}
                    setNewHandle={setNewHandle}
                    newHandle={newHandle}
                />
            ))}

            <ModalHandleModel
                newHandle={newHandle}
                modal={modal}
                handleToggleModal={handleToggleModal}
                newHandleModel={newHandleModel}
                handleSubmit={handleSubmit}
                setNewHandleModel={setNewHandleModel}
                keyValue={`#addShelf`}
            />

            <Button
                className={cnFormHandle('Button')}
                type={'button'}
                text={`Добавить модель ручки`}
                leftIcon={BUTTON_LEFT_ICON_PLUS_2}
                mods={BUTTON_MODE_LIGHT_GREEN}
                callBack={handleToggleModal}
            />
        </>
    )
}

export default HandleModels
