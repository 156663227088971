import React, { useEffect } from 'react'
import {
    HANDLE_MODEL_TYPE_DEFAULT,
    HANDLE_MODEL_TYPE_DEFAULT_V2,
    HANDLE_MODEL_TYPE_SMALL,
    MODEL_EXTENSION_GLB,
} from 'common-code/lib/constants'
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle'
import { cnModal, Modal } from '../../../../components/Modal'
import { cnCreateForm } from '../../FormModule/FormModule'
import { Button } from '../../../../components/Button'
import { INewHandleModelData } from '../defaultValues'
import { cnFormHandle, INewHandleData } from '../FormHandle'
import { Select } from '../../../../components/Select/Select'
import { ThreeCanvas } from '../../../../components/ThreeCanvas/ThreeCanvas'
import { useGltfLoader } from '../../../../../hooks/useLoadModel'
import { transliterate } from '../../utils/transliterate'
import { BUTTON_LEFT_ICON_PLUS_2, BUTTON_MODE_LIGHT_GREEN } from '../../../../constants'

const optionsType = [
    { value: HANDLE_MODEL_TYPE_DEFAULT, content: HANDLE_MODEL_TYPE_DEFAULT },
    {
        value: HANDLE_MODEL_TYPE_DEFAULT_V2,
        content: HANDLE_MODEL_TYPE_DEFAULT_V2,
    },
    { value: HANDLE_MODEL_TYPE_SMALL, content: HANDLE_MODEL_TYPE_SMALL },
]
const optionsExt = [
    { value: MODEL_EXTENSION_GLB, content: MODEL_EXTENSION_GLB },
]

interface IShelfProps {
    newHandle: INewHandleData
    modal: boolean
    handleToggleModal: () => void
    keyValue: string
    handleSubmit: () => void
    newHandleModel: INewHandleModelData
    setNewHandleModel: (newHandleModel: INewHandleModelData) => void
}

function ModalHandleModel({
    newHandle,
    modal,
    handleToggleModal,
    newHandleModel,
    handleSubmit,
    setNewHandleModel,
    keyValue,
}: IShelfProps) {
    const onInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setNewHandleModel({
            ...newHandleModel,
            [e.target.name]: e.target.value,
        })
    }

    const onFocus = () => setNewHandleModel({ ...newHandleModel, error: '' })
    const { loadGltfModel } = useGltfLoader()

    const { width, height, depth, type, ext, error, object3D } = newHandleModel

    const edit = keyValue.includes('edit')

    const handleUploadModel = () => {
        let file: File | undefined
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.gltf,.glb'
        input.onchange = (event) => {
            if (event.target instanceof HTMLInputElement) {
                file = event.target.files?.[0]
            }
            loadGltfModel(file)
                .then((gltfModel) => {
                    setNewHandleModel({
                        ...newHandleModel,
                        object3D: gltfModel,
                        file: file
                    })
                })
                .catch((err) => {})
        }
        input.click()
    }

    const handleClearModel = () => {
        setNewHandleModel({ ...newHandleModel, object3D: undefined, file: undefined })
    }

    useEffect(() => {
        if (!newHandleModel.object3D && newHandleModel.id) {
            const serverFolderName = newHandle.id ? newHandle.id : transliterate(newHandle.title)
            loadGltfModel(
                `/static-files/handle/${serverFolderName}/${newHandleModel.id}.glb`
            )
                .then((object3D) => {
                    setNewHandleModel({
                        ...newHandleModel,
                        object3D,
                    })
                })
                .catch((err) => {})
        }
        // eslint-disable-next-line
    }, [newHandleModel])

    return (
        <Modal
            className={cnFormHandle(cnModal())}
            visible={modal}
            callBack={handleToggleModal}
            key={keyValue}
        >
            <h4 className={cnFormHandle('Header')}>
                {edit
                    ? 'Редактирование модели ручки'
                    : 'Добавление модели ручки'}
            </h4>

            <div className={cnFormHandle('Content')}>
                <div>
                    <label htmlFor="width">Ширина модели ручки</label>
                    <Textinput
                        className={cnFormHandle('Textinput')}
                        value={width}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="width"
                        type="number"
                        maxLength={3}
                        onFocus={onFocus}
                    />
                </div>
                <div>
                    <label htmlFor="height">Высота модели ручки</label>
                    <Textinput
                        className={cnFormHandle('Textinput')}
                        value={height}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="height"
                        type="number"
                        maxLength={3}
                        onFocus={onFocus}
                    />
                </div>
                <div>
                    <label htmlFor="depth">Глубина модели ручки</label>
                    <Textinput
                        className={cnFormHandle('Textinput')}
                        value={depth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="depth"
                        type="number"
                        maxLength={3}
                        onFocus={onFocus}
                    />
                </div>
                <label htmlFor="type">Тип ручки</label>
                <Select
                    className={cnFormHandle('Select')}
                    placeholder={'Выберите значение'}
                    showPlaceholder={false}
                    options={optionsType}
                    value={type}
                    callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        onInputChange({
                            ...e,
                            target: { ...e.target, name: 'type' },
                        })
                    }
                />
                <label htmlFor="ext">Расширение для файла</label>
                <Select
                    className={cnFormHandle('Select')}
                    placeholder={'Выберите значение'}
                    showPlaceholder={false}
                    options={optionsExt}
                    value={ext}
                    callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        onInputChange({
                            ...e,
                            target: { ...e.target, name: 'ext' },
                        })
                    }
                />

                {error && <div className="text-danger">{error}</div>}
            </div>

            <div className={cnFormHandle('Form-Group')}>
                <Button
                    className={cnCreateForm('Button')}
                    type={'button'}
                    text={object3D ? 'Удалить 3D-модель' : 'Добавить 3D-модель'}
                    leftIcon={
                        object3D
                            ? undefined
                            : BUTTON_LEFT_ICON_PLUS_2
                    }
                    mods={BUTTON_MODE_LIGHT_GREEN}
                    callBack={object3D ? handleClearModel : handleUploadModel}
                />

                {object3D && (
                    <ThreeCanvas
                        model3D={object3D}
                    />
                )}

                <Button
                    className={cnCreateForm('Button')}
                    type={'button'}
                    text={'Сохранить'}
                    leftIcon={BUTTON_LEFT_ICON_PLUS_2}
                    mods={BUTTON_MODE_LIGHT_GREEN}
                    callBack={handleSubmit}
                />
            </div>
        </Modal>
    )
}

export default ModalHandleModel
