import React from 'react'
import { cnIntegratedHandles } from './IntegratedHandles'
import Edit from '../../../../../components/icons/Edit'
import Trash from '../../../../../components/icons/Trash'
import ModalIntegratedHandles from './ModalIntegratedHandles'
import {
    createIntegratedHandle,
    IIntegratedHandle,
    initionalIntegratedHandle,
    validateIntegratedHandle,
} from './initionalIntegratedHandle'
import { ISaveIntegratedHandleData } from 'common-code/lib/interfaces/saveData/ISaveIntegratedHandleData'

interface IntegratedHandleProps {
    arrIntegratedHandles: ISaveIntegratedHandleData[]
    setArrIntegratedHandles: (arr: ISaveIntegratedHandleData[]) => void
    handle: ISaveIntegratedHandleData
    index: number
}

function IntegratedHandle({
    arrIntegratedHandles,
    setArrIntegratedHandles,
    handle,
    index,
}: IntegratedHandleProps) {
    const [modal, setModal] = React.useState<boolean>(false)
    const [newIntegratedHandle, setNewIntegratedHandle] =
        React.useState<IIntegratedHandle>(initionalIntegratedHandle(handle))

    const handleToggleModal = () => setModal((prev) => !prev)

    const handleDeleteIntegratedHandle = () =>
        setArrIntegratedHandles(
            arrIntegratedHandles.filter((_, i) => i !== index)
        )

    const handleEditIntegratedHandle = () => {
        const error = validateIntegratedHandle(newIntegratedHandle)
        if (error) {
            setNewIntegratedHandle({ ...newIntegratedHandle, error })
            return
        }
        const updatedHandle = createIntegratedHandle(newIntegratedHandle)
        setArrIntegratedHandles(
            arrIntegratedHandles.map((el, i) => (i === index ? updatedHandle : el))
        )
        setModal(!modal)
    }

    return (
        <div className={cnIntegratedHandles('Children')}>
            <span>Интегрированные ручки</span>
            <span>Тип ручки: {handle.functionalType}</span>
            <div className={cnIntegratedHandles('Box-Btn')}>
                <Edit handleToggleModal={handleToggleModal} />
                <Trash handleDeleteValue={handleDeleteIntegratedHandle} />
            </div>

            <ModalIntegratedHandles
                modal={modal}
                handleToggleModal={handleToggleModal}
                newIntegratedHandle={newIntegratedHandle}
                setNewIntegratedHandle={setNewIntegratedHandle}
                handleSubmit={handleEditIntegratedHandle}
                keyValue={`#editIntegratedHandle-${index}`}
            />
        </div>
    )
}

export default IntegratedHandle
