import React from 'react'
import {
    createHandleNew,
    defaultValues,
    initialErrors,
    validateHandle,
} from './defaultValues'
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle'
import { Tumbler } from '../../../components/Tumbler/Tumbler'
import { Button, cnButton } from '../../../components/Button'
import { cn } from '@bem-react/classname'
import { IHandleData } from '../../../../../common-code/interfaces/materials/IHandleData'
import HandleModels from './HandleModels/HandleModels'
import InputFile from '../../../components/InputFile/InputFile'
import Trash from '../../../components/icons/Trash'
import './FormHandle.css'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux/AppStore'
import {
    BUTTON_LEFT_ICON_PLUS_2,
    BUTTON_MODE_LIGHT_GREEN,
} from '../../../constants'

export const cnFormHandle = cn('FormHandle')

export interface INewHandleData extends IHandleData {
    imageNew?: File | null
}
export interface IFormHandleProps {
    saveHandlesCollection: (itemHandle: INewHandleData) => Promise<void>
    editHandlesCollection: (itemHandle: INewHandleData) => Promise<void>
}
function FormHandle({
    saveHandlesCollection,
    editHandlesCollection,
}: IFormHandleProps) {
    const initialValue = useSelector(
        (state: AppState) => state.wizard.editMaterial.itemHandle
    )
    const [newHandle, setNewHandle] = React.useState<INewHandleData>(
        defaultValues(initialValue || null)
    )

    const formRef = React.useRef<HTMLFormElement>(null)

    const scrollToForm = () =>
        formRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })

    const {
        id,
        //обязательные
        title,
        models,
        //необязательные
        image,
        isDefault,
        imageNew,
    } = newHandle

    const [errors, setErrors] = React.useState(initialErrors)

    const onInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setNewHandle({ ...newHandle, [e.target.name]: e.target.value })
    }

    const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewHandle({ ...newHandle, [e.target.name]: e.target.checked })
    }

    const onFocus = () => {
        setNewHandle({ ...newHandle })
        setErrors(initialErrors)
    }
    const handleSubmit = () => {
        const error = validateHandle(newHandle)
        if (error) {
            scrollToForm()
            setErrors({ ...errors, title: error })
            return
        }
        const handle = createHandleNew(newHandle)
        console.log('handle', handle)
        return handle
    }

    const addFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewHandle({
            ...newHandle,
            imageNew: Array.from(e.target.files as FileList)[0],
            image: '',
        })
        setErrors(initialErrors)
    }

    const filterFunction = () => {
        setNewHandle({ ...newHandle, imageNew: null, image: '' })
        setErrors(initialErrors)
    }
    // функция удаления выбранного фото
    const deleteImage = () => {
        setNewHandle({ ...newHandle, image: '' })
    }
    const saveHandle = () => {
        const handle = handleSubmit()
        if (handle) {
            saveHandlesCollection(handle)
        }
    }
    const editHandle = () => {
        const handle = handleSubmit()
        if (handle) {
            editHandlesCollection(handle)
        }
    }

    const edit = !!initialValue

    return (
        <div className={cnFormHandle('')}>
            <div className={cnFormHandle('Container')}>
                <h3 className={cnFormHandle('Header')}>
                    {edit ? 'Редактирование ручек' : 'Добавление ручек'}
                </h3>

                <form className={cnFormHandle('Content')} ref={formRef}>
                    {edit && (
                        <p className={cnFormHandle('Id')}>
                            ID ручек: &nbsp; {id}
                        </p>
                    )}
                    <label htmlFor="title">Название ручек *</label>

                    {models.length > 0 ? (
                        <div>
                            <p>{title}</p>
                            <p className={cnFormHandle('Explanations')}>
                                ( изменить название ручки после добавления
                                моделей невозможно )
                            </p>
                        </div>
                    ) : (
                        <Textinput
                            className={cnFormHandle('Textinput')}
                            value={title}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => onInputChange(e)}
                            name="title"
                            type="text"
                            maxLength={50}
                            onFocus={onFocus}
                        />
                    )}
                    {errors.title && (
                        <p className="text-danger">{errors.title}</p>
                    )}

                    <HandleModels
                        newHandle={newHandle}
                        setNewHandle={setNewHandle}
                        setErrors={setErrors}
                    />
                    <h5>Изображение ручек</h5>

                    {image && !imageNew && (
                        <div className={cnFormHandle('Children')}>
                            <span>{image.split('/').at(-1) as string}</span>
                            <img width={50} height={50} src={image} alt="..." />
                            <div className={cnFormHandle('Box-Btn')}>
                                <Trash handleDeleteValue={deleteImage} />
                            </div>
                        </div>
                    )}
                    <InputFile
                        file={imageNew as File | null}
                        addFiles={addFiles}
                        filterFunction={filterFunction}
                    />

                    <Tumbler
                        className={cnFormHandle('Tumbler')}
                        checked={isDefault as boolean}
                        callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChecked(e)
                        }
                        label="Определить как ручка по умолчанию"
                        name="isDefault"
                    />

                    <div className={cnFormHandle('Footer')}>
                        <div className={cnFormHandle('Footer-Button-Add')}>
                            <Button
                                className={cnFormHandle(cnButton())}
                                type={'button'}
                                text={'Добавить новую ручку в коллекцию'}
                                leftIcon={BUTTON_LEFT_ICON_PLUS_2}
                                mods={BUTTON_MODE_LIGHT_GREEN}
                                callBack={saveHandle}
                            />
                            {edit && (
                                <Button
                                    className={cnFormHandle(cnButton())}
                                    type={'button'}
                                    text={
                                        'Заменить редактируемую ручку в коллекции'
                                    }
                                    mods={BUTTON_MODE_LIGHT_GREEN}
                                    callBack={editHandle}
                                />
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FormHandle
