
const No = () => {
    return (
        <svg
            width="24"
            height="24"
            id="Layer_1"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipRule="evenodd" fillRule="evenodd">
                <circle
                    cx="256"
                    cy="256"
                    fill="#FF0015"
                    r="225"
                    // style="fill: rgb(255, 0, 21);"
                ></circle>
                <path
                    d="m343.832 185.85-70.144 70.151 70.144 70.149c4.88 4.881 4.88 12.789 0 17.681-2.44 2.439-5.63 3.66-8.821 3.66-3.238 0-6.428-1.221-8.868-3.66l-70.143-70.15-70.144 70.151c-4.88 4.878-12.809 4.878-17.688 0-4.88-4.892-4.88-12.8 0-17.681l70.144-70.149-70.144-70.151c-4.88-4.878-4.88-12.789 0-17.679 4.88-4.881 12.809-4.881 17.688 0l70.144 70.149 70.144-70.149c4.879-4.881 12.809-4.881 17.688 0 4.88 4.889 4.88 12.8 0 17.678zm-87.832 282.65c117.157 0 212.496-95.33 212.496-212.499 0-117.171-95.339-212.501-212.496-212.501s-212.496 95.331-212.496 212.502c0 117.169 95.339 212.498 212.496 212.498zm167.923-380.439c-44.855-44.86-104.489-69.562-167.923-69.562s-123.068 24.702-167.923 69.562c-44.854 44.859-69.58 104.499-69.58 167.941 0 63.44 24.726 123.08 69.581 167.938 44.855 44.861 104.488 69.561 167.923 69.561 63.434 0 123.068-24.7 167.923-69.561 44.901-44.859 69.581-104.499 69.581-167.938-.001-63.442-24.681-123.082-69.582-167.941z"
                    fill="#FFFFFF"
                ></path>
            </g>
        </svg>
    )
}

export default No
