const Yes = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="30"
                cy="30"
                fill="#32AE35"
                r="29"
            ></circle>
            <path
                d="m24.262 42.07-6.8-6.642a1.534 1.534 0 0 1 0-2.2l2.255-2.2a1.621 1.621 0 0 1 2.256 0l4.048 3.957 11.353-17.26a1.617 1.617 0 0 1 2.2-.468l2.684 1.686a1.537 1.537 0 0 1 .479 2.154l-13.443 20.444a3.3 3.3 0 0 1 -5.032.529z"
                fill="#fff"
            ></path>
        </svg>
    )
}

export default Yes
