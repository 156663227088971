import {BufferGeometry, CylinderGeometry, Mesh} from 'three';
import {ThreeLeg} from "../ThreeLeg";
import {ThreeKUnit} from "../../../units/ThreeKUnit/ThreeKUnit";
import {ISaveLegNormalData} from '../../../../../../../common-code/interfaces/saveData/ISaveLegNormalData';

export class ThreeLegNormal extends ThreeLeg {
    saveData: ISaveLegNormalData;
    unit: ThreeKUnit;

    constructor(options: ISaveLegNormalData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getGeometryType(): string {
        return 'normal';
    }

    protected createBody() {
        let top: Mesh;
        let middle: Mesh;
        let bottom: Mesh;

        top = this.createCylinder(24, 20);
        top.position.y = -10;
        top.name = 'topLeg';
        top.matrixAutoUpdate = false;
        this.view3d.add(top);
        middle = this.createCylinder(12, this.getHeight() - 40);
        middle.position.y = -this.getHeight()/2;
        middle.name = 'middleLeg';
        middle.matrixAutoUpdate = false;
        this.view3d.add(middle);
        bottom = this.createCylinder(24, 20);
        bottom.position.y = -this.getHeight() + 10;
        bottom.name = 'bottomLeg';
        bottom.matrixAutoUpdate = false;
        this.view3d.add(bottom);
    }

    protected createCylinder(width: number, height: number) {
        let geometry: BufferGeometry;
        geometry = new CylinderGeometry(width, width, height, 50, 1);

        let cylinder = new Mesh(geometry, this.bodyMaterial);
        cylinder.receiveShadow = true;

        return cylinder;
    }
}