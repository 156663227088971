import {ThreeFacadeUnit} from '../ThreeFacadeUnit';
import {SIDE_TYPE_BOTTOM, SIDE_TYPE_LEFT, SIDE_TYPE_TOP} from '../../../../../../../common-code/constants';
import {TSizes} from '../../../../../../../common-code/types/geometry/TSizes';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {TPlaneType} from '../../../../../types/TPlaneType';
import {PLANE_TYPE_HORIZONTAL, PLANE_TYPE_VERTICAL, PLANE_TYPE_WALL} from '../../../../../../constants';
import {Vector3} from 'three';
export class ThreeSideTopFacadeUnit extends ThreeFacadeUnit {

    public isEndUnit(): boolean {
        return true;
    }

    public defaultYPosition(): number {
        let topFirstYPosition: number;
        let yPosition: number;

        if (this.saveData.initPosition && this.saveData.initPosition.y !== undefined &&
            !isNaN(+this.saveData.initPosition.y)) {
            return this.getSideDistance(SIDE_TYPE_BOTTOM) + +this.saveData.initPosition.y;
        }
        topFirstYPosition = this.service.getTopFirstYPosition();
        if (this.getHeight() >= this.service.getTopUnitHeight()) {
            yPosition = this.getSideDistance(SIDE_TYPE_BOTTOM) + topFirstYPosition;
        } else {
            yPosition = this.service.getInstallTopUnitHeight() - this.getSideDistance(SIDE_TYPE_TOP);
        }

        // TODO: Правильное позиционирование боковых панелей, в зависимости от коллекции
        if(this.service.getCollectionId()==="43") {
            if(yPosition + this.getHeight()/2 >= this.service.getRoom().getHeight()) {
                yPosition = this.getSideDistance(SIDE_TYPE_BOTTOM) + 100
            }
        }

        // Если фасад выше верхней части помещения то устанавливаем его на низ
        if(yPosition + this.getHeight()/2 >= this.service.getRoom().getHeight()) {
            yPosition = this.getSideDistance(SIDE_TYPE_BOTTOM)
        }
        
        return yPosition;
    }

    public getCorpusSizes(): TSizes {
        return {
            length: this.getDepth(),
            height: this.getHeight(),
            width: this.getWidth()
        };
    }

    protected getIntersectsTypes(): TPlaneType[] {
        return [
            PLANE_TYPE_WALL,
            PLANE_TYPE_VERTICAL,
            PLANE_TYPE_HORIZONTAL,
        ];
    }

    protected correctMovePosition(movePosition: Vector3) {
        this.correctTopUnitMovePosition(movePosition);
    }

    protected calculateInitFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        let facadeData: ISaveFacadeData;
        let direction: number;

        direction = this.getSideType() === SIDE_TYPE_LEFT ? -1 : 1;
        if (facades) {
            for (facadeData of facades) {
                facadeData.rotation = {
                    y: direction * Math.PI/2
                }
                facadeData.margin = {
                    x: -direction * this.getWidth()/2,
                    y: 0,
                    z: 0
                }
                facadeData.notTransparent = true
            }
        }
        return facades;
    }

}