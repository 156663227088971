import { ITextureData } from "common-code/lib/interfaces/materials/ITextureData";
import { cnFormPlinth,INewPlinthData } from "../FormPlinth";
import { INewTexture, INewTextureData } from "./Textures";
import React from "react";
import { TTextureExtension } from "common-code/lib/types/TTextureExtension";
import { TTextureType } from "common-code/lib/types/TTextureType";
import Edit from "../../../../components/icons/Edit";
import Trash from "../../../../components/icons/Trash";
import ModalTexture from "../../FormMaterial/Textures/ModalTexture";

function Texture({
    texture, 
    index, 
    setNewPlinth, 
    newPlinth,
    createTextureNew,
    validateTexture
  } : {
    texture: ITextureData, 
    index: number, 
    setNewPlinth: (value: INewPlinthData) => void, 
    newPlinth: INewPlinthData,
    createTextureNew: (value: INewTexture) => INewTextureData,
    validateTexture: (value: INewTexture) => string
  }) {

    const deleteTexture = () =>setNewPlinth({...newPlinth, textures: newPlinth?.textures?.filter((el:ITextureData, i: number) => i !== index)});

    const initionalTexture: INewTexture  = {

      path: texture.path,
      type: texture.type,
      repeatX: texture.repeat.x.toString(),
      repeatY: texture.repeat.y.toString(),
      file: texture.file,

      fileNew: null,

      offset: !!texture.offset?.x || !!texture.offset?.y,
      offsetX: texture.offset?.x ? texture.offset?.x.toString() : "0",
      offsetY: texture.offset?.y ? texture.offset?.y.toString() : "0",

      sizes: !!texture.sizes?.width || !!texture.sizes?.height,
      sizesWidth: texture.sizes?.width ? texture.sizes?.width.toString() : "0",
      sizesHeight: texture.sizes?.height ? texture.sizes?.height.toString() : "0",

      visible: false,
      error: "",
      url: ''
    }

    const [newTexture, setNewTexture] = React.useState (initionalTexture);
    const handleToggleModal = () => setNewTexture({ ...newTexture, visible: !newTexture.visible });

    const createTexture = ({path, type, repeatX, repeatY, offset, offsetX, offsetY, sizes, sizesWidth, sizesHeight, fileNew } : 
      {path: string, type: string, repeatX: string, repeatY: string, offset: boolean, offsetX: string, offsetY: string, sizes: boolean, sizesWidth: string, sizesHeight: string, fileNew: File | null}) => {
  
      const textureCurrent: ITextureData = {
          path,
          extension: path.split('.').at(-1) as TTextureExtension,
          file: path.split('/').at(-1) as string,
          
          type: type as TTextureType,
  
          repeat: {
              x: Number( repeatX ),
              y: Number( repeatY )
          }
      }
  
      if(offset) {
          textureCurrent.offset = {
              x: Number(offsetX),
              y: Number(offsetY)
          }
      }
      if(sizes) {
          textureCurrent.sizes = {
              width: Number(sizesWidth),
              height: Number(sizesHeight)
          }
      }
      return textureCurrent
  
    }

    const handleSubmit = () => {
      const error = validateTexture(newTexture);
      if (error) {
        setNewTexture({ ...newTexture, error});
          return;
      }
      if(newTexture.fileNew) {
        const textureNew = createTextureNew(newTexture);
        setNewPlinth({ ...newPlinth, texturesNew: [...(newPlinth.texturesNew as INewTextureData[]), textureNew], textures: newPlinth.textures?.filter((el:ITextureData, i: number) => i !== index) });
        setNewTexture(initionalTexture);
        return;
      } else {
        const texture = createTexture(newTexture);
        setNewPlinth({ ...newPlinth, textures: newPlinth.textures?.map((el:ITextureData, i: number) => i === index ? texture : el) });
        setNewTexture(initionalTexture);
        return;
      }
    }

    return (
      <div className={cnFormPlinth('Children')} >
          <span>{texture.file}</span> 
          <img width={50} height={50}  src={texture.path} alt={texture.file} />
          <div className={cnFormPlinth('Box-Btn')}>
              <Edit handleToggleModal={handleToggleModal} />
              <Trash handleDeleteValue={deleteTexture} /> 
          </div>
  
          {<ModalTexture 
              handleToggleModal={handleToggleModal}
              setNewTexture={setNewTexture}
              newTexture={newTexture}
              handleSubmit={handleSubmit}
              keyValue="editTexture"
          />
   }
      </div>
    )






  }
  
  export default Texture