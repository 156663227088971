import React, { useEffect, useRef } from 'react';
import { ThreeSceneManager } from '../../../3d/testCanvas/ThreeSceneManager';
import { Group, Mesh } from 'three';

type ThreeCanvasProps = {
  onSceneReady?: (sceneManager: ThreeSceneManager) => void;
  model3D: Group | undefined
};

export const ThreeCanvas: React.FC<ThreeCanvasProps> = ({ onSceneReady, model3D}) => {

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const sceneManagerRef = useRef<ThreeSceneManager | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      sceneManagerRef.current = new ThreeSceneManager(canvasRef.current);

      const mesh = model3D?.children[0] as Mesh;
      const boundingBox = mesh?.geometry.boundingBox;

      // масштабирование модели
      if (boundingBox && canvasRef.current) {
        const canvasAspect = canvasRef.current.clientWidth / canvasRef.current.clientHeight;
        const maxSize = Math.max(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z);
        const scale = (canvasAspect * 2) / maxSize;
        model3D?.scale.set(scale, scale, scale);
      }

      sceneManagerRef.current.animate();
      sceneManagerRef.current.addToScene(model3D as Group);
    

      if (onSceneReady) {
        onSceneReady(sceneManagerRef.current);
      }
    
      const handleResize = () => sceneManagerRef.current?.resize();
      window.addEventListener('resize', handleResize);

      return () => {
        sceneManagerRef.current?.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
    // eslint-disable-next-line
  }, [onSceneReady]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        display: 'block',
        width: '100%', 
        height: 'calc(100% - 40px)', 
        margin: '20px 0',
      }}
    />
  );
};


