import {ThreeTopUnit} from "../ThreeTopUnit";
import {ThreeTopAngleDoubleCorpus} from "../../../details/ThreeCorpus/types/ThreeTopAngleDoubleCorpus";
import {KitchenService} from "../../../../../services/KitchenService/KitchenService";
import {
    ISaveTopUnitAngleDoubleData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleDoubleData';
import {
    ISaveTopAngleDoubleCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopAngleDoubleCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitAngleDouble extends ThreeTopUnit {
    corpus: ThreeTopAngleDoubleCorpus;
    saveData: ISaveTopUnitAngleDoubleData;

    constructor(options: ISaveTopUnitAngleDoubleData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isAngleUnit(): boolean {
        return true;
    }

    protected initCorpus(corpusData: ISaveTopAngleDoubleCorpusData): ThreeTopAngleDoubleCorpus {
        return new ThreeTopAngleDoubleCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    // не работает, похоже на устраревшую логику

    // protected createFacades() {
    //     console.log('createFacades');
    //     let facadeData: ISaveFacadeData;
    //     let facade: ThreeFacade | undefined;

    //     this.saveData.facades = this.calculateInitFacadesData(this.saveData.facades);

    //     if (!this.saveData.facades) {
    //         return;
    //     }
    //     this.facades = [];
    //     for (facadeData of this.saveData.facades) {
    //         switch (facadeData.geometryType) {
    //             case GEOMETRY_TYPE_SQUARE:
                    
    //                 facade = new ThreeSquareFacade(CommonHelper.deepCopy(facadeData), this);
    //         }
    //         if (!facade) {
    //             continue;
    //         }
    //         facade.initState();
    //         facade.createView();
    //         switch (facadeData.positionType) {
    //             case FACADE_POSITION_TYPE_CUBE:
    //                 facade.setPosition(new Vector3(
    //                     -this.getWidth() / 2 + this.corpus.getSideDepth() + this.corpus.getBackThickness(),
    //                     0,
    //                     this.getDepth() / 2 - +facadeData.initSizes.width / 2)
    //                 );
    //                 facade.setRotation(new Euler(0, 0.5 * Math.PI, 0));
    //                 break;
    //             default:
    //                 facade.setPosition(new Vector3(
    //                     this.getWidth() / 2 - +facadeData.initSizes.width / 2,
    //                     0,
    //                     -this.getDepth() / 2 + this.corpus.getSideDepth())
    //                 );
    //                 facade.setRotation(new Euler(0, 0, 0));
    //         }
    //         this.view3d.add(facade.view3d);
    //         this.facades.push(facade);
    //     }
    // }
}