import Trash from '../../../../../../components/icons/Trash'
import { cnCreateForm } from '../../../FormModule';
import { ISaveHingeData } from 'common-code/lib/interfaces/saveData/ISaveHingeData';
import { IFacade } from '../initialFacade';

function Hinge({hinge, index, setFacadeNew, facadeNew}: 
    {hinge: ISaveHingeData, index: number, setFacadeNew: (value: IFacade) => void, facadeNew: IFacade}) {

    const deleteHinge = () =>setFacadeNew({...facadeNew, arrHinges:facadeNew.arrHinges.filter((el:ISaveHingeData, i: number) => i !== index)});
  return (
    <div className={cnCreateForm('Children')} >
        <span>{hinge.furnitureType}</span> 
        <div className={cnCreateForm('Btn')}>
            <Trash handleDeleteValue={deleteHinge} />  
        </div>
    </div>
  )
}

export default Hinge