import { Group } from 'three'
import { IHandleData } from '../../../../../common-code/interfaces/materials/IHandleData'
import { IHandleModelData } from '../../../../../common-code/interfaces/materials/IHandleModelData'
import { INewHandleData } from './FormHandle'
import { transliterate } from '../utils/transliterate'

export const initialErrors = {
    title: '',
}

export const defaultValues = (item: IHandleData | null): INewHandleData => {
    return item
        ? {
              id: item.id,
              title: item.title,
              models: item.models ? item.models : [],
              isDefault: item.isDefault ? item.isDefault : false,
              image: item.image ? item.image : '',
              imageNew: null,
          }
        : {
              id: '',
              title: '',
              models: [],
              isDefault: false,
              image: '',
              imageNew: null,
          }
}

export interface INewHandleModelData {
    id: string
    width: string
    height: string
    depth: string
    type: string
    ext: string
    error: string
    object3D?: Group
    file?: File
}

export const initialModel = (
    item: IHandleModelData | null
): INewHandleModelData =>
    item
        ? {
              id: item.id,
              width: item.width.toString(),
              height: item.height.toString(),
              depth: item.depth.toString(),
              type: item.type ? item.type : item.id.replace(/_\d+-\d+-\d+/, ''),
              ext: item.ext,
              error: '',
          }
        : {
              id: '',
              width: '0',
              height: '0',
              depth: '0',
              type: 'default',
              ext: 'glb',
              error: '',
          }
export const createHandleNew = (newHandle: INewHandleData) => {
    const handle: INewHandleData = {
        id: transliterate(newHandle.title),
        title: newHandle.title,
        models: newHandle.models,
    }

    if (newHandle.isDefault) handle.isDefault = newHandle.isDefault
    if (newHandle.imageNew) {
        handle.imageNew = newHandle.imageNew
    } else if (newHandle.image) {
        handle.image = newHandle.image
    }

    return handle
}
export const validateHandle = (newHandle: INewHandleData) => {
    if (!newHandle.title) {
        return 'Поле название ручки не может быть пустым'
    }
    if (newHandle.title.length < 5) {
        return 'Минимальная длина названия ручки 5 символов'
    }
    if (newHandle.models.length === 0) {
        return 'Добавте модели ручек'
    }
    return ''
}
