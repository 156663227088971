import Trash from "../../../../components/icons/Trash";
import { cnInputFile } from "./InputFile";


function File({ file, filterFunction }: {file: File,filterFunction: () => void}) {

  return (
    <div className={cnInputFile('File')}>
      <>{file.name}</>
      <img width={50} height={50} key={file.name} src={URL.createObjectURL(file)} alt={file.name} />
      <Trash 
        handleDeleteValue={() => filterFunction()}
      />
    </div>
  );
}

export default File;
