import React, { useRef } from "react";
import File from './File';
import AddFile from "../icons/AddFile";
import { cn } from "@bem-react/classname";
import "./InputFile.css"

export const cnInputFile = cn('InputFile');

function InputFile({
    file, 
    addFiles, 
    filterFunction
  } : { 
    file: File | null, 
    addFiles: (e: React.ChangeEvent<HTMLInputElement>) => void, 
    filterFunction: () => void
  }) {
  
  const inputRef = useRef<HTMLInputElement>(null);

  // функции обработки click "выбор файла"
  const handleClick = () => {
    inputRef.current?.click();
  };


  return (
    <>
      <div className={cnInputFile()} onClick={handleClick}>
      
        <span>Выбрать фото</span>
        <input
          type="file"
          className={cnInputFile("Hidden")}
          accept="image/png, image/jpeg, image/jpg"
          ref={inputRef}
          onChange={(e) => addFiles(e)}
          readOnly   
        />
         <AddFile /> 
      </div>

      { file && <File file={file as File} filterFunction={filterFunction} />}
 
    </>
  );
}

export default InputFile;
