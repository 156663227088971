import React from "react";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Tumbler } from "../../../components/Tumbler/Tumbler";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select/Select";
import { cn } from "@bem-react/classname";
import { calculateTypes, defaultValues, initialErrors, levels } from "./defaultValues";
import { transliterate } from "../utils/transliterate";
import Trash from "../../../components/icons/Trash";
import InputFile from "./InputFile/InputFile";
import { IFacadeData } from "../../../../../common-code/interfaces/materials/IFacadeData";
import { Textarea} from '@yandex/ui/Textarea/Textarea@desktop';
import Handle from "./Handle/Handle";
import { IFacadeModelData } from "../../../../../common-code/interfaces/materials/IFacadeModelData";
import { IFacadeOfferData } from "../../../../../common-code/interfaces/materials/IFacadeOfferData";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import { IPlinthData } from "../../../../../common-code/interfaces/materials/IPlinthData";
import { t } from "i18next";
import "./FormFacade.css";

export interface INewFacadeData extends IFacadeData {
  imageNew?: File | null;
}

export const cnFormFacade = cn("FormFacade");

function FormFacade({
  itemFacade,
  createFacade,
}: {
  itemFacade: IFacadeData | null;
  createFacade: (material: INewFacadeData) => void;
}) {
  const plinthMaterials = useSelector((state: AppState) => state.wizard.plinths);
  
  const [newFacade, setNewFacade] = React.useState<INewFacadeData>(
    defaultValues(itemFacade)
  );

  const onInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewFacade({ ...newFacade, [e.target.name]: e.target.value });
  };

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFacade({ ...newFacade, [e.target.name]: e.target.checked });
  };

  const onFocus = () =>{
    setErrors(initialErrors);
  };

const formRef = React.useRef<HTMLFormElement>(null);
const scrollToForm = () => formRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  const {
    id,
    // обязательные поля
    title,
    depth,
    calculateType,
    image,
    models,
    offers,

    // необязательные поля 
    handle,
    level,
    isDefault,
    // color,
    imageNew,
    plinthMaterial,
    isTopFlipY,
    isBottomFlipY,
  } = newFacade;

  const [isLevel, setIsLevel] = React.useState(!!level);
  const [modelsValue, setModelsValue] = React.useState(models.length ? JSON.stringify(models, null, 2) : '');
  const [offersValue, setOffersValue] = React.useState(offers.length ? JSON.stringify(offers, null, 2) : '');
  const [isPlinthMaterial, setIsPlinthMaterial] = React.useState(!!plinthMaterial);
  const [errors, setErrors] = React.useState(initialErrors);

  const CreateFacadeNew = () => {
    const facade: INewFacadeData = {
      id:  transliterate(title),
      title: title,
      depth: Number(newFacade.depth),
      calculateType: newFacade.calculateType,
      models: modelsValue ? JSON.parse(modelsValue) as IFacadeModelData[] : [],
      offers:  offersValue ? JSON.parse(offersValue) as IFacadeOfferData[] : [],
      image: newFacade.image,
    };

    if(isDefault){
      facade.isDefault = isDefault;
    }

    if (isLevel) {
      facade.level = level;
    }

    if (imageNew) {
      facade.imageNew = imageNew;
    } else if (image) {
      facade.image = image;
    }

    if (newFacade.plinthMaterial) {
      facade.plinthMaterial = newFacade.plinthMaterial;
    }

    if (handle) {
      facade.handle = handle;
    }

    if (isTopFlipY) {
      facade.isTopFlipY = isTopFlipY;
    }
    if (isBottomFlipY) {
      facade.isBottomFlipY = isBottomFlipY;
    }

    return facade;
  };

  const validateFacade = () => {
    
    if (!title) {
      setErrors({
        ...errors,
        title: 'Заполните поле "Название материала"',
      })
      return false;
    } else if (title.length < 2) {
      setErrors({
        ...errors,
        title: "Название материала должно содержать более 2 символов",
      })
      return false;
    }
    if(!depth){
      setErrors({
        ...errors,
        depth: 'Заполните поле "Глубина"',
      })
      return false;
    } else  if (depth > 1000) {
      setErrors({
        ...errors,
        depth: "Глубина должна быть больше 1000",
      })
      return false;
    }
    if(!image && !imageNew){
      setErrors({
        ...errors,
        image: 'Загрузите изображение',
      })
      return false;
    }
    if(modelsValue.length > 0){
      try {
        JSON.parse(modelsValue);
     } catch (error) {
       setErrors({
         ...errors,
         models: "Неверный формат данных модели",
       })
       return false;
     }
    }
    if(offersValue.length > 0){
      try {
        JSON.parse(offersValue);
     } catch (error) {
       setErrors({
         ...errors,
         offers: "Неверный формат данных оффера",
       })
       return false;
     }
    }
    return true;
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onFocus();
    if (!validateFacade()) {
      scrollToForm();
      return;
    }

    const facade = CreateFacadeNew();
    createFacade(facade);
    console.log("facade", facade);
  };

  // add files
  const addFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFacade({
      ...newFacade,
      imageNew: Array.from(e.target.files as FileList)[0],
      image: "",
    });
    setErrors(initialErrors);
  };

  // функция удаления выбранного фото
  const filterFunction = () => {
    setNewFacade({ ...newFacade, imageNew: null, image: ""});
    setErrors(initialErrors);
  };

  // функция удаления выбранного фото
  const deleteImage = () => {
    setNewFacade({ ...newFacade, image: "" });
    setErrors(initialErrors);
  };

  const edit = !!itemFacade;

  return (
    <div className={cnFormFacade("")}>
      <div className={cnFormFacade("Container")}>
        <h3 className={cnFormFacade("Header")}>
          {edit ? "Редактирование фасада" : "Добавление фасада"}
        </h3>

        <form
          className={cnFormFacade("Content")}
          onSubmit={(e) => handleSubmit(e)}
          ref={formRef}
        >
          {edit && <p className={cnFormFacade('Id')}>ID фасада: &nbsp; {id}</p>}
          <label htmlFor="title">Название фасада *</label>
          <Textinput
            className={cnFormFacade("Textinput")}
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="title"
            type="text"
            maxLength={50}
            onFocus={onFocus}
          />
          {errors.title && <p className="text-danger">{errors.title}</p>}

          <>
            <h5>Изображение фасадов</h5>

            {image && !imageNew && (
              <div className={cnFormFacade("Children")}>
                <span>{image.split("/").at(-1) as string}</span>
                <img width={50} height={50} src={image} alt="..." />
                <div className={cnFormFacade("Box-Btn")}>
                  <Trash handleDeleteValue={deleteImage} />
                </div>
              </div>
            )}
            
            <InputFile
              file={imageNew as File | null}
              addFiles={addFiles}
              filterFunction={filterFunction}
            />
            {errors.image && <p className="text-danger">{errors.image}</p>}
          </>

          <div>
              <label htmlFor="depth">Толщина фасада *</label>
              <Textinput
                  className={cnFormFacade('Textinput')}
                  value={depth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                  name="depth"
                  type="number"
              />
          </div>
          {errors.depth && <p className="text-danger">{errors.depth}</p>}

          <div>
              <label >Параметры модулей для которых применим фасад (models) *</label>
              <Textarea
                className={cnFormFacade('Textarea')}
                value={modelsValue}
                placeholder={t('Параметры модулей для которых применим фасад *')}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setModelsValue(e.target.value) }
                rows={5}
                onFocus={onFocus}
            />
            {errors.models && <p className="text-danger">{errors.models}</p>}
          </div>

          <div>
              <label >Параметры модулей для которых применим фасад (offers) *</label>
              <Textarea
                className={cnFormFacade('Textarea')}
                value={offersValue}
                placeholder={t('Параметры модулей для которых применим фасад *')}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setOffersValue(e.target.value) }
                rows={5}
                onFocus={onFocus}
            />
            {errors.offers && <p className="text-danger">{errors.offers}</p>}
          </div>
          
          <div>
            <label htmlFor="sideType">Способ расчёта стоимости фасада *</label>
              <Select
                  className={cnFormFacade('Select')}
                  placeholder={"Выберите значение"}
                  showPlaceholder={false}
                  options={calculateTypes}
                  value={calculateType}
                  callBack={(e) => onInputChange({...e,target:{...e.target, name: 'calculateType'}})}
              />
          </div>

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={isLevel as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsLevel(e.target.checked)
            }
            label="Уточнить уровень установки модулей"
          />

          {isLevel && (
            <>
              <label htmlFor="levels">Уровень модулей(нижний или верхний)</label>
              <Select
                className={cnFormFacade("Select")}
                placeholder={"Выберите значение"}
                showPlaceholder={false}
                options={levels}
                value={level as string}
                callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  onInputChange({
                    ...e,
                    target: { ...e.target, name: "level" },
                  })
                }
              />
            </>
          )}

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={isDefault as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Определить этот фасад, как фасад по умолчанию"
            name="isDefault"
          />

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={isPlinthMaterial as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsPlinthMaterial(e.target.checked)
            }
            label="Указать материал цоколя"
          />
          {isPlinthMaterial && 
          <>
            <label htmlFor="plinthMaterial">Материал цоколя</label>
           
              <Select
                className={cnFormFacade("Select")}
                placeholder={"Выберите значение"}
                showPlaceholder={false}
                options={plinthMaterials.map((material: IPlinthData) => ({value: material.id, content: material.title}))}
                value={plinthMaterial as string}
                callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  onInputChange({
                    ...e,
                    target: { ...e.target, name: "plinthMaterial" },
                  })
                }
              />
          </>
          }  

          
          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={isTopFlipY as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Перевернуть фрезировку фасада по вертикали вверх"
            name="isTopFlipY"
          />
          
          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={isBottomFlipY as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Перевернуть фрезировку фасада по вертикали вниз"
            name="isBottomFlipY"
          />


          <Handle facadeNew={newFacade} setFacadeNew={setNewFacade}/>


          <div className={cnFormFacade("Footer")}>
            <Button
              className={cnFormFacade("Button")}
              type={"submit"}
              text={"Подтвердить изменения"}
              leftIcon={undefined}
              mods={{
                mods: {
                  bkg: "white",
                  color: "lightGreen",
                  border: "lightGreen",
                  fill: "lightGreen",
                },
                hover: {
                  mods: { bkg: "lightGreen", color: "white", fill: "white" },
                },
              }}
              callBack={() => {}}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormFacade;
