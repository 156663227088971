import {ThreeKUnitDetail} from '../ThreeKUnitDetail/ThreeKUnitDetail';
import {DEFAULT_APRON_WIDTH} from '../../../../constants';
import {Box3, Euler, ExtrudeGeometry, Mesh, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {
    KDETAIL_PRICE_TYPE_COLLECTIONS,
    SIDE_TYPE_BACK,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT
} from '../../../../../../common-code/constants';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {ICoverMainPoints} from '../../../../interfaces/ICoverMainPoints';
import {IThreeKUnitDetail} from '../../../../interfaces/IThreeKUnitDetail';
import {
    onAfterRenderTransparentForBack,
    onBeforeRenderTransparentForBack,
} from '../../../../helpers/ThreeHelper/ThreeHelper';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {TPositionSideType} from "../../../../../../common-code/types/TPositionSideType";
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {i18n} from '../../../../../i18n';
import {IApronData} from '../../../../../../common-code/interfaces/materials/IApronData';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {CommonHelper} from 'common-code';
import {ITextureData} from '../../../../../../common-code/interfaces/materials/ITextureData';
import {TLine3D} from '../../../../../../common-code/types/TLine3D';
import {MathHelper} from 'common-code';
import {ThreeMathHelper} from '../../../../helpers/ThreeMathHelper/ThreeMathHelper';

export class ThreeApron extends ThreeKUnitDetail implements IThreeKUnitDetail {
    materialData: IApronData;

    constructor(options: ISaveKUnitDetailData, unit: ThreeUnit) {
        super(options, unit);
        this.materialData = this.initMaterialData();
        this.transparentForBack = this.saveData.transparentForBack !== undefined ?
            this.saveData.transparentForBack : true;
    }

    public isAkril(): boolean {
        return this.materialData.materialType === 'akril';
    }

    public initHeight(positionType?: TPositionSideType): number {
        return this.service.getApronHeight();
    }

    public getMaterialData(): IApronData {
        return this.materialData;
    }

    public getSettingName(): string {
        switch (this.getPositionType()) {
            case SIDE_TYPE_LEFT:
                return i18n.t('Левая панель')
            case SIDE_TYPE_RIGHT:
                return i18n.t('Правая панель')
            case SIDE_TYPE_BACK:
            default:
                return i18n.t('Задняя панель')
        }
    }

    public getVisibleTitle(): string {
        let title: string;

        title = i18n.t('Строить панель');
        if (this.saveData.isSelfVisible !== undefined) {
            title += ' (' + i18n.t('вручную') + ')';
        } else {
            title += ' (' + i18n.t('автоматически') + ')';
        }

        return title;
    }

    public canHasEndPlanks(): boolean {
        return this.saveData.hasEndPlanks !== false;
    }

    public getGlobalSideItemPoints(points: Vector2[]): TLine3D[] {
        let sideItemPoints: TLine3D[] = [];
        let index: number;
        let threePoint: Vector3 = new Vector3();

        for (index = 0; index < points.length - 1; index++) {
            if (!MathHelper.isEqualPoints2D(points[index], points[index + 1])) {
                sideItemPoints.push({
                    pointA: ThreeMathHelper.toPoint3D(threePoint.set(points[index].x, points[index].y, -this.getHeight()/2).applyMatrix4(this.view3d.matrixWorld)),
                    pointB: ThreeMathHelper.toPoint3D(threePoint.set(points[index + 1].x, points[index + 1].y, -this.getHeight()/2).applyMatrix4(this.view3d.matrixWorld))
                });
            }
        }

        return sideItemPoints;
    }

    public initLength(positionType?: TPositionSideType): number {
        let length: number;
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        switch (positionType) {
            case SIDE_TYPE_LEFT:
            case SIDE_TYPE_RIGHT:
                length = coverBox.max.z - coverBox.min.z;
                break;
            case SIDE_TYPE_BACK:
            default:
                length = coverBox.max.x - coverBox.min.x;
                break;
        }

        return length;
    }

    public initWidth(positionType?: TPositionSideType): number {
        return DEFAULT_APRON_WIDTH;
    }

    public getBodyMaterial(): MeshStandardMaterial {
        return new MeshStandardMaterial({
            color: this.materialData.color || '#ffffff',
            map: this.materialTextures.texture || null,
            normalMap: this.materialTextures.normal || null,
            roughnessMap: this.materialTextures.roughness || null,
            envMapIntensity: 5,
        });
    }

    public getUnionYPosition(): number {
        return this.getWidth()/2;
    }

    public getDefaultRotationByType(): Euler {
        let rotation: Euler;

        switch (this.saveData.positionType) {
            case SIDE_TYPE_LEFT:
                rotation = new Euler(0, 0.5* Math.PI, 0);
                break;
            case SIDE_TYPE_RIGHT:
                rotation = new Euler(0, -0.5* Math.PI, 0);
                break;
            case SIDE_TYPE_BACK:
            default:
                rotation = new Euler();
                break;
        }

        return rotation;
    }

    public getDefaultPositionByType(): Vector3 {
        let position: Vector3;
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        switch (this.saveData.positionType) {
            case SIDE_TYPE_LEFT:
                position = new Vector3(
                    coverBox.min.x + this.getHeight() / 2,
                    coverBox.max.y + this.getWidth()/2 - this.unit.getTabletopHeight(),
                    coverBox.min.z + this.getLength() / 2
                );
                break;
            case SIDE_TYPE_RIGHT:
                position = new Vector3(
                    coverBox.max.x - this.getHeight() / 2,
                    coverBox.max.y + this.getWidth()/2 - this.unit.getTabletopHeight(),
                    coverBox.min.z + this.getLength() / 2
                );
                break;
            case SIDE_TYPE_BACK:
            default:
                position = new Vector3(
                    (coverBox.min.x + coverBox.max.x) / 2,
                    coverBox.max.y + this.getWidth()/2 - this.unit.getTabletopHeight(),
                    coverBox.min.z + this.getHeight() / 2
                );
                break;
        }

        return position;
    }

    public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
        if (!this.correctLeftPoints || !this.correctRightPoints) {
            throw new Error('error-ThreeApron-getGlobalMainPoints');
        }
        this.view3d.updateMatrixWorld();
        this.globalCoverMainPoints.bottom.pointA.set(
            this.correctLeftPoints[0].x,
            this.correctLeftPoints[0].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.bottom.pointB.set(
            this.correctRightPoints[0].x,
            this.correctRightPoints[0].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.top.pointA.set(
            this.correctLeftPoints[this.correctLeftPoints.length - 1].x,
            this.correctLeftPoints[this.correctLeftPoints.length - 1].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.top.pointB.set(
            this.correctRightPoints[this.correctRightPoints.length - 1].x,
            this.correctRightPoints[this.correctRightPoints.length - 1].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.back.pointA.set(
            this.correctLeftPoints[this.correctLeftPoints.length - 1].x,
            this.correctLeftPoints[this.correctLeftPoints.length - 1].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.back.pointB.set(
            this.correctRightPoints[this.correctRightPoints.length - 1].x,
            this.correctRightPoints[this.correctRightPoints.length - 1].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);

        return this.globalCoverMainPoints;
    }

    public getDefaultPoints(type: TSideType): Vector2[] {
        let points;

        points = [
            new Vector2(0, -this.getWidth() / 2),
            new Vector2(0, this.getWidth() / 2)
        ];

        return points;
    }

    public createBody() {
        let geometry;
        let extrudeSettings;

        extrudeSettings = {
            steps: 1,
            depth: this.getHeight(),
            bevelEnabled: false
        };
        geometry = new ExtrudeGeometry(this.shape, extrudeSettings);
        geometry.center();
        this.body = new Mesh(geometry, this.getBodyMaterial());
        this.body.renderOrder = 2;
        this.body.name = "body";
        this.body.matrixAutoUpdate = false;
        this.body.userData.commonRenderOrder = 2;
        this.body.updateMatrix();
        this.body.onBeforeRender = onBeforeRenderTransparentForBack;
        this.body.onAfterRender = onAfterRenderTransparentForBack;
        this.view3d.add(this.body);
        this.addCoverPoints(this.calculateMeshCoverPoints(this.body));
    }

    public getTechnologMapCuttingGap(): number {
        return this.service.getApronCuttingGap(this.getTechnologMapFacadeId());
    }

    protected loadTextures(): IMaterialTextures {
        let textures: ITextureData[];
        switch (this.materialData.type) {
            case KDETAIL_PRICE_TYPE_COLLECTIONS:
                if (this.materialData.offerTextures) {
                    textures = Object.values(this.materialData.offerTextures)[0] || [];
                } else {
                    textures = this.materialData.textures || [];
                }
                break;
            default:
                textures = this.materialData.textures || [];
                break;
        }

        return this.service.loadMaterialTextures(this.materialData.id, textures);
    }

    protected getOtherDetails(): ThreeKUnitDetail[] {
        return this.unit.aprons || [];
    }

    protected correctMaterialOffset() {
        let isChange: boolean = false;
    
        if (this.materialData.textures && this.materialData.textures.length > 0) {
            const length = this.getLength();
            const halfWidth = this.materialData.width * 2;
    
            for (const textureData of this.materialData.textures) {
                textureData.offset = textureData.offset || { x: 0, y: 0 };
    
                const targetX = length / halfWidth;
                const targetY = 0.5;
    
                if (
                    textureData.offset.x !== targetX ||
                    textureData.offset.y !== targetY
                ) {
                    textureData.offset = { x: targetX, y: targetY };
                    isChange = true;
                }
            }
    
            if (isChange) {
                this.materialTextures = this.loadTextures();
            }
        }
    }

    protected initMaterialData(): IApronData {
        return CommonHelper.deepCopy(this.service.getApronMaterial(this.saveData.material));
    }

    protected initPriceType() {
        if (this.saveData.priceType === undefined && this.service.appConfig.catalog.aprons !== undefined) {
            this.saveData.priceType = this.service.appConfig.catalog.aprons.priceType;
        }
    }
}