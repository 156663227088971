import React from "react";
import Trash from "../../../../../components/icons/Trash";
import { legsValues } from "../../constants/constants";
import { cnCreateForm } from "../../FormModule";
import Edit from "../../../../../components/icons/Edit";
import ModalValues from "./ModalValues";
import { SIDE_TYPES as sideTypes } from "common-code/lib/constants";
import { initialValue, IParamsValue, IValue } from "./initialValue";
import { validateValue } from "./validateValue";
import { createValue } from "./createValue";

interface IValueProps {
  arrValues: IValue[];
  setArrValues: (arr: IValue[]) => void;
  value: IValue;
  index: number;
  name: string;
  keyValue: string;
}

function Value({
  arrValues,
  setArrValues,
  value,
  index,
  name,
  keyValue,
}: IValueProps) {
  const [paramsValue, setParamsValue] = React.useState<IParamsValue>(
    initialValue(value)
  );

  const handleDeleteValue = () =>
    setArrValues(arrValues.filter((_, ind) => ind !== index));

  const handleToggleModal = () => {
    setParamsValue({ ...paramsValue, modal: !paramsValue.modal });
  };

  const handleValueEdit = () => {
    const error = validateValue(paramsValue, arrValues, value.positionType);
    if (error) {
      setParamsValue({ ...paramsValue, error: error });
      return;
    }

    const valueEdit = createValue(paramsValue);
    setArrValues(
      arrValues.map((el, ind) =>
        ind === index ? valueEdit : el
      )
    );
    setParamsValue({ ...paramsValue, modal: false });
  };

  return (
    <div className={cnCreateForm("Children")}>
      <span>
        Элемент: {name} -{" "}
        {name === "Ножки"
          ? legsValues[value.positionType]
          : (sideTypes as Record<string, string>)[value.positionType]}
      </span>

      <div className={cnCreateForm("Box-Btn")}>
        <Edit handleToggleModal={handleToggleModal} />
        <Trash handleDeleteValue={handleDeleteValue} />
      </div>

      <ModalValues
        handleToggleModal={handleToggleModal}
        handleSubmit={handleValueEdit}
        paramsValue={paramsValue}
        setParamsValue={setParamsValue}
        name={name}
        keyValue={`edit${keyValue}`}
      />
    </div>
  );
}

export default Value;
