export const LINK_TARGET_BLANK = '_blank';
export const LINK_TARGET_SELF = '_self';
export const LINK_TYPE_DEFAULT = 'default';
export const LINK_TYPE_NAV = 'nav';
export const MENU_TYPE_MAIN_MENU = 'MainMenu';
export const MENU_TYPE_USER_MENU = 'UserMenu';
export const HEADER_PHONE = '8-800-511-07-33';
export const WHATSAPP_PHONE = '79143317552';
export const INPUT_TYPE_PHONE = 'phone';
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_TEXTAREA = 'textarea';
export const INPUT_TYPE_PASSWORD = 'password';
export const HEADER_TYPE_SMALL = 'small';
export const HEADER_TYPE_MIDDLE = 'middle';
export const HEADER_TYPE_BIG = 'big';
export const BUTTON_MODE_LIGHT_GREEN = {
    mods: {
        bkg: 'white',
        color: 'lightGreen',
        border: 'lightGreen',
        fill: 'lightGreen',
    },
    hover: {
        mods: {
            bkg: 'lightGreen',
            color: 'white',
            fill: 'white',
        },
    },
};
export const BUTTON_MODE_BLACK_WHITE = {
    mods: {
        color: 'black',
        bkg: 'white',
        border: 'black',
        fill: 'black',
    },
}
export const BUTTON_LEFT_ICON_PLUS_2 = {
        path: 'plus2',
        width: 16,
        height: 16,
        marginRight: 8,
};
export const BUTTON_LEFT_ICON_ARROW_LEFT = {
    path: 'arrow-left',
    width: 20,
    height: 20,
    marginRight: 8,
}
