import React from "react";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import Textures, { INewTextureData } from "./Textures/Textures";
import EnableFacades from "./EnableFacades/EnableFacades";
import { Tumbler } from "../../../components/Tumbler/Tumbler";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select/Select";
import { cn } from "@bem-react/classname";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import { defaultValues, initialErrors, levels } from "./defaultValues";
import InputFile from "./InputFile/InputFile";
import Trash from "../../../components/icons/Trash";
import { transliterate } from "../utils/transliterate";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import { IPlinthData } from "../../../../../common-code/interfaces/materials/IPlinthData";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import "./FormMaterial.css";

export interface INewMaterialData extends IMaterialData {
  texturesNew?: INewTextureData[];
  imageNew?: File | null;
}

export const cnFormMaterial = cn("FormMaterial");

function FormMaterial({
  itemMaterial,
  createMaterial,
}: {
  itemMaterial: INewMaterialData | null;
  createMaterial: (material: INewMaterialData) => void;
}) {
  const plinthMaterials = useSelector((state: AppState) => state.wizard.plinths);
  const [newMaterial, setNewMaterial] = React.useState<INewMaterialData>(
    defaultValues(itemMaterial)
  );
 
const formRef = React.useRef<HTMLFormElement>(null);
const scrollToForm = () => formRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  const {
    id,
    // обязательные поля
    title,
    // необязательные поля
    level,
    emissiveColor,
    color,
    isDefault,
    image,
    imageNew,
    plinthMaterial,
  } = newMaterial;

  const [isColor, setIsColor] = React.useState(!!color);
  const [isEmissiveColor, setIsEmissiveColor] = React.useState(!!emissiveColor);
  const [isLevel, setIsLevel] = React.useState(!!level);
  const [isPlinthMaterial, setIsPlinthMaterial] = React.useState(!!plinthMaterial);
  const [error, setError] = React.useState(initialErrors);

  const onInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewMaterial({ ...newMaterial, [e.target.name]: e.target.value });
  };

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMaterial({ ...newMaterial, [e.target.name]: e.target.checked });
  };

  const onFocus = () =>{
    setNewMaterial({ ...newMaterial });
    setError(initialErrors);
  }



  const CreateMaterialNew = () => {
    const material: INewMaterialData = {
      id:  transliterate(title),
      title: title,
    };
    if (isColor) {
      material.color = color;
    } else {
        if (newMaterial.textures && newMaterial.textures.length > 0) {
            material.textures = newMaterial.textures;
        }
        if (newMaterial.texturesNew && newMaterial.texturesNew?.length > 0) {
            material.texturesNew = newMaterial.texturesNew;
        }
    }

    if(isDefault){
      material.isDefault = isDefault;
    }
    if (emissiveColor) {
      material.emissiveColor = emissiveColor;
    }
    if (isLevel) {
      material.level = level;
    }

    if (imageNew) {
      material.imageNew = imageNew;
    } else if (image) {
      material.image = image;
    }

    if (newMaterial.enableFacades && newMaterial.enableFacades.length > 0) {
      material.enableFacades = newMaterial.enableFacades;
    }

    if (newMaterial.disableFacades && newMaterial.disableFacades.length > 0) {
      material.disableFacades = newMaterial.disableFacades;
    }

    if (isPlinthMaterial) {
      material.plinthMaterial = newMaterial.plinthMaterial;
    }

    return material;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!title) {
      setError({ ...error, title: "Поле не может быть пустым" });
      scrollToForm();
      return;
    } else if (title.length < 5) {
      setError({ ...error, title: "Минимальная длина 5 символов" });
      scrollToForm();
      return;
    }

    const material = CreateMaterialNew();
    createMaterial(material);
    console.log("material", material);
  };

  // add files
  const addFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMaterial({
      ...newMaterial,
      imageNew: Array.from(e.target.files as FileList)[0],
      image: "",
    });
    setError(initialErrors);
  };

  // функция удаления выбранного фото
  const filterFunction = () => {
    setNewMaterial({ ...newMaterial, imageNew: null, image: ""});
    setError(initialErrors);
  };

  // функция удаления выбранного фото
  const deleteImage = () => {
    setNewMaterial({ ...newMaterial, image: "" });
  };

  const edit = !!itemMaterial;

  return (
    <div className={cnFormMaterial("")}>
      <div className={cnFormMaterial("Container")}>
        <h3 className={cnFormMaterial("Header")}>
          {edit ? "Редактирование материала" : "Добавление материала"}
        </h3>

        <form
          className={cnFormMaterial("Content")}
          onSubmit={(e) => handleSubmit(e)}
          ref={formRef}
        >
          {edit && <p className={cnFormMaterial("Id")}>ID материала: &nbsp; {id}</p>}
          <label htmlFor="title">Название материала</label>
          <Textinput
            className={cnFormMaterial("Textinput")}
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="title"
            type="text"
            maxLength={50}
            onFocus={onFocus}
          />
          {error.title && <p className="text-danger">{error.title}</p>}

          <Tumbler
            className={cnFormMaterial("Tumbler")}
            checked={isColor as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsColor(e.target.checked)
            }
            label="Указать цвет"
            name="isColor"
          />

          {isColor ? (
            <>
                {/* <div className={cnFormMaterial('Form-Group')}>
                              <label>Цвет</label>
                              <input type="color"  name="color" value={color} onChange={(e) => onInputChange(e)} className={cnFormMaterial('СolorInput')}/>
                          </div> */}
              <ColorPicker
                color={color}
                onChange={(color) => setNewMaterial({ ...newMaterial, color })}
              />
            </>
          ) : (
            <>
              <h4 className={cnFormMaterial('Title')}>Изображение для материала</h4>
              {image && !imageNew && (
                <div className={cnFormMaterial("Children")}>
                  <span>{image.split("/").at(-1) as string}</span>
                  <img width={50} height={50} src={image} alt="..." />
                  <div className={cnFormMaterial("Box-Btn")}>
                    <Trash handleDeleteValue={deleteImage} />
                  </div>
                </div>
              )}

              <InputFile
                file={imageNew as File | null}
                addFiles={addFiles}
                filterFunction={filterFunction}
              />

              <Textures
                setNewMaterial={setNewMaterial}
                newMaterial={newMaterial}
              />
            </>
          )}

          <Tumbler
            className={cnFormMaterial("Tumbler")}
            checked={isLevel as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsLevel(e.target.checked)
            }
            label="Уточнить уровень установки модулей"
          />

          {isLevel && (
            <>
              <label htmlFor="levels">Уровень установки</label>
              <Select
                className={cnFormMaterial("Select")}
                placeholder={"Выберите значение"}
                showPlaceholder={false}
                options={levels}
                value={level as string}
                callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  onInputChange({
                    ...e,
                    target: { ...e.target, name: "level" },
                  })
                }
              />
            </>
          )}

          <Tumbler
            className={cnFormMaterial("Tumbler")}
            checked={isEmissiveColor as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsEmissiveColor(e.target.checked)
            }
            label="Указать оттенок свечения"
          />

          {isEmissiveColor && (
            <div className={cnFormMaterial("Form-Group")}>
              <label htmlFor="emissiveColor">Оттенок свечения</label>
              {/* <input type="color" id='emissiveColor' name="emissiveColor" value={emissiveColor} onChange={(e) => onInputChange(e)} className={cnFormMaterial('СolorInput')}/> */}
              <ColorPicker
                color={emissiveColor}
                onChange={(emissiveColor) =>
                  setNewMaterial({ ...newMaterial, emissiveColor })
                }
              />
            </div>
          )}

          <Tumbler
            className={cnFormMaterial("Tumbler")}
            checked={isDefault as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Определить этот материал, как материал по умолчанию"
            name="isDefault"
          />

          <EnableFacades
            setNewMaterial={setNewMaterial}
            newMaterial={newMaterial}
            value="enableFacades"
          />
          <EnableFacades
            setNewMaterial={setNewMaterial}
            newMaterial={newMaterial}
            value="disableFacades"
          />
          <Tumbler
            className={cnFormMaterial("Tumbler")}
            checked={isPlinthMaterial as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsPlinthMaterial(e.target.checked)
            }
            label="Указать материал цоколя"
          />
          {isPlinthMaterial && 
          <>
            <label htmlFor="plinthMaterial">Материал цоколя</label>
           
              <Select
                className={cnFormMaterial("Select")}
                placeholder={"Выберите значение"}
                showPlaceholder={false}
                options={plinthMaterials.map((material: IPlinthData) => ({value: material.id, content: material.title}))}
                value={plinthMaterial as string}
                callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  onInputChange({
                    ...e,
                    target: { ...e.target, name: "plinthMaterial" },
                  })
                }
              />
          </>
          } 

          <div className={cnFormMaterial("Footer")}>
            <Button
              className={cnFormMaterial("Button")}
              type={"submit"}
              text={"Подтвердить изменения"}
              leftIcon={undefined}
              mods={{
                mods: {
                  bkg: "white",
                  color: "lightGreen",
                  border: "lightGreen",
                  fill: "lightGreen",
                },
                hover: {
                  mods: { bkg: "lightGreen", color: "white", fill: "white" },
                },
              }}
              callBack={() => {}}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormMaterial;
