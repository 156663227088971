import React from "react";
import {Popup} from '../../components/Popup';
import {useTranslation} from 'react-i18next';
import { Button as ButtonIcon } from '@yandex/ui/Button/Button';
import './IconsStyle.css'


function AddFile() {

    const [hoverIcon, setHoverIcon] = React.useState<string | null>(null);
    const {t} = useTranslation();

    return (  
        
        <div 
            className="IconStyle-Btn-Icon"
            onMouseEnter={() => {setHoverIcon('addFile')}}
            onMouseLeave={() => {setHoverIcon(null)}} >
            <Popup
                active={'addFile' === hoverIcon}
                position={{x: -30, y: 0}}>
                {t('Выбрать изображение')}
            </Popup>
            <ButtonIcon
                type="button"
                className="IconStyle-Btn"
                >

                <svg  width="32" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style={{background:"new 0 0 512 512"}} >
                    <path style={{fill:"#F2ECBF"}} d="M476.693,423.731C476.685,472.487,437.154,512,388.407,512c-23.711,0-46.416-9.543-63.021-26.474 H71.235c-19.845,0-35.929-16.084-35.929-35.929V35.929C35.307,16.084,51.391,0,71.235,0h228.903l105.933,105.933V337.22 C447.182,345.615,476.702,381.773,476.693,423.731z"/>
                    <path style={{fill:"#23A24D"}} d="M476.693,423.731c-0.009,48.756-39.539,88.269-88.295,88.26 c-48.756-0.009-88.269-39.539-88.26-88.295s39.539-88.269,88.295-88.26c5.923,0,11.829,0.6,17.638,1.783 C447.182,345.615,476.702,381.773,476.693,423.731z"/>
                        <polygon style={{fill:"#FFFFFF"}} points="432.555,406.076 432.555,441.387 406.072,441.387 406.072,467.87 370.761,467.87 370.761,441.387 344.277,441.387 344.277,406.076 370.761,406.076 370.761,379.593 406.072,379.593 406.072,406.076 "/><g>
                    <path style={{fill:"#212F3F"}} d="M353.105,211.866H79.445c-4.873,0-8.828-3.955-8.828-8.828s3.955-8.828,8.828-8.828h273.66 c4.873,0,8.828,3.955,8.828,8.828S357.987,211.866,353.105,211.866z"/>
                    <path style={{fill:"#212F3F"}} d="M353.105,317.799H79.445c-4.873,0-8.828-3.955-8.828-8.828s3.955-8.828,8.828-8.828h273.66 c4.873,0,8.828,3.955,8.828,8.828S357.987,317.799,353.105,317.799z"/>
                    <path style={{fill:"#212F3F"}} d="M211.861,247.177H79.445c-4.873,0-8.828-3.955-8.828-8.828c0-4.873,3.955-8.828,8.828-8.828h132.416 c4.873,0,8.828,3.955,8.828,8.828C220.689,243.222,216.743,247.177,211.861,247.177z"/>
                    <path style={{fill:"#212F3F"}} d="M353.105,247.177H256c-4.873,0-8.828-3.955-8.828-8.828c0-4.873,3.955-8.828,8.828-8.828h97.105 c4.873,0,8.828,3.955,8.828,8.828C361.933,243.222,357.987,247.177,353.105,247.177z"/>
                    <path style={{fill:"#212F3F"}} d="M353.105,282.488H194.206c-4.873,0-8.828-3.955-8.828-8.828c0-4.873,3.955-8.828,8.828-8.828 h158.899c4.873,0,8.828,3.955,8.828,8.828C361.933,278.533,357.987,282.488,353.105,282.488z"/>
                    <path style={{fill:"#212F3F"}} d="M158.895,282.488h-79.45c-4.873,0-8.828-3.955-8.828-8.828c0-4.873,3.955-8.828,8.828-8.828h79.45 c4.873,0,8.828,3.955,8.828,8.828C167.723,278.533,163.777,282.488,158.895,282.488z"/></g>
                    <path style={{fill:"#BFA380"}} d="M406.072,105.933h-70.004c-19.845,0-35.929-16.084-35.929-35.929V0L406.072,105.933z"/>
                </svg>
    
            </ButtonIcon>
        </div>    
    );
}

export default AddFile;